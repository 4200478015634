import { useLocation } from 'react-router-dom';
import ContentfulButton from '../../buttons/ContentfulButton';

type Props = React.ComponentProps<typeof ContentfulButton>;

const NavLink = ({ link, className = '', ...rest }: Props) => {
  const { pathname } = useLocation();
  const isActive = pathname === link.href;

  return (
    <ContentfulButton
      link={link}
      className={`whitespace-nowrap p-1 text-[13px] text-gray-400 transition hover:text-gray-100 disabled:text-white/30 sm:text-[15px] ${link.shape === 'text' ? '' : 'w-full max-w-xs'} ${link.shape === 'text' && isActive ? 'font-bold text-white' : ''} ${className}`}
      data-title={link.label}
      data-location='header'
      role='menuitem'
      {...rest}
    />
  );
};

export default NavLink;
