import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  getEventListing,
  getExperiences,
  getPackages,
  getRestaurants,
  getShows,
} from '@wbk/contentful/api';
import useExploreQuery from './useExploreQuery';

type Props = {
  detectedLang?: Language;
  category?: string;
};

const useExploreFetch = ({ detectedLang, category }: Props = {}) => {
  const { query, types, perPage } = useExploreQuery({ detectedLang, category });
  const showEvents = !types.length || types.includes('events');
  const showExperiences = !types.length || types.includes('experiences');
  const showRestaurants = !types.length || types.includes('restaurants');
  const showShows = !types.length || types.includes('shows');
  const showPackages = !types.length || types.includes('packages');

  const {
    fetchNextPage: fetchMoreEvents,
    hasNextPage: moreEvents,
    isFetching: eventsLoading,
    data: events,
  } = useInfiniteQuery({
    queryKey: ['getEvents', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getEventListing({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: showEvents,
  });

  const {
    fetchNextPage: fetchMoreExperiences,
    hasNextPage: moreExperiences,
    isFetching: experiencesLoading,
    data: experiences,
  } = useInfiniteQuery({
    queryKey: ['getExperiences', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getExperiences({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: showExperiences,
  });

  const {
    fetchNextPage: fetchMoreShows,
    hasNextPage: moreShows,
    isFetching: showsLoading,
    data: shows,
  } = useInfiniteQuery({
    queryKey: ['getShows', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getShows({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: showShows,
  });

  const {
    fetchNextPage: fetchMoreRestaurants,
    hasNextPage: moreRestaurants,
    isFetching: restaurantsLoading,
    data: restaurants,
  } = useInfiniteQuery({
    queryKey: ['getRestaurants', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getRestaurants({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: showRestaurants,
  });

  const {
    fetchNextPage: fetchMorePackages,
    hasNextPage: morePackages,
    isFetching: packagesLoading,
    data: packages,
  } = useInfiniteQuery({
    queryKey: ['getPackages', query],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => getPackages({ ...query, skip: (pageParam - 1) * perPage }),
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      const totalRendered = lastPageParam * perPage;
      const total = lastPage.total;
      return total > totalRendered ? lastPageParam + 1 : undefined;
    },
    enabled: showPackages,
  });

  const allMergedEvents = useMemo(() => {
    const allEvents = showEvents ? events?.pages || [] : [];
    const allExperiences = showExperiences ? experiences?.pages || [] : [];
    const allShows = showShows ? shows?.pages || [] : [];
    const allRestaurants = showRestaurants ? restaurants?.pages || [] : [];
    const allPackages = showPackages ? packages?.pages || [] : [];

    // A hack for the sorting
    const all = allEvents.concat(allExperiences, allShows, allRestaurants, allPackages);

    return all.sort((a, b) => a.settledAt - b.settledAt).flatMap((p) => p.items);
  }, [
    events?.pages,
    experiences?.pages,
    packages?.pages,
    restaurants?.pages,
    showEvents,
    showExperiences,
    showPackages,
    showRestaurants,
    showShows,
    shows?.pages,
  ]);

  const hasMoreEvents = showEvents && moreEvents;
  const hasMoreExperiences = showExperiences && moreExperiences;
  const hasMoreShows = showShows && moreShows;
  const hasMoreRestaurants = showRestaurants && moreRestaurants;
  const hasMorePackages = showPackages && morePackages;

  const anyLoading =
    eventsLoading || experiencesLoading || restaurantsLoading || showsLoading || packagesLoading;

  return {
    allMergedEvents,
    hasMoreEvents,
    hasMoreExperiences,
    hasMoreRestaurants,
    hasMoreShows,
    hasMorePackages,
    fetchMoreEvents,
    fetchMoreExperiences,
    fetchMoreRestaurants,
    fetchMoreShows,
    fetchMorePackages,
    anyLoading,
  };
};

export default useExploreFetch;
