import { useTranslation } from 'react-i18next';
import { useWebsiteLocale } from '@wbk/hooks';
import { ContentfulEvent, Experience } from '@wbk/contentful/api';
import { Link } from 'react-router-dom';
import { Button } from '@wbk/ui';
import StreamIncludedBar from '../stream/StreamIncludedBar';
import ZoneEntryInfo from '../zone/ZoneIncludedBar';
import EventLocation from './EventItem/Location';

type Props = {
  event: ContentfulEvent;
};

const TitleInfo = ({ event }: Props) => {
  const { t } = useTranslation('event');
  const { shortLang } = useWebsiteLocale();
  const hyProps = (event as Experience).hyProperties;

  const city = hyProps
    ? hyProps[`city_${shortLang}` as 'city'] || hyProps.city
    : event.location?.city;

  return (
    <div className='flex flex-wrap items-center gap-4'>
      <EventLocation
        location={{
          ...event.location,
          city,
        }}
        icon
      />

      {event.isStreamingEvent && <StreamIncludedBar event={event} />}
      {event?.zoneEntryIncluded && <ZoneEntryInfo event={event} />}

      {hyProps && event?.zone && (
        <div className='flex items-center gap-1 text-sm'>
          <p>{t('event:organized_by')}</p>
          <div className='flex items-center gap-1'>
            <img
              className='h-7 w-7 rounded-full'
              src={event.zone?.zoneLogo?.url}
              alt={event.zone?.title}
            />
            <Button
              as={Link}
              to={`/${shortLang}/zones/${event.zone?.slug}`}
              className='px-1 py-0.5'
              theme='white'
              shape='text'
            >
              {event.zone?.title}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TitleInfo;
