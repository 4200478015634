import { GroupEvent } from '@wbk/api';
import { ImageWithPlaceholder } from '@wbk/ui';
import GalleryWithThumbs from '../group/GalleryWithThumbs';

type Props = {
  event: GroupEvent;
};

const StickyEventInfo = ({ event }: Props) => {
  return (
    <div className='hidden space-y-8 xl:block'>
      {event.gallery_images?.length > 0 ? (
        <GalleryWithThumbs
          poster={event.poster}
          carousel={event.gallery_images}
          title={event.title}
        />
      ) : (
        <figure className='rounded-lg'>
          <ImageWithPlaceholder
            className='aspect-1 overflow-hidden rounded-lg object-cover'
            src={event.poster}
            width={600}
            height={600}
            alt={event.title}
          />
        </figure>
      )}
    </div>
  );
};

export default StickyEventInfo;
