import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { FeaturedSection } from '@wbk/contentful/api';
import TicketIcon from '../svg/Ticket';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  section: FeaturedSection;
};

const EventFeaturedSection = ({ section }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const { t } = useTranslation('common');
  const event = section.content;

  return (
    <section className='aspect-1 container relative my-6 h-full w-full overflow-hidden md:aspect-[3/1] md:rounded-md'>
      <ContentfulImage
        src={event.image31?.url}
        className='absolute hidden object-cover object-center md:block'
        sizes='100vw'
        alt={event.title}
        placeholder
      />
      <ContentfulImage
        src={event.image11?.url || event.image31?.url}
        className='absolute object-cover object-center md:hidden'
        sizes='100vw'
        alt={event.title}
        placeholder
      />

      <div className='absolute bottom-4 left-0 z-10 flex w-full justify-center'>
        <Button
          as={Link}
          to={`/${lang}/events/${event.slug}`}
          className='h-12 w-full max-w-xs gap-2 text-xl'
        >
          <TicketIcon className='h-7 w-7' />
          {t('nav.buy_tickets')}
        </Button>
      </div>
    </section>
  );
};

export default EventFeaturedSection;
