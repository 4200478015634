import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WebSection, WebZonePages } from '@wbk/contentful/api';
import { formatDate, parseSectionUtm } from '@wbk/utils';
import { Carousel } from '@wbk/ui';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  section: WebSection;
};

const ZonePagesCarousel = ({ section }: Props) => {
  const zonePages = section.contentCollection.items as WebZonePages[];
  const { lang } = useParams<{ lang: Language }>();
  const locale = lang as Language;
  const { t } = useTranslation('common');

  const formattedEndDate = (date: string) => {
    const month = formatDate(new Date(date), locale, { month: 'short' }).toUpperCase();
    const day = formatDate(new Date(date), locale, { day: 'numeric' });

    return `${day} ${month}`;
  };

  if (!zonePages.length) return null;

  return (
    <section
      data-testid={`home_zone_pages_carousel_${section.id}`}
      className='container relative space-y-8 overflow-hidden py-12'
    >
      {section?.title && <h2 className='text-2xl'>{section.title}</h2>}
      <Carousel locale={locale} options={{ loop: true, dragFree: true }} arrows>
        {zonePages.map((page, i) => {
          const utm = parseSectionUtm({ utm: section.utm, idx: i, id: page.id });
          const zone = page.sectionsCollection?.items?.[0]?.zone;
          if (!zone) return null;
          const hasEnded =
            !!zone.schedule?.closeDateTime && new Date(zone.schedule.closeDateTime) < new Date();

          const endDate =
            !hasEnded &&
            zone.schedule?.closeDateTime &&
            formattedEndDate(zone.schedule.closeDateTime);

          return (
            <Link
              data-testid={`home_zone_carousel_item_${zone.id}`}
              to={`/${lang}/zones/${zone.slug}${utm}`}
              className='group relative mr-2 flex h-60 shrink-0 basis-2/5 items-center justify-center overflow-hidden rounded-xl sm:basis-1/4 md:basis-1/5 xl:basis-1/6'
              key={i}
            >
              {!hasEnded ? (
                <ContentfulImage
                  alt={zone.title || ''}
                  src={`${zone.featuredBanner.url}?h=320`}
                  sizes='600px'
                  fit='thumb'
                  className='absolute -z-10 h-full w-full object-cover opacity-20 transition duration-500 group-hover:scale-[1.02] group-hover:opacity-40'
                  placeholder
                />
              ) : (
                <div className='absolute -z-10 h-full w-full bg-zinc-900' />
              )}

              <div className={`relative z-10 ${hasEnded ? 'opacity-50 grayscale filter' : ''}`}>
                <ContentfulImage
                  className='mx-auto max-w-24 shrink-0'
                  src={zone?.zoneLogo?.url}
                  width={250}
                  height={250}
                  alt={zone.title || ''}
                  fit='pad'
                />
                {zone?.sponsorLogo && (
                  <div className='space-y-2'>
                    <p className='text-xs text-gray-400'>{t('presented_by')}</p>
                    <ContentfulImage
                      className='mx-auto max-h-6 w-auto shrink-0'
                      src={zone?.sponsorLogo?.url}
                      width={zone.sponsorLogo.width || 140}
                      height={zone.sponsorLogo.height || 140}
                      alt={zone.sponsorLogo?.title || ''}
                      fit='crop'
                    />
                  </div>
                )}
              </div>

              {endDate && (
                <div className='absolute bottom-4 left-1/2 -translate-x-1/2 text-[11px] text-yellow-500'>
                  {t('common:zone_ends', {
                    date: endDate,
                  })}
                </div>
              )}

              {hasEnded && (
                <span className='absolute bottom-4 left-1/2 -translate-x-1/2 text-[11px] capitalize text-zinc-500'>
                  {t('common:ended')}
                </span>
              )}
            </Link>
          );
        })}
      </Carousel>
    </section>
  );
};

export default ZonePagesCarousel;
