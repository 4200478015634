import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BottomSheet, Button, DayPicker } from '@wbk/ui';
import { formatDate, parseDate, splitDateTime } from '@wbk/utils';
import CalendarIcon from '@/components/svg/Calendar';
import CloseButton from '@/components/buttons/CloseButton';

type Props = {
  render?: 'desktop' | 'mobile';
};

type DateRange = {
  from: Date | undefined;
  to?: Date | undefined;
};

const DateFilters = ({ render }: Props) => {
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const selectedDate = searchParams.get('date');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [localDate, setLocalDate] = useState(selectedDate);

  const handleClear = () => {
    const newUrl = new URLSearchParams(searchParams);
    newUrl.delete('date');
    navigate(`${pathname}?${newUrl.toString()}`, { replace: true });
  };

  const toggleCollapse = () => {
    setOpen((prev) => !prev);
  };

  const handleFilterClick = (date: string) => {
    const newUrl = new URLSearchParams(searchParams);
    newUrl.delete('page');

    if (date === selectedDate) {
      newUrl.delete('date');
    } else {
      newUrl.set('date', date);
    }

    navigate(`${pathname}?${newUrl.toString()}`);
  };

  const handleMobileApply = () => {
    const newUrl = new URLSearchParams(searchParams.toString());
    newUrl.delete('page');
    if (localDate) {
      newUrl.set('date', localDate);
    } else {
      newUrl.delete('date');
    }
    const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
    navigate(`${pathname}${search}`, { replace: true });
  };

  useEffect(() => {
    if (!selectedDate) {
      setLocalDate(null);
    }
  }, [selectedDate]);

  if (render === 'mobile') {
    return (
      <BottomSheet
        rounded
        ignoreHistory
        render={({ open }) => (
          <Button
            theme='primary'
            onClick={() => {
              open();
              setLocalDate(selectedDate);
            }}
            className={`border-primary bg-body-light hover:bg-primary/30 focus:bg-primary/30 active:text-text shrink-0 border-2 px-2.5 py-1 text-sm text-gray-100 shadow-md lg:hidden ${
              selectedDate
                ? 'bg-primary/30 text-primary focus:border-2 active:border-2'
                : 'border-transparent'
            }`}
            phill={false}
            rounded
          >
            <CalendarIcon />
            <span>{t('common:date')}</span>

            {!!selectedDate && (
              <a
                href='#!'
                className='text-primary relative -mt-px h-5 w-5 rounded-full bg-white/10 p-1 font-sans text-[16px] ltr:ml-1 rtl:mr-1'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const newUrl = new URLSearchParams(searchParams.toString());
                  newUrl.delete('date');
                  const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
                  navigate(`${pathname}${search}`, { replace: true });
                }}
              >
                <span className='absolute left-1/2 top-1/2 -mt-px -translate-x-1/2 -translate-y-1/2 leading-none'>
                  x
                </span>
              </a>
            )}
          </Button>
        )}
        stickyFooter={({ close }) => (
          <div className='p-2 pb-4 text-center'>
            <Button
              className='w-full max-w-sm'
              onClick={() => {
                handleMobileApply();
                close();
              }}
            >
              {t('common:apply')}
            </Button>
          </div>
        )}
        body={({ close }) => (
          <div className='px-4 pb-24'>
            <div className='flex items-center justify-between pb-4'>
              <h4 className='font-bold'>{t('common:date')}</h4>
              <CloseButton onClick={close} className='relative left-0 right-0 top-0' />
            </div>
            <DateSelectors onClick={(date) => setLocalDate(date)} selected={localDate} />
          </div>
        )}
      />
    );
  }

  return (
    <div className='hidden space-y-2 lg:block'>
      <Button
        theme='white'
        shape='text'
        className='w-full items-center justify-between p-2'
        onClick={toggleCollapse}
        phill={false}
        rounded
      >
        <div className='flex w-full items-baseline justify-between gap-2 text-start'>
          <div className='flex items-baseline gap-4'>
            <p className='grow font-bold'>{t('common:date')}</p>
            {searchParams.has('date') && (
              <Button
                as='a'
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClear();
                }}
                shape='text'
                className='text-primary-300 px-2 py-1 text-xs'
                theme='transparent'
              >
                {t('common:clear')}
              </Button>
            )}
          </div>

          <svg
            width='14'
            height='8'
            viewBox='0 0 14 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={`shrink-0 transform transition-transform duration-300 ${open ? 'rotate-180' : ''}`}
          >
            <path
              d='M12.2929 0.292892C12.6834 -0.0976324 13.3166 -0.0976324 13.7071 0.292892C14.0976 0.683416 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.32853 8.08568 6.71894 8.09893 6.32428 7.73715L0.324275 2.23715C-0.0828438 1.86396 -0.110347 1.23139 0.262846 0.824274C0.636039 0.417155 1.26861 0.389652 1.67572 0.762845L6.96992 5.61586L12.2929 0.292892Z'
              fill='#A1A1AA'
            />
          </svg>
        </div>
      </Button>
      <motion.div
        className='overflow-hidden'
        initial={false}
        animate={{ height: open ? 'auto' : 0 }}
        transition={{ duration: 0.3 }}
      >
        <DateSelectors onClick={handleFilterClick} selected={selectedDate} />
      </motion.div>

      <hr className='border-body-lighter' />
    </div>
  );
};

type DateSelectorsProps = {
  onClick: (selected: string) => void;
  selected?: string | null;
};

const DateSelectors = ({ onClick, selected }: DateSelectorsProps) => {
  const { t } = useTranslation('common');
  const initialFrom = selected?.split('_')?.[0];
  const initialTo = selected?.split('_')?.[1];
  const safeFrom = initialFrom ? parseDate(initialFrom) : undefined;
  const safeTo = initialTo ? parseDate(initialTo) : undefined;
  const [showPicker, setShowPicker] = useState(!!safeFrom && !!safeTo);
  const [range, setRange] = useState<DateRange | undefined>({
    from: safeFrom,
    to: safeTo,
  });

  const { lang } = useParams<{ lang: Language }>();

  const handleRangeSelect = (range: DateRange | undefined) => {
    if (!range) return;

    setRange(range);

    if (range?.from && range.to) {
      const fromSplitted = splitDateTime(range.from.getTime() / 1000, 'en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const toSplitted = splitDateTime(range.to.getTime() / 1000, 'en', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      const from = `${fromSplitted.year}-${fromSplitted.month}-${fromSplitted.day}`;
      const to = `${toSplitted.year}-${toSplitted.month}-${toSplitted.day}`;
      onClick(`${from}_${to}`);
    }
  };

  const clearRange = () => {
    setShowPicker(false);
    setRange(undefined);
  };

  useEffect(() => {
    if (!selected) {
      clearRange();
    }
  }, [selected]);

  return (
    <div className='flex flex-wrap gap-2 lg:p-1'>
      {['today', 'tomorrow', 'thisweek'].map((date) => (
        <Button
          key={date}
          theme='primary'
          onClick={() => {
            onClick(date);
            if (showPicker) {
              clearRange();
            }
          }}
          className={`border-primary bg-body-lighter hover:bg-primary/30 focus:bg-primary/30 active:text-text lg:bg-body-light shrink-0 border-2 px-3 py-1 text-sm text-gray-100 shadow-md ${
            selected === date
              ? 'bg-primary/30 text-primary focus:border-2 active:border-2'
              : 'border-transparent'
          }`}
          phill={false}
          rounded
        >
          {t(`common:${date}`)}
        </Button>
      ))}

      <Button
        theme='primary'
        onClick={() => setShowPicker((prev) => !prev)}
        className={`border-primary bg-body-lighter hover:bg-primary/30 focus:bg-primary/30 active:text-text lg:bg-body-light shrink-0 border-2 px-3 py-1 text-sm text-gray-100 shadow-md ${
          showPicker
            ? 'bg-primary/30 text-primary focus:border-2 active:border-2'
            : 'border-transparent'
        }`}
        phill={false}
        rounded
      >
        {t('common:custom_dates')}
      </Button>

      <motion.div
        initial={false}
        className='overflow-hidden'
        animate={{ height: showPicker ? 'auto' : 0 }}
      >
        <DayPicker
          className='bg-body-lighter/70 lg:bg-body-light/70 w-full rounded-lg py-2'
          lang={lang}
          mode='range'
          selected={range}
          onSelect={handleRangeSelect}
          weekStartsOn={0}
          fromDate={new Date()}
          components={{
            CaptionLabel: ({ displayMonth }) => {
              return (
                <p className='px-2'>
                  {formatDate(displayMonth, lang, { month: 'long', year: 'numeric' })}
                </p>
              );
            },
          }}
        />
      </motion.div>
    </div>
  );
};

export default DateFilters;
