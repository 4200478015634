import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAnalytics } from '@wbk/analytics';
import { Button } from '@wbk/ui';
import { Package } from '@wbk/contentful/api';
import { getUser } from '@wbk/api';
import { formatPrice } from '@wbk/utils';
import { useQueue } from '@wbk/queue';

type Props = {
  event: Package;
  className?: string;
  containerClassName?: string;
  href?: string;
  pricePosition?: 'inside' | 'outside';
  queueProtected?: boolean;
};

const BuyTicketButton = ({
  event,
  className,
  containerClassName,
  href,
  pricePosition = 'outside',
  queueProtected,
}: Props) => {
  const { t } = useTranslation(['common', 'event']);
  const { isActive, shouldWaitQueue } = useQueue();
  const { pathname } = useLocation();
  const { generateLeadEvent } = useAnalytics();

  if (isActive || shouldWaitQueue) {
    return null;
  }

  if (event.eventType === 'external' && event.buttonLink) {
    return <ExternalEventButton event={event} />;
  }

  if (event.isComingSoon || !event.ticketingUrlSlug) {
    return event.subtitle ? (
      <div className='self-center'>
        <p>{event.subtitle}</p>
      </div>
    ) : (
      <Button className='mx-auto w-full max-w-xs !bg-gray-700 lg:mx-0' disabled>
        {event?.buttonLabel || t('common:coming_soon')}
      </Button>
    );
  }

  const originalPrice = Number(event.originalPrice) || 0;
  const hasOffer = Number(event.startingPrice) < originalPrice;
  const price = Number(event.startingPrice);
  const savedPrice = originalPrice - price;

  return (
    <div
      className={twMerge(
        'flex items-center justify-between gap-8 rounded-md px-3 py-4 sm:gap-6',
        containerClassName
      )}
    >
      {price > 0 && (
        <div className='shrink-0'>
          {hasOffer && (
            <p className='flex items-center gap-2 text-sm'>
              <span className='text-zinc-400 line-through'>
                {t('common:price', {
                  price: formatPrice(originalPrice),
                  currency: t(event.currencyCode),
                })}
              </span>
              <span className='bg-tertiary/5 text-tertiary px-1.5 py-px capitalize'>
                {t('event:discount_save', {
                  amount: formatPrice(savedPrice),
                  currency: t(event.currencyCode),
                })}
              </span>
            </p>
          )}
          <p className='flex items-baseline gap-1'>
            <strong className='text-lg'>{formatPrice(price)}</strong>
            <span>{t(event.currencyCode)}</span>
            <span className='text-sm'>/</span>
            <span className='text-[11px]'>{t('event:per_person')}</span>
          </p>
          <p className='text-[11px] text-zinc-400'>{t('event:vat_included')}</p>
        </div>
      )}

      <Button
        queueProtected={queueProtected}
        as={Link}
        to={href || `${pathname}/book`}
        onClick={() => {
          generateLeadEvent({
            event_start_price: event.startingPrice,
            currency: event.currencyCode,
            title: event.title,
            slug: event.slug,
          });
        }}
        disabled={shouldWaitQueue || isActive}
        className={twMerge(
          'buy-ticket mx-auto min-h-[50px] w-full max-w-xs gap-2 text-lg capitalize leading-none lg:mx-0',
          className
        )}
        data-event-name={event.title}
        data-event-start-date={event.schedule?.openDateTime}
        data-category='package'
        arrow
      >
        {price > 0 && pricePosition === 'inside' ? (
          <div>
            <span>{event.buttonLabel || t('event:buy_tickets')}</span>
            <p className='text-xs font-normal'>{`${t('event:from')} ${t('common:price', {
              price: formatPrice(originalPrice),
              currency: t(event.currencyCode),
            })}`}</p>
          </div>
        ) : (
          <span>{event.buttonLabel || t('event:buy_tickets')}</span>
        )}
      </Button>
    </div>
  );
};

const ExternalEventButton = ({ event }: Pick<Props, 'event'>) => {
  const { t } = useTranslation(['common', 'event']);
  const { lang } = useParams<{ lang: Language }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const externalLinkRef = useRef<HTMLAnchorElement>(null);

  const handleClick = async () => {
    setIsLoading(true);
    const user = await getUser({ lang });
    setIsLoading(false);

    if (!user) {
      navigate(`/${lang}/login?redirect=${encodeURIComponent(pathname)}`);
    } else {
      externalLinkRef.current?.click();
    }
  };

  return (
    <>
      <Button
        className='mx-auto w-full max-w-xs gap-2 lg:mx-0'
        {...(event.authGaurd
          ? {
              onClick: handleClick,
              loading: isLoading,
              disabled: isLoading,
            }
          : {
              as: 'a',
              href: event.buttonLink,
              target: '_blank',
              rel: 'noopener noreferrer',
            })}
      >
        <span>{event.buttonLabel || t('event:more_details')}</span>
        <img
          className={!event.buttonIcon?.url ? 'ltr:rotate-90' : ''}
          src={event.buttonIcon?.url || '/icons/common/external-arrow.svg'}
          width={16}
          height={16}
          alt={t('event:more_details').toUpperCase()}
        />
      </Button>

      {event.authGaurd && (
        <a
          href={event.buttonLink}
          target='_blank'
          rel='noopener noreferrer'
          className='hidden'
          ref={externalLinkRef}
        >
          {' '}
        </a>
      )}
    </>
  );
};

export default BuyTicketButton;
