import { Link, useParams } from 'react-router-dom';
import { Button, ImageWithPlaceholder } from '@wbk/ui';
import { ContentfulEvent } from '@wbk/contentful/api';
import { resolveContentfulEventType } from '@/util/events';
import EventItemType from '../events/EventItem/Type';
import EventItemPrice from '../events/EventItem/Price';
import EventBookingTypeBadge from '../events/BookingType';
import EventLocation from '../events/EventItem/Location';

type Props = {
  event: ContentfulEvent;
  onClick?: () => void;
};

const SearchEventItem = ({ event, onClick }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const resolvedType = resolveContentfulEventType(event.__typename);

  return (
    <Button
      data-testid={`search_event_button_${event.slug}`}
      queueProtected
      as={Link}
      to={`/${lang}/${resolvedType}/${event.slug}`}
      onClick={onClick}
      className='w-full justify-start gap-3 p-0.5 text-start'
      theme='white'
      shape='text'
      phill={false}
      rounded
    >
      <ImageWithPlaceholder
        src={event.image11?.url}
        className='h-24 w-24 shrink-0 rounded-md object-cover'
      />
      <div className='space-y-px [&>div:nth-child(1)]:text-xs [&>div:nth-child(3)]:text-sm'>
        <EventItemType event={event} />
        <p className='line-clamp-2 text-sm'>{event.title}</p>
        <EventLocation location={event.location} className='text-xs text-gray-400' />
        <EventItemPrice event={event} />
        <EventBookingTypeBadge className='py-0.5' type={event.eventType} mini />
      </div>
    </Button>
  );
};

export default SearchEventItem;
