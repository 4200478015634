import { useTranslation } from 'react-i18next';
import { AuthForm } from '@wbk/auth';
import Seo from '@/components/headers/seo';

const SignupPage = () => {
  const { t } = useTranslation('common');
  return (
    <>
      <Seo title={t('common:auth.signup')} />
      <AuthForm title={t('common:auth.login_webook')} initialMode='signup' />
    </>
  );
};

export default SignupPage;
