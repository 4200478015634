const TypeIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='3.33301' y='3.87488' width='5.83333' height='5.83333' rx='1.5' fill='currentColor' />
      <path
        opacity='0.3'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.833 5.37488C10.833 4.54645 11.5046 3.87488 12.333 3.87488H15.1663C15.9948 3.87488 16.6663 4.54645 16.6663 5.37488V8.20821C16.6663 9.03664 15.9948 9.70821 15.1663 9.70821H12.333C11.5046 9.70821 10.833 9.03664 10.833 8.20821V5.37488ZM3.33301 12.8749C3.33301 12.0465 4.00458 11.3749 4.83301 11.3749H7.66634C8.49477 11.3749 9.16634 12.0465 9.16634 12.8749V15.7082C9.16634 16.5366 8.49477 17.2082 7.66634 17.2082H4.83301C4.00458 17.2082 3.33301 16.5366 3.33301 15.7082V12.8749ZM12.333 11.3749C11.5046 11.3749 10.833 12.0465 10.833 12.8749V15.7082C10.833 16.5366 11.5046 17.2082 12.333 17.2082H15.1663C15.9948 17.2082 16.6663 16.5366 16.6663 15.7082V12.8749C16.6663 12.0465 15.9948 11.3749 15.1663 11.3749H12.333Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default TypeIcon;
