import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWebsiteLocale } from '@wbk/hooks';
import Context from './context';

type Props = {
  children: React.ReactNode;
};

const LanguageProvider = ({ children }: Props) => {
  const { i18n } = useTranslation('common');
  const { lang } = useWebsiteLocale();

  useEffect(() => {
    if (lang) {
      setLanguage(lang as Language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLanguage = (language: Language) => {
    i18n.changeLanguage(language);
    document.documentElement.lang = language;
    document.documentElement.dir = language.startsWith('ar') ? 'rtl' : 'ltr';
  };

  return <Context.Provider value={{ setLanguage }}>{children}</Context.Provider>;
};

export default LanguageProvider;
