import React from 'react';
import { RouteObject } from 'react-router-dom';
import ZoneDetails from '@/pages/zone/slug';
import Error404 from '@/components/error/404';
const ExploreListing = React.lazy(() => import('@/components/explore/ExploreListing'));

export const zonesRoutes: RouteObject[] = [
  {
    path: 'zones/:zoneSlug',
    errorElement: <Error404 />,
    element: <ZoneDetails />,
    children: [
      {
        path: '',
        element: <ExploreListing />,
      },
    ],
  },
];
