import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, ImageWithPlaceholder, Modal } from '@wbk/ui';
import { Restaurant } from '@wbk/contentful/api';
import Breadcrumb from '../common/Breadcrumb';
import GalleryWithThumbs from '../events/GalleryWithThumbs';
import Gallery from './Gallery';

interface IProps {
  data: Restaurant;
}

const HeroSection = ({ data }: IProps) => {
  const { lang } = useParams<{ lang: Language; slug: string }>();
  const { t } = useTranslation(['common', 'event']);

  const [galleryIndex, setGalleryIndex] = useState<number>(-1);

  const posters = data?.carousalCollection.items.length
    ? data?.carousalCollection.items
    : [data?.image11];

  const MAX_POSTERS_TO_SHOW = 4;

  const postersToShow = posters ? posters.slice(0, MAX_POSTERS_TO_SHOW) : [];

  const showMoreButton = posters ? posters.length > MAX_POSTERS_TO_SHOW : false;

  return (
    <>
      <section className='relative'>
        {/* BG blur effect */}
        <div className='absolute inset-0 -z-[1] h-[calc(100%+100px)] w-full overflow-hidden after:absolute after:z-10 after:block after:h-[calc(100%+100px)] after:w-full after:bg-gradient-to-b after:from-black/80 after:via-black/30 after:to-black after:to-[80%]'>
          <img
            src={posters[0]?.url}
            className='absolute h-full w-full object-cover blur-xl'
            alt=''
          />
        </div>

        <div className='container relative py-4'>
          <Breadcrumb
            items={[
              {
                href: `/${lang}/explore?type=restaurants`,
                name: t('restaurants'),
              },
              {
                name: data.title,
                href: data.slug,
                disabled: true,
                className: 'text-gray-400',
              },
            ]}
          />
        </div>

        <Modal
          fullscreen
          closeButton
          render={({ open }) => {
            return (
              <div className='container relative hidden grid-cols-4 gap-2 md:grid'>
                {postersToShow?.map((poster, index) => (
                  <Button
                    onClick={() => {
                      open();
                      setGalleryIndex(index);
                    }}
                    theme='white'
                    key={index}
                    className='relative aspect-[1/1] bg-transparent hover:bg-transparent'
                  >
                    <ImageWithPlaceholder
                      key={index}
                      src={poster?.url}
                      className='absolute inset-0 h-full w-full cursor-pointer rounded-md object-cover'
                      alt='restaurant-test'
                    />
                  </Button>
                ))}

                {showMoreButton && (
                  <Button
                    theme='white'
                    shape='solid'
                    className='absolute bottom-2 text-xs capitalize ltr:right-7 rtl:left-7'
                    onClick={() => {
                      open();
                      setGalleryIndex(MAX_POSTERS_TO_SHOW + 1);
                    }}
                  >
                    <Trans
                      i18nKey='event:see_all_photos'
                      values={{ number: posters.length }}
                    ></Trans>
                  </Button>
                )}
              </div>
            );
          }}
          className='overflow-y-auto bg-black/10 p-4 backdrop-blur-sm'
          body={() => <Gallery posters={posters} selectedIndex={galleryIndex} autoPlay />}
        />
      </section>

      <section className='container md:hidden'>
        <GalleryWithThumbs poster={data.image11} carousel={posters} />
      </section>
    </>
  );
};

export default HeroSection;
