import { Link } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { scrollIntoElementId } from '@wbk/utils';
import ContentfulImage from '../images/ContentfulImage';
import type { ContentfulLink } from '@wbk/contentful/api';

// Same props as Button props
type Props = Parameters<typeof Button>[0] & {
  children?: React.ReactNode;
  link: ContentfulLink;
  onClickScrollToSectionId?: string;
};

const ContentfulButton = ({ link, children, onClickScrollToSectionId, ...props }: Props) => {
  const noLink = !link.href || link.href === '#';

  return (
    <Button
      as={noLink ? 'button' : Link}
      to={link.href}
      shape={link.image?.url ? 'text' : link.shape}
      theme={link.image?.url ? 'white' : link.theme}
      target={link.isExternal ? '_blank' : undefined}
      rel={link.isExternal ? 'noopener noreferrer' : undefined}
      rounded
      onClick={() => {
        if (noLink && onClickScrollToSectionId) {
          scrollIntoElementId(onClickScrollToSectionId);
        }
      }}
      aria-label={link.label || ''}
      {...props}
    >
      {link.image?.url ? (
        <ContentfulImage
          src={link.image.url}
          width={link.image.width}
          height={link.image.height}
          alt={link.label || ''}
          fit='pad'
          format={null}
        />
      ) : (
        <>
          {link.icon?.url && link.iconPosition === 'left' && (
            <ContentfulImage
              className='shrink-0'
              src={link.icon.url}
              width={link?.icon?.width || 20}
              height={link?.icon?.height || 20}
              alt=''
              fit='pad'
            />
          )}
          {link.label}
          {children}
          {link.icon?.url && link.iconPosition === 'right' && (
            <ContentfulImage
              className='shrink-0'
              src={link.icon.url}
              width={link?.icon?.width || 20}
              height={link?.icon?.height || 20}
              alt=''
              fit='pad'
            />
          )}
        </>
      )}
    </Button>
  );
};

export default ContentfulButton;
