import { Suspense } from 'react';
import { WebSection } from '@wbk/contentful/api';
import CardsGrid from '../cards/CardsGrid';
import ZonePagesGrid from '../zone/ZonePagesGrid';
import EventsGrid from '../events/EventsGrid';
import EventsFullScreenCarousel from '../events/FullScreenCarousel';
import EventsGridCarousel from '../events/EventsGridCarousel';
import CardsCarousel from '../cards/CardsCarousel';
import ZonePagesCarousel from '../zone/ZonePagesCarousel';
import EventsFocusedCarousel from '../events/EventsFocusedCarousel';
import EventsNumberedCarousel from '../events/EventNumberedCarousel';
import CardsCarouselMini from '../cards/CardsCarouselMini';
import SponsorList from '../sponsors/SponsorList';
import CategoryCardsCarousel from '../cards/CategoryCardsCarousel';
import SocialCardsCarousel from '../cards/SocialCardsCarousel';
import SpotlightCarousel from '../cards/SpotlightCarousel';
import NewsLetterSubscribe from '../newsletter/Subscribe';
import ErrorBoundary from '../error/ErrorBoundary';
import HomeShopSection from '../shop/HomeShopSection';
import DownloadAppBanner from '../app/DownloadAppBanner';
import WbkBlogSection from '../blog/BlogSection';
import PollSection from '../poll/PollSection';

type Props = {
  section: WebSection;
  specificType?: WebSection['contentCollection']['items'][0]['__typename'];
};

const ContentfulWebSection = ({ section, specificType }: Props) => {
  // 1. Render based on section type
  switch (section.renderType) {
    case 'newsletter':
      return (
        <NewsLetterSubscribe title={section.title} type='newsletter' subtitle={section.subtitle} />
      );
    case 'wbk-shop':
      return (
        <ErrorBoundary fallback={<></>}>
          <Suspense fallback={<></>}>
            <HomeShopSection />
          </Suspense>
        </ErrorBoundary>
      );
    case 'wbk-blog':
      return (
        <ErrorBoundary fallback={<></>}>
          <Suspense fallback={<></>}>
            <WbkBlogSection section={section} />
          </Suspense>
        </ErrorBoundary>
      );
    case 'download-app':
      return <DownloadAppBanner />;
    default:
      break;
  }

  //  2. Render based on content type
  if (!section.contentCollection?.items?.[0]) {
    return null;
  }

  const type = specificType || section.contentCollection?.items?.[0]?.__typename;

  switch (type) {
    case 'WebZonePage':
      return section.renderType === 'carousel' ? (
        <ZonePagesCarousel section={section} />
      ) : (
        <ZonePagesGrid section={section} />
      );
    case 'WebCard':
      return section.renderType === 'fullwidth-carousel' ? (
        <EventsFullScreenCarousel section={section} />
      ) : section.renderType === 'carousel' ? (
        <CardsCarousel section={section} />
      ) : section.renderType === 'social' ? (
        <SocialCardsCarousel section={section} />
      ) : section.renderType === 'cards' ? (
        <CardsCarouselMini section={section} />
      ) : section.renderType === 'category' ? (
        <CategoryCardsCarousel section={section} />
      ) : section.renderType === 'spotlight' ? (
        <SpotlightCarousel section={section} />
      ) : (
        <CardsGrid section={section} />
      );
    case 'Event':
    case 'Experience':
    case 'Shows':
    case 'Restaurant':
    case 'Package':
      return section.renderType === 'fullwidth-carousel' ? (
        <EventsFullScreenCarousel section={section} />
      ) : section.renderType === 'carousel' ? (
        <EventsGridCarousel section={section} adType='mpu' />
      ) : section.renderType === 'focused-carousel' ? (
        <EventsFocusedCarousel section={section} />
      ) : section.renderType === 'numbered-carousel' ? (
        <EventsNumberedCarousel section={section} />
      ) : (
        <EventsGrid section={section} />
      );
    case 'SponsorList':
      return <SponsorList section={section} />;
    case 'Poll':
      return <PollSection section={section} />;
    default:
      return null;
  }
};

export default ContentfulWebSection;
