const TicketIcon = ({ className = '', double = true }) => {
  return (
    <svg
      width='24'
      height='21'
      viewBox='0 0 24 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      {double && (
        <path
          opacity='0.3'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.1176 10.928C10.7082 10.5185 9.95358 10.5185 9.54411 10.928L7.6527 12.8194C7.29054 13.1816 7.29054 13.7688 7.6527 14.131L8.05098 14.5292C8.4072 14.8855 9.04912 14.9007 9.60245 14.8521C10.1611 14.8031 10.7368 14.9924 11.1644 15.42C11.6868 15.9424 11.8535 16.6859 11.6644 17.3494C11.5668 17.6918 11.5924 18.0707 11.8627 18.341L12.4535 18.9318C12.8157 19.294 13.4029 19.294 13.765 18.9318L15.8968 16.8C16.2134 16.4834 16.2134 16.0234 15.8968 15.7068L15.332 15.1419C15.0251 14.835 15.0251 14.3375 15.332 14.0306C15.6388 13.7237 16.1364 13.7237 16.4433 14.0306L16.768 14.3553C17.1775 14.7648 17.932 14.7648 18.3415 14.3553L23.0112 9.68556C23.3733 9.32339 23.3733 8.7362 23.0112 8.37403L22.6128 7.97567C22.2566 7.61942 21.6146 7.60421 21.0613 7.65278C20.5026 7.70182 19.9269 7.51253 19.4993 7.08493C18.9769 6.56247 18.8102 5.81895 18.9993 5.15545C19.0969 4.81305 19.0713 4.4341 18.801 4.16377L18.2104 3.57316C17.8482 3.21099 17.261 3.21099 16.8988 3.57316L11.9888 8.48328C11.6722 8.79989 11.6722 9.25984 11.9888 9.57645L12.5535 10.1412C12.8604 10.448 12.8604 10.9456 12.5535 11.2525C12.2466 11.5594 11.749 11.5594 11.4421 11.2525L11.1176 10.928ZM13.1092 12.9196C12.8023 12.6127 12.8023 12.1151 13.1092 11.8082C13.4161 11.5013 13.9136 11.5013 14.2205 11.8082L14.7762 12.3639C15.0831 12.6708 15.0831 13.1683 14.7762 13.4752C14.4693 13.7821 13.9717 13.7821 13.6648 13.4752L13.1092 12.9196Z'
          fill='currentColor'
        />
      )}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.6629 9.30772L0.984736 11.9859C0.622568 12.3481 0.622568 12.9353 0.984736 13.2975L2.0237 14.3364C2.78649 13.8311 3.82433 13.9144 4.49639 14.5865C5.16845 15.2586 5.25181 16.2964 4.74645 17.0592L5.78554 18.0983C6.14771 18.4605 6.7349 18.4605 7.09707 18.0983L9.77545 15.4199L8.66399 14.3084C8.3571 14.0015 8.3571 13.504 8.66399 13.1971C8.97087 12.8902 9.46844 12.8902 9.77532 13.1971L10.8868 14.3086L16.3432 8.85207C16.7054 8.4899 16.7054 7.9027 16.3432 7.54053L15.3041 6.50144C14.5413 7.00686 13.5034 6.92353 12.8314 6.25143C12.1593 5.57934 12.0759 4.54144 12.5814 3.77863L11.5424 2.73967C11.1802 2.37749 10.593 2.37749 10.2309 2.73967L4.77424 8.19637L5.88551 9.30766C6.1924 9.61455 6.1924 10.1121 5.88551 10.419C5.57863 10.7259 5.08107 10.7259 4.77418 10.419L3.6629 9.30772ZM6.44121 12.0861C6.13432 11.7792 6.13432 11.2816 6.44121 10.9747C6.74809 10.6678 7.24566 10.6678 7.55254 10.9747L8.10821 11.5304C8.41509 11.8373 8.41509 12.3348 8.10821 12.6417C7.80132 12.9486 7.30376 12.9486 6.99687 12.6417L6.44121 12.0861Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default TicketIcon;
