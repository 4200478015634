import EventItemSkeleton from './EventItemSkeleton';

const ZonePageSkeleton = () => {
  return (
    <div className='flex flex-col items-start lg:container lg:flex-row lg:gap-6 lg:py-6'>
      <aside className='relative w-full lg:max-w-sm'>
        <div className='bg-body-light min-h-[300px] w-full animate-pulse rounded-lg lg:rounded-lg'></div>
        <div className='aspect-1 border-body bg-body-light mx-auto -mb-10 flex h-28 w-28 -translate-y-1/2 items-center justify-center overflow-hidden rounded-full border-4 p-2' />
        <div className='bg-body-light mb-4 min-h-[100px] w-full animate-pulse rounded-lg lg:rounded-lg' />
        <div className='bg-body-light mb-4 min-h-[100px] w-full animate-pulse rounded-lg lg:rounded-lg' />
      </aside>
      <section className='w-full space-y-2'>
        <div className='bg-body-lighter/50 h-9 max-w-sm animate-pulse rounded-md'></div>
        <div className='flex gap-2 overflow-hidden'>
          {[1, 2, 3, 4].map((idx) => (
            <div key={idx} className='bg-body-lighter/50 h-8 w-32 animate-pulse rounded-md'></div>
          ))}
        </div>
        <div className='grid w-full grow grid-cols-1 gap-2 sm:grid-cols-2 xl:grid-cols-3'>
          <EventItemSkeleton number={6} />
        </div>
      </section>
    </div>
  );
};

export default ZonePageSkeleton;
