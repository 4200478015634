import { APP_STORE_URL, GOOGLE_PLAY_STORE_URL, HUAWEI_STORE_URL } from '@/constants/apps';

const AppsDownload = () => {
  return (
    <div className='flex flex-row items-center gap-2 xl:gap-4'>
      <a
        data-testid='download_app_store_button'
        className='hover:opacity-80'
        href={APP_STORE_URL}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src='/images/app/appstore.svg' alt='appstore' width={105} height={35} />
      </a>
      <a
        className='hover:opacity-80'
        data-testid='download_google_play_store_button'
        href={GOOGLE_PLAY_STORE_URL}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src='/images/app/playstore.svg' alt='playstore' width={115} height={35} />
      </a>
      <a
        className='hover:opacity-80'
        data-testid='download_huawei_store_button'
        href={HUAWEI_STORE_URL}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src='/images/app/huawei.svg' alt='playstore' width={115} height={35} />
      </a>
    </div>
  );
};

export default AppsDownload;
