import { useTranslation } from 'react-i18next';
import { Experience } from '@wbk/contentful/api';
import { useWebsiteLocale } from '@wbk/hooks';
import CollapsableText from '../text/CollapsableText';

type Props = {
  experience: Experience;
};

const ExperienceCancelRefund = ({ experience }: Props) => {
  const { t } = useTranslation('event');
  const { shortLang } = useWebsiteLocale();
  const policy = experience.hyProperties;
  const text = policy?.refund_policy_details?.[shortLang];

  if (!text) return null;

  return (
    <div className='border-b border-white/20 py-6'>
      <h2 className='mb-4 text-xl'>{t('event:cancellation_and_refund_policy')}</h2>
      <CollapsableText text={text} />
    </div>
  );
};

export default ExperienceCancelRefund;
