import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetOrganizationBySlug } from '@wbk/api/hooks';
import Error404 from '@/components/error/404';
import Seo from '@/components/headers/seo';
import EventListByOrganization from '@/components/organizations/EventListing';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import NewsLetterSubscribe from '@/components/newsletter/Subscribe';

const OrganizationListing = () => {
  const { lang, slug } = useParams<{ lang: Language; slug: string }>();
  const { t } = useTranslation(['common', 'event']);

  const { data: organization, isLoading } = useGetOrganizationBySlug({ slug, lang });

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!organization) {
    return <Error404 />;
  }

  return (
    <>
      <Seo title={organization.name} />
      <section className='container space-y-1 py-6 text-center'>
        {organization.logo && (
          <img src={organization.logo} alt={organization.name} className='mx-auto h-28 w-28' />
        )}
        <h1 className='text-base font-normal'>{organization.name}</h1>
      </section>

      <section className='container space-y-6 py-6'>
        <h2 className='text-2xl'>{t('event:upcoming_matches')}</h2>
        <EventListByOrganization organization={organization} />
      </section>

      <NewsLetterSubscribe
        title={t('common:newsletter.title')}
        subtitle={t('common:newsletter.description')}
        type='organization'
        interests={[organization.slug]}
        slug={organization.slug}
      />
    </>
  );
};

export default OrganizationListing;
