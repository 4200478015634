import { Link, useParams } from 'react-router-dom';
import { parseSectionUtm } from '@wbk/utils';
import { Carousel } from '@wbk/ui';
import ContentfulImage from '../images/ContentfulImage';
import type { WebCard, WebSection } from '@wbk/contentful/api';

type Props = {
  section: WebSection;
};

const CategoryCardsCarousel = ({ section }: Props) => {
  const { lang } = useParams();
  const cards = section.contentCollection.items as WebCard[];

  if (!cards?.length) return null;

  return (
    <section
      data-testid={`home_category_cards_carousel_${section.id}`}
      className='container space-y-8 py-12'
    >
      <h2 className='text-2xl font-semibold text-gray-100'>{section.title}</h2>
      <Carousel locale={lang} options={{ dragFree: true }}>
        {cards.map((card, idx) => {
          let utm = parseSectionUtm({ utm: section.utm, idx, id: card.id });
          utm = card.href?.includes('?') ? utm.replace('?', '&') : utm;

          return (
            <div
              className='flex max-w-sm shrink-0 basis-[80%] px-2 md:basis-1/2 lg:basis-1/3 xl:basis-1/5'
              key={idx}
            >
              <Link
                to={`${card.href}${utm}`}
                data-testid={`home_category_card_item_${card.id}`}
                className='group relative flex h-36 w-full items-center overflow-hidden rounded-[14px] border-2 border-[var(--color)] text-[var(--color)]'
                aria-label={card.title}
                style={
                  {
                    '--color': card.color,
                    '--highlight': `${card.color}30`,
                  } as React.CSSProperties
                }
              >
                <span className='absolute top-0 ltr:left-0 rtl:right-0 rtl:-scale-x-100'>
                  <IconHighlight className='text-[var(--highlight)] transition-transform duration-500 ease-in-out group-hover:scale-[4]' />
                </span>

                <ContentfulImage
                  className='absolute top-3 z-[3] ltr:left-4 rtl:right-4 rtl:-scale-x-100'
                  src={card.icon?.url}
                  width={20}
                  height={20}
                  alt={card.title}
                  format={null}
                />

                <div className='relative z-[3] px-4 text-2xl font-semibold tracking-[-0.5px]'>
                  {card.title}
                </div>

                <div className='absolute bottom-0 z-[2] ltr:-right-2 rtl:-left-2 rtl:-scale-x-100'>
                  <ImageHighlight className='absolute bottom-0 text-[var(--highlight)] transition-transform duration-500 ease-in-out group-hover:scale-[4] ltr:-right-1 rtl:-left-1' />
                  <ContentfulImage
                    src={card.image.url}
                    className='relative'
                    alt=''
                    width={120}
                    height={120}
                  />
                </div>
              </Link>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
};

const ImageHighlight = ({ className = '' }) => {
  return (
    <svg
      width='153'
      height='113'
      viewBox='0 0 153 113'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M201.555 58.721C218.78 109.537 77.0242 112.925 40.011 112.925C2.99775 112.925 0 76.4149 0 48.5577C0 20.7006 8.06097 1.759e-05 67.0478 0C126.035 -1.759e-05 184.331 7.90475 201.555 58.721Z'
        className='fill-body'
      />
      <path
        d='M201.555 58.721C218.78 109.537 77.0242 112.925 40.011 112.925C2.99775 112.925 0 76.4149 0 48.5577C0 20.7006 8.06097 1.759e-05 67.0478 0C126.035 -1.759e-05 184.331 7.90475 201.555 58.721Z'
        fill='currentColor'
      />
    </svg>
  );
};

const IconHighlight = ({ className = ' ' }) => {
  return (
    <svg
      width='60'
      height='51'
      viewBox='0 0 60 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M-3.36978 -37.733C16.0635 -51.6447 39.6225 -37.9962 52.4968 -15.4198C65.3711 7.15655 61.2321 33.7701 43.2521 44.0233C25.2721 54.2765 -0.425292 54.3771 -12.6145 21.7102C-24.8038 -10.9566 -22.803 -23.8212 -3.36978 -37.733Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default CategoryCardsCarousel;
