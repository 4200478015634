import { POSTER_1X1_FADED } from '@/constants/fallbackLogo';

const EventItemSkeleton = ({ number = 6 }) => {
  const array = Array.from(Array(number).keys());

  return (
    <>
      {array.map((i) => (
        <div
          key={i}
          className='bg-body-light/60 relative mx-auto flex aspect-[2/2.5] w-full max-w-sm items-center justify-center overflow-hidden rounded-md'
        >
          <img src={POSTER_1X1_FADED} className='h-full w-full object-cover' alt='' />
        </div>
      ))}
    </>
  );
};

export default EventItemSkeleton;
