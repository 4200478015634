import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { BottomSheet, Button, Input } from '@wbk/ui';
import PriceIcon from '@/components/svg/Price';
import CloseButton from '@/components/buttons/CloseButton';

type Props = {
  render?: 'desktop' | 'mobile';
};

type FormValues = { fromPrice: string; toPrice: string };

const PriceFilters = ({ render }: Props) => {
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const priceFrom = searchParams.get('price_from');
  const priceTo = searchParams.get('price_to');

  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      fromPrice: priceFrom || '0',
      toPrice: priceTo || '',
    },
  });

  const hasValidFilters = searchParams.has('price_from') && searchParams.has('price_to');

  const handleClear = () => {
    const newUrl = new URLSearchParams(searchParams.toString());
    newUrl.delete('price_from');
    newUrl.delete('price_to');
    navigate(`${pathname}?${newUrl.toString()}`);
    reset();
  };

  const applyPriceFilter = (data: FormValues) => {
    const from = Number(data.fromPrice);
    const to = Number(data.toPrice);

    const newUrl = new URLSearchParams(searchParams.toString());
    newUrl.delete('page');
    if (from >= 0 && to) {
      const safeTo = from > to ? data.fromPrice : data.toPrice;
      newUrl.set('price_from', data.fromPrice);
      newUrl.set('price_to', safeTo);
    } else {
      newUrl.delete('price_from');
      newUrl.delete('price_to');
    }
    navigate(`${pathname}?${newUrl.toString()}`);
  };

  useEffect(() => {
    if (!priceFrom && !priceTo) {
      reset();
    }
    // eslint-disable-next-line
  }, [priceFrom, priceTo]);

  const toggleCollapse = () => {
    setOpen((prev) => !prev);
  };

  const Inputs = (
    <>
      <div>
        <Input
          className='ltr:!pr-2 rtl:!pl-2'
          type='number'
          placeholder='0'
          {...register('fromPrice', {
            min: {
              value: 0,
              message: t('common:validation.min_length', { min: 0 }),
            },
            max: {
              value: 10000,
              message: t('common:validation.max_length', { max: 10000 }),
            },
            required: t('common:validation.required'),
          })}
          onChange={(val) => {
            setValue('fromPrice', val);
          }}
          hideErrorMark
          error={errors.fromPrice?.message}
        />
      </div>
      <span className='mt-3 text-sm'>{t('common:to')}</span>
      <div>
        <Input
          className='ltr:!pr-2 rtl:!pl-2'
          type='number'
          placeholder='100'
          {...register('toPrice', {
            min: {
              value: 1,
              message: t('common:validation.min_length', { min: 1 }),
            },
            max: {
              value: 10000,
              message: t('common:validation.max_length', { max: 10000 }),
            },
            required: t('common:validation.required'),
          })}
          onChange={(val) => {
            setValue('toPrice', val);
          }}
          hideErrorMark
          error={errors.toPrice?.message}
        />
      </div>
    </>
  );

  if (render === 'mobile') {
    return (
      <BottomSheet
        rounded
        ignoreHistory
        render={({ open }) => (
          <Button
            theme='primary'
            onClick={() => {
              open();
              setValue('fromPrice', priceFrom || '0');
              setValue('toPrice', priceTo || '');
            }}
            className={`border-primary bg-body-light hover:bg-primary/30 focus:bg-primary/30 active:text-text shrink-0 border-2 px-2.5 py-1 text-sm text-gray-100 shadow-md lg:hidden ${
              hasValidFilters
                ? 'bg-primary/30 text-primary focus:border-2 active:border-2'
                : 'border-transparent'
            }`}
            phill={false}
            rounded
          >
            <PriceIcon />
            <span>{t('common:price_only')}</span>

            {!!hasValidFilters && (
              <a
                href='#!'
                className='text-primary relative -mt-px h-5 w-5 rounded-full bg-white/10 p-1 font-sans text-[16px] ltr:ml-1 rtl:mr-1'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const newUrl = new URLSearchParams(searchParams.toString());
                  newUrl.delete('price_from');
                  newUrl.delete('price_to');
                  const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
                  navigate(`${pathname}${search}`, { replace: true });
                }}
              >
                <span className='absolute left-1/2 top-1/2 -mt-px -translate-x-1/2 -translate-y-1/2 leading-none'>
                  x
                </span>
              </a>
            )}
          </Button>
        )}
        stickyFooter={({ close }) => (
          <div className='p-2 pb-4 text-center'>
            <Button
              form='price-range-form-mobile'
              type='submit'
              className='w-full max-w-sm'
              onClick={() => {
                // handleMobileApply();
                close();
              }}
            >
              {t('common:apply')}
            </Button>
          </div>
        )}
        body={({ close }) => (
          <form
            id='price-range-form-mobile'
            className='px-4 pb-24'
            onSubmit={handleSubmit(applyPriceFilter)}
          >
            <div className='flex items-center justify-between pb-4'>
              <h4 className='font-bold'>{t('common:price_range')}</h4>
              <CloseButton onClick={close} className='relative left-0 right-0 top-0' />
            </div>
            <div className='mx-auto flex max-w-sm gap-3 py-2'>{Inputs}</div>
          </form>
        )}
      />
    );
  }

  return (
    <>
      <Button
        theme='white'
        shape='text'
        className='w-full items-center justify-between p-2'
        onClick={toggleCollapse}
        phill={false}
        rounded
      >
        <div className='flex w-full items-baseline justify-between gap-2 text-start'>
          <div className='flex items-baseline gap-4'>
            <p className='grow font-bold'>{t('common:price_range')}</p>
            {hasValidFilters && (
              <Button
                as='a'
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClear();
                }}
                shape='text'
                className='text-primary-300 px-2 py-1 text-xs'
                theme='transparent'
              >
                {t('common:clear')}
              </Button>
            )}
          </div>

          <svg
            width='14'
            height='8'
            viewBox='0 0 14 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={`shrink-0 transform transition-transform duration-300 ${open ? 'rotate-180' : ''}`}
          >
            <path
              d='M12.2929 0.292892C12.6834 -0.0976324 13.3166 -0.0976324 13.7071 0.292892C14.0976 0.683416 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.32853 8.08568 6.71894 8.09893 6.32428 7.73715L0.324275 2.23715C-0.0828438 1.86396 -0.110347 1.23139 0.262846 0.824274C0.636039 0.417155 1.26861 0.389652 1.67572 0.762845L6.96992 5.61586L12.2929 0.292892Z'
              fill='#A1A1AA'
            />
          </svg>
        </div>
      </Button>
      <motion.div
        className='overflow-hidden'
        initial={false}
        animate={{ height: open ? 'auto' : 0 }}
        transition={{ duration: 0.3 }}
      >
        <form
          id='price-range-form'
          className='mt-2 lg:p-1'
          onSubmit={handleSubmit(applyPriceFilter)}
        >
          <div className='flex gap-3'>
            {Inputs}
            <Button
              form='price-range-form'
              type='submit'
              shape='text'
              theme='transparent'
              className='text-primary-300 ring-primary mt-2 hidden shrink-0 self-start px-2 py-1 text-xs lg:flex'
            >
              {t('common:apply')}
            </Button>
          </div>
        </form>
      </motion.div>
    </>
  );
};

export default PriceFilters;
