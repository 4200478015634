import { useParams } from 'react-router-dom';
import { Button, Carousel } from '@wbk/ui';
import ContentfulImage from '../images/ContentfulImage';
import type { ContentfulImage as ContentfulImageType } from '@wbk/contentful/api';

interface IProps {
  posters: ContentfulImageType[];
  selectedIndex: number;
  loop?: boolean;
  autoPlay?: boolean;
}

const Gallery = ({ posters, selectedIndex, loop = true, autoPlay = true }: IProps) => {
  const { lang } = useParams<{ lang: string }>();

  return (
    <div className='grid h-full place-items-center'>
      <Carousel
        locale={lang}
        options={{ align: 'center', loop, startIndex: selectedIndex }}
        autoplay={autoPlay}
        renderFooter={({ scrollPrev, scrollNext }) => (
          <div className='flex justify-center gap-4 pt-8'>
            <Button
              shape='outlined'
              theme='white'
              className='flex h-[44px] w-[44px] items-center justify-center rounded-xl p-0'
              onClick={scrollPrev}
              aria-label='Previous'
            >
              <img src='/icons/common/arrow.svg' className='ltr:rotate-180' alt='arrow-back' />
            </Button>

            <Button
              shape='outlined'
              theme='white'
              className='flex h-[44px] w-[44px] items-center justify-center rounded-xl p-0'
              onClick={scrollNext}
              aria-label='Next'
            >
              <img src='/icons/common/arrow.svg' className='rtl:rotate-180' alt='arrow-next' />
            </Button>
          </div>
        )}
      >
        {posters.map((image, i) => (
          <div
            className='aspect-1 mr-1 max-h-[450px] max-w-[450px] shrink-0 basis-full'
            key={image.sys.id + i}
          >
            <figure className='basis-full'>
              <ContentfulImage
                className='h-full w-full overflow-hidden rounded-lg object-contain'
                src={image.url}
                alt={image.title}
                placeholder
              />
            </figure>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Gallery;
