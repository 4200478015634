import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { usePage } from '@wbk/contentful/api';
import { AdSlotLB, AdSlotMLB } from '@smc/ads';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import HomeSearch from '@/components/search/HomeSearch';
import SearchProvider from '@/context/search/provider';
import PageSection from '@/components/section';

const Home = () => {
  const { lang } = useParams<{ lang: Language }>();
  const {
    data: home,
    isLoading,
    error,
  } = usePage({ lang, limit: 1, where: { slug: 'webook-home' } });

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (error) {
    throw error;
  }

  if (home) {
    return (
      <>
        <SearchProvider>
          <HomeSearch />
        </SearchProvider>

        {home?.sectionsCollection.items.map((section, idx) => (
          <Fragment key={section.__typename + idx}>
            {idx === 1 && (
              <>
                <AdSlotLB adUnit='home/lb' />
                <AdSlotMLB adUnit='home/mlb' />
              </>
            )}
            <PageSection section={section} />
          </Fragment>
        ))}
      </>
    );
  }

  return null;
};

export default Home;
