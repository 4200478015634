import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetZonePages } from '@wbk/contentful/api';
import ZonePagesCarousel from '@/components/zone/ZonePagesCarousel';

const OtherZonesCarousel = () => {
  const { lang, zoneSlug } = useParams<{ lang: Language; zoneSlug: string }>();
  const { t } = useTranslation('common');
  const { data, isLoading } = useGetZonePages({ lang, where: { slug_not: zoneSlug } });

  if (isLoading) {
    return (
      <ul className='flex gap-2 overflow-x-hidden py-12'>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((idx) => (
          <li
            key={idx}
            className='bg-body-light/50 h-96 shrink-0 basis-3/5 animate-pulse sm:basis-1/3 md:basis-1/4 xl:basis-1/6 2xl:basis-[14%]'
          ></li>
        ))}
      </ul>
    );
  }

  if (data) {
    return (
      <ZonePagesCarousel
        section={{
          title: t('common:other_zones'),
          id: 'other-zones',
          webLinksCollection: { items: [] },
          contentCollection: {
            items: data,
          },
          categoriesCollection: { items: [] },
        }}
      />
    );
  }

  return null;
};

export default OtherZonesCarousel;
