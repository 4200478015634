import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrganizationBySlug } from '@wbk/api/hooks';
import { useGetEventDetail } from '@wbk/contentful/api';
import Seo from '@/components/headers/seo';
import Error404 from '@/components/error/404';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import { eventJsonLd } from '@/components/headers/seo/json-ld';
import Terms from '@/components/saudi-cup/Terms';

const Hero = React.lazy(() => import('@/components/saudi-cup/Hero'));
const About = React.lazy(() => import('@/components/saudi-cup/About'));
const Tickets = React.lazy(() => import('@/components/saudi-cup/Tickets'));
const Essentials = React.lazy(() => import('@/components/saudi-cup/Essentials'));
const Gallery = React.lazy(() => import('@/components/saudi-cup/Gallery'));
const Sponsors = React.lazy(() => import('@/components/saudi-cup/Sponsors'));

const saudiCupCSS = (
  <style>{`
  #main {background:#ffffff; color:#000000}
`}</style>
);

const SaudiCupPage = () => {
  const { lang } = useParams<{ lang: Language; slug: string }>();

  const { data: eventData, isLoading } = useGetEventDetail({
    lang,
    limit: 1,
    where: { slug: 'saudi-cup' },
  });

  const event = eventData?.items[0];

  const { data, isLoading: isOrganizationLoading } = useGetOrganizationBySlug(
    {
      slug: event?.ticketingUrlSlug,
      lang,
    },
    { enabled: !!event }
  );

  if (isLoading || isOrganizationLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!event || !data) {
    return <Error404 />;
  }

  return (
    <>
      {saudiCupCSS}
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(eventJsonLd(event, `/${lang}/events/saudi-cup`)),
        }}
      />
      <Seo
        title={event.seo?.title || event.title}
        description={event.seo?.description}
        image={event.seo?.image?.url || event.image11.url}
      />

      <Hero event={event} />

      {/* <Benefits /> */}

      <About description={event.description} />

      <Terms terms={event.terms} />

      <Tickets events={data.event_groups} />

      {/* <Singers /> */}

      {/* <Highlights /> */}

      {/* <News /> */}

      <Essentials />

      <Gallery />

      <Sponsors event={event} />
    </>
  );
};

export default SaudiCupPage;
