import ContentfulImage from '../images/ContentfulImage';
import type { ContentfulImage as ContentfulImageType } from '@wbk/contentful/api';

const CardBackgroundMedia = ({ background }: { background: ContentfulImageType }) => {
  if (background.contentType?.includes('video')) {
    return (
      <video
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        className='pointer-events-none absolute inset-0 z-[-1] h-full w-full select-none object-cover opacity-50'
      >
        <source src={background.url} type={background.contentType} />
      </video>
    );
  }
  return (
    <ContentfulImage
      src={background.url}
      width={background.width}
      height={background.height}
      alt={background.title || ''}
      className='absolute inset-0 z-[-1] h-full w-full object-cover opacity-50'
    />
  );
};

export default CardBackgroundMedia;
