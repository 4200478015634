import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import EventLocation from '../events/EventItem/Location';
import type { ContentfulLocation } from '@wbk/contentful/api';

type Props = {
  location: ContentfulLocation;
  isOnline: boolean;
};

const LocationMap = ({ location, isOnline }: Props) => {
  const { t } = useTranslation('event');

  if (isOnline) {
    return (
      <div className='flex gap-1'>
        <img alt='' src='/icons/common/live-tv.svg' width={22} height={22} />
        {location.title}
      </div>
    );
  }

  return (
    <div className='space-y-2'>
      <Button
        as='a'
        theme='white'
        shape='text'
        href={`https://www.google.com/maps/search/?api=1&query=${location.location.lat},${location.location.lon}`}
        target='_blank'
        rel='noreferrer'
        className='relative flex h-full max-h-96 min-h-[225px] w-full flex-col justify-between overflow-hidden rounded-md !bg-cover !bg-center p-0'
        style={{ background: `url(${location.banner.url})` }}
      >
        {/* <ImageWithPlaceholder
          className='-z-10 w-full object-cover object-center'
          src={location.banner.url + '?w=800'}
          alt={location.title || ''}
          sizes='800px'
        /> */}
      </Button>
      <div className='flex items-start justify-between gap-4'>
        <EventLocation location={location} showTitle icon />
        <Button
          as='a'
          target='_blank'
          rel='noreferrer'
          href={`https://www.google.com/maps/search/?api=1&query=${location.location.lat},${location.location.lon}`}
          className='shrink-0 bg-white/10 py-1 text-sm'
          theme='white'
          shape='text'
        >
          {t('event:go_to_location')}
          <img
            src='/icons/common/location.svg'
            width={15}
            height={16}
            className='shrink-0 rtl:scale-x-[-1]'
            alt='direction'
          />
        </Button>
      </div>
    </div>
  );
};

export default LocationMap;
