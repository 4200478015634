import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '@wbk/analytics';
import { useGetEventDetail } from '@wbk/contentful/api';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import { eventJsonLd } from '@/components/headers/seo/json-ld';
import Breadcrumb from '@/components/common/Breadcrumb';
import EventTitleDetails from '@/components/events/TitleDetails';
import StickyEventInfo from '@/components/events/StickyInfo';
import EventTitleDescription from '@/components/events/Description';
import Seo from '@/components/headers/seo';
import SimilarEvents from '@/components/events/SimilarEvents';
import Error404 from '@/components/error/404';
import { parseContentfulToAnalyticsEvent } from '@/util/events';

const EventDetailPage = () => {
  const { slug, lang } = useParams<{ lang: Language; slug: string }>();
  const { data, isLoading } = useGetEventDetail({ lang, limit: 1, where: { slug } });
  const event = data?.items?.[0];
  const { t } = useTranslation('common');
  const { viewItemEvent } = useAnalytics();

  useEffect(() => {
    // GA4 Event
    if (event) {
      viewItemEvent({
        event: parseContentfulToAnalyticsEvent(event),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!event) {
    return <Error404 />;
  }

  return (
    <>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(eventJsonLd(event, `/${lang}/events/${slug}`)),
        }}
      />

      <Seo
        title={event.seo?.title || event.title}
        description={event.seo?.description}
        image={event.seo?.image?.url || event.image11.url}
      />

      <section className='container py-4'>
        <Breadcrumb
          items={[
            {
              href: `/${lang}/explore?type=events`,
              name: t('events'),
            },
            {
              name: event.title,
              href: event.slug,
              disabled: true,
              className: 'text-gray-400',
            },
          ]}
        />
      </section>

      <div className='container flex flex-col items-start gap-6 space-y-6 xl:flex-row xl:space-y-0'>
        <section className='w-full grow'>
          <EventTitleDetails event={event} />
          <EventTitleDescription event={event} />
        </section>

        <aside className='sticky top-24 mx-auto w-full max-w-xl shrink-0 grow gap-8 xl:max-w-lg'>
          <StickyEventInfo event={event} />
        </aside>
      </div>

      <SimilarEvents event={event} />
    </>
  );
};

export default EventDetailPage;
