import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import type { ContentfulRichText } from '@wbk/contentful/api';

type Props = {
  description: ContentfulRichText;
};

const ContentfulRichText = ({ description }: Props) => {
  const { json, links } = description;

  return documentToReactComponents(json, {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a href={node.data.uri} className='text-primary hover:underline'>
            {children}
          </a>
        );
      },
      [BLOCKS.UL_LIST]: (_, children) => {
        return <ul className='list-disc ltr:ml-4 rtl:mr-4'>{children}</ul>;
      },
      [BLOCKS.OL_LIST]: (_, children) => {
        return <ol className='list-decimal ltr:ml-4 rtl:mr-4'>{children}</ol>;
      },
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const assetId = node.data.target.sys.id;
        const asset = links?.assets?.block?.find((asset) => asset.sys.id === assetId);

        if (asset?.contentType?.includes('video')) {
          return (
            <video width={asset.width} height={asset.height} controls playsInline>
              <source src={asset.url} type={asset.contentType} />
              <track kind='captions' />
            </video>
          );
        } else if (asset?.contentType?.includes('image')) {
          return (
            <img src={asset.url} width={asset.width} height={asset.height} alt={asset.title} />
          );
        }

        return null;
      },
    },
  });
};

export default ContentfulRichText;
