export const EXPLORE_TYPE_COLOR: Record<ExploreTypes, string> = {
  events: '#A855F7',
  experiences: '#22C55E',
  restaurants: '#F97316',
  shows: '#EF4444',
  packages: '#eeeeee',
  streams: '#3373EF',
  explore: '#3373EF',
  conferences: '#3373EF',
  auctions: '#3373EF',
};

export const EXPLORE_TYPE_COLOR_LIGHT: Record<ExploreTypes, string> = {
  events: '#1a052e',
  experiences: '#062311',
  restaurants: '#321501',
  shows: '#2f0404',
  packages: '#212121',
  streams: '#051639',
  explore: '#051639',
  conferences: '#051639',
  auctions: '#051639',
};
