import { twMerge } from 'tailwind-merge';

type Props = {
  onClick: () => void;
  className?: string;
};

const CloseButton = ({ onClick, className = '' }: Props) => {
  return (
    <button
      className={twMerge(
        'absolute top-2 flex h-8 w-8 items-center justify-center rounded-full bg-zinc-700 p-2 font-sans text-lg leading-none',
        'font-bold text-gray-400 hover:bg-zinc-600 focus:bg-zinc-600 ltr:right-2 rtl:left-2',
        className
      )}
      onClick={() => onClick()}
      type='button'
    >
      <img src='/icons/common/close.svg' alt='close' className='h-4 w-4' />
    </button>
  );
};

export default CloseButton;
