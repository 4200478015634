import React from 'react';
import { RouteObject } from 'react-router-dom';
const PaymentSuccess = React.lazy(() => import('@/pages/payment/payment-success'));
const HyExperiencesPaymentSuccess = React.lazy(
  () => import('@/pages/payment/payment-success/experiences')
);
const PaymentFailed = React.lazy(() => import('@/pages/payment/payment-failed'));
const GeneratedPayment = React.lazy(() => import('@/pages/generated-payment'));
const UpgradePayment = React.lazy(() => import('@/pages/upgrade-payment'));
const ClaimOrderPage = React.lazy(() => import('@/pages/claim-order'));
const ChargeSuccess = React.lazy(() => import('@/pages/payment/charge-success'));
const ChargeFailed = React.lazy(() => import('@/pages/payment/charge-failed'));
import Error404 from '@/components/error/404';
import SubscribeSuccess from '@/pages/payment/subscribe-success';
import SubscribeFailed from '@/pages/payment/subscribe-failed';

export const paymentRoutes: RouteObject[] = [
  {
    path: 'payment-success/:id',
    errorElement: <Error404 />,
    element: <PaymentSuccess />,
  },
  {
    path: 'payment-success/experiences/:id',
    errorElement: <Error404 />,
    element: <HyExperiencesPaymentSuccess />,
  },
  {
    path: 'payment-failed/:id?',
    errorElement: <Error404 />,
    element: <PaymentFailed />,
  },
  {
    path: 'generated-payment/:id',
    errorElement: <Error404 />,
    element: <GeneratedPayment />,
  },
  {
    path: 'upgrade-payment/:id',
    errorElement: <Error404 />,
    element: <UpgradePayment />,
  },
  {
    path: 'claim-order/:token',
    errorElement: <Error404 />,
    element: <ClaimOrderPage />,
  },
  {
    path: 'charge-success/:id',
    errorElement: <Error404 />,
    element: <ChargeSuccess />,
  },
  {
    path: 'charge-failed/:id?',
    errorElement: <Error404 />,
    element: <ChargeFailed />,
  },
  {
    path: 'subscribe-success/:id',
    errorElement: <Error404 />,
    element: <SubscribeSuccess />,
  },
  {
    path: 'subscribe-failed/:id?',
    errorElement: <Error404 />,
    element: <SubscribeFailed />,
  },
];
