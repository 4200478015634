import Markdown from 'markdown-to-jsx';

type Props = {
  text: string;
};

const MarkdownRender = ({ text }: Props) => {
  return (
    <div className='prose w-full max-w-full text-white'>
      <Markdown
        options={{
          forceBlock: true,
          overrides: {
            a: {
              component: 'a',
              props: {
                className: 'text-primary hover:underline',
              },
            },
            h2: {
              props: {
                className: 'text-xl',
              },
            },
            p: {
              props: {
                className: 'my-1',
              },
            },
            ul: {
              props: {
                className: 'my-2',
              },
            },
            ol: {
              props: {
                className: 'my-2',
              },
            },
          },
        }}
      >
        {text}
      </Markdown>
    </div>
  );
};

export default MarkdownRender;
