import { useTranslation } from 'react-i18next';

const Achievements = () => {
  const { t } = useTranslation(['about']);
  const achievements = t('about:achievements', { returnObjects: true }) as {
    number: string;
    title: string;
  }[];

  return (
    <section className='bg-[url("/images/about/stats-bg.jpg")] bg-cover bg-fixed bg-center bg-no-repeat py-12'>
      <div className='container flex flex-col items-center space-y-12'>
        <div className='flex flex-col items-center gap-8 text-center md:flex-row'>
          <img width={187} height={100} alt='vision 2030' src='/images/about/vision_2030.png' />
          <p className='max-w-2xl text-lg'>{t('about:build_massive_ecosystem')}</p>
        </div>

        <div className='mx-auto h-px w-full max-w-5xl bg-gray-600' />

        <h3 className='text-center text-3xl'>{t('about:our_wins')}</h3>

        <ul className='flex flex-wrap justify-center gap-20 capitalize'>
          {achievements.map((achievement, index) => (
            <li key={index} className='min-w-[200px] space-y-4 text-center capitalize'>
              <div className='text-5xl font-bold'>{achievement.number}</div>
              <div className='text-lg'>{achievement.title}</div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Achievements;
