import { useEffect, useState } from 'react';
import { readCookie } from '@wbk/utils';
import { HideOnQueue } from '@wbk/queue';
import LoginButton from '../../buttons/LoginButton';
import UserMenu from './UserMenu';

const UserProfileButton = () => {
  const [hasCookie, setHasCookie] = useState(false);

  useEffect(() => {
    const token = readCookie('token');
    if (token) {
      setHasCookie(true);
    }
  }, []);

  return <HideOnQueue>{hasCookie ? <UserMenu /> : <LoginButton />}</HideOnQueue>;
};

export default UserProfileButton;
