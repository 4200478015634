import { Package } from '@wbk/contentful/api';
import { QueueView } from '@wbk/queue';
import GalleryWithThumbs from '../events/GalleryWithThumbs';
import ShareModal from '../common/ShareModal';
import EventDateTime from '../events/DateTime';
import ContentfulImage from '../images/ContentfulImage';
import BuyTicketButton from './BuyButton';
import StickyPackageBuyNav from './StickyBuyNav';
import MobileStickyBuy from './MobileStickyBuy';

type Props = {
  event: Package;
};

const PackagesTitle = ({ event }: Props) => {
  return (
    <>
      <StickyPackageBuyNav event={event} />

      <div className='space-y-4 pb-6'>
        {event.carousalCollection.items?.length > 0 ? (
          <div className='pb-4 xl:hidden'>
            <GalleryWithThumbs poster={event.image11} carousel={event.carousalCollection.items} />
          </div>
        ) : (
          <figure className='pb-2 xl:hidden'>
            <ContentfulImage
              className='aspect-1 mx-auto w-full max-w-2xl overflow-hidden rounded-lg object-cover sm:hidden'
              src={event.image11?.url}
              width={600}
              height={600}
              alt={event.title}
              placeholder
            />
            <ContentfulImage
              className='mx-auto hidden aspect-[3/1] w-full max-w-2xl overflow-hidden rounded-lg object-cover sm:block'
              src={event.image31?.url}
              width={600}
              height={400}
              alt={event.title}
              placeholder
            />
          </figure>
        )}

        <div className='flex flex-col-reverse items-center gap-6 md:flex-row'>
          <div className='grow'>
            <h1 className='text-2xl sm:text-4xl'>{event.title}</h1>
          </div>

          {event?.eventLogo && (
            <ContentfulImage
              className='max-w-[120px] object-cover'
              src={event?.eventLogo?.url}
              width={event?.eventLogo?.width || 300}
              height={event?.eventLogo?.height || 300}
              alt={event.title}
              placeholder
            />
          )}
        </div>

        <QueueView />

        <div
          className='flex w-full flex-col items-center gap-2 lg:flex-row [&>div]:w-full'
          data-testid='buy-tickets-cta'
        >
          <div className='xl:max-w-lg'>
            <BuyTicketButton event={event} className='m-0' />
          </div>
          <ShareModal key='desktop' share_description={event?.title} share_title={event?.title} />
        </div>

        <div className='space-y-1'>
          {event.subtitle && <p>{event.subtitle}</p>}
          <EventDateTime schedule={event.schedule} showIcon={false} />
        </div>
      </div>

      {/* Mobile sticky */}
      <MobileStickyBuy event={event} />
    </>
  );
};

export default PackagesTitle;
