import { ContentfulEvent } from '@wbk/contentful/api';
import { TicketingEvent } from '@wbk/api';
import { QueueView } from '@wbk/queue';
import ShareModal from '../common/ShareModal';
import BuyTicketButton from '../buttons/BuyButton';
import ContentfulImage from '../images/ContentfulImage';
import TitleInfo from '../events/TitleInfo';
import { NotifyButton } from '../buttons/NotifyButton';
import GalleryWithThumbs from './GalleryWithThumbs';
import { StickyEventNav } from './StickyEventNav';
import MobileStickyBuy from './MobileStickyBuy';
import ResellBanner from './ResellBanner';
import EventBookingTypeBadge from './BookingType';

type Props = {
  event: ContentfulEvent;
  ticketingEvent?: TicketingEvent;
};

const EventTitleDetails = ({ event, ticketingEvent }: Props) => {
  return (
    <>
      <StickyEventNav event={event} />
      <div className='space-y-4 pb-6'>
        {event.carousalCollection.items?.length > 0 ? (
          <div className='pb-4 xl:hidden'>
            <GalleryWithThumbs poster={event.image11} carousel={event.carousalCollection.items} />
          </div>
        ) : (
          <figure className='pb-2 xl:hidden'>
            <ContentfulImage
              className='aspect-1 mx-auto w-full max-w-2xl overflow-hidden rounded-lg object-cover sm:hidden'
              src={event.image11?.url}
              width={600}
              height={600}
              alt={event.title}
              placeholder
            />
            <ContentfulImage
              className='mx-auto hidden aspect-[3/1] w-full max-w-2xl overflow-hidden rounded-lg object-cover sm:block'
              src={event.image31?.url}
              width={600}
              height={400}
              alt={event.title}
              placeholder
            />
          </figure>
        )}

        <div className='flex flex-col-reverse items-start gap-6 md:flex-row md:items-center'>
          <div className='grow space-y-4'>
            <h1 className='text-2xl sm:text-4xl'>{event.title}</h1>
            <TitleInfo event={event} />
          </div>

          {event?.eventLogo && (
            <ContentfulImage
              className='mx-auto max-w-[120px] object-cover'
              src={event?.eventLogo?.url}
              width={event?.eventLogo?.width || 300}
              height={event?.eventLogo?.height || 300}
              alt={event.title}
              placeholder
            />
          )}
        </div>
        <QueueView />

        <div
          className='flex w-full flex-col items-center gap-2 lg:flex-row [&>div]:w-full'
          data-testid='buy-tickets-cta'
        >
          <div className='xl:max-w-lg'>
            {event.eventType?.includes('offline') ? (
              <NotifyButton
                event={event}
                type={event.__typename === 'Experience' ? 'experience' : 'event'}
              />
            ) : (
              <BuyTicketButton event={event} className='m-0' />
            )}
          </div>
          <ShareModal key='desktop' share_description={event?.title} share_title={event?.title} />
        </div>

        <EventBookingTypeBadge type={event.eventType} className='xl:max-w-lg' />
      </div>

      <ResellBanner
        slug={event.ticketingUrlSlug || event.slug}
        show={!!event?.showResellBanner || ticketingEvent?.has_resale_tickets}
        primary
      />

      {/* Mobile sticky */}
      <MobileStickyBuy event={event} />
    </>
  );
};

export default EventTitleDetails;
