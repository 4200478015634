import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ImageWithFallback } from '@wbk/ui';
import { useGetSubscriptionById } from '@wbk/api/hooks';
import { useDeviceDetect } from '@wbk/hooks';
import { formatDate } from '@wbk/utils';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import SubscribeFailed from '@/components/subscription/SubscribeFailed';

const SubscribeSuccess = () => {
  const { isWebView } = useDeviceDetect();
  const { id, lang } = useParams<{ id: string; lang: Language }>();
  const dateLocale = lang?.split('-')[0] || 'en';

  const { data, isLoading, error } = useGetSubscriptionById({
    subscriptionId: id || '',
    lang,
  });

  const { t } = useTranslation(['common', 'subscription']);

  useEffect(() => {
    if (data) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({ eventName: 'SUBSCRIBE_SUCCESS', subscription_id: id })
      );
    }
  }, [data, id]);

  if (error) {
    return <SubscribeFailed />;
  }

  if (isLoading || !data) {
    return <FullpageAnimatedLogo />;
  }

  return (
    <section
      data-webview={isWebView}
      className='data-[webview=true]:bg-body-light flex min-h-[calc(100svh-48px)] py-12 data-[webview=true]:min-h-svh'
    >
      <div className='container flex min-h-full max-w-xl flex-col justify-between'>
        <div className='flex grow flex-col items-center justify-center text-center'>
          <div className='relative mb-8 min-h-[216px] w-full max-w-[358px]'>
            <span className='absolute top-4 ltr:left-4 rtl:right-4'>
              {data.subscription.icon && (
                <img src={data.subscription.icon} alt={data.subscription.name} />
              )}
            </span>
            <ImageWithFallback src={data.plan.poster} width={358} height={216} />
            <p className='text-text absolute bottom-4 text-sm ltr:left-4 rtl:right-4'>
              {t('subscription:expires')}{' '}
              {formatDate(data.end_date, `${dateLocale}-GB`, {
                dateStyle: 'long',
              })}
            </p>
          </div>
          <h2 className='mb-2 text-4xl font-semibold capitalize'>
            {t('subscription:congratulations')}
          </h2>
          <p>
            {t('subscription:subscribed_to', {
              subscription_name: data.subscription.name,
              plan_name: data.plan.name,
            })}
          </p>
        </div>

        <div className='mx-auto w-full max-w-xs space-y-4'>
          <Button
            className='w-full'
            theme='white'
            shape='outlined'
            onClick={() => {
              window.ReactNativeWebView?.postMessage(
                JSON.stringify({ eventName: 'CLOSE_SUBSCRIBE' })
              );
            }}
          >
            {t('common:ok')}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SubscribeSuccess;
