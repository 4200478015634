import React from 'react';
import { RouteObject } from 'react-router-dom';
import Error500 from '@/components/error/500';
const ProfileInfoPage = React.lazy(() => import('@/pages/profile/info'));
const ProfileLayout = React.lazy(() => import('@/pages/profile/layout'));
const BookingHistoryPage = React.lazy(() => import('@/pages/profile/bookings'));
const OrderDetail = React.lazy(() => import('@/pages/profile/bookings/id'));
const ExperienceOrderDetail = React.lazy(() => import('@/pages/profile/bookings/experiences/id'));
const SeasonOrderDetail = React.lazy(() => import('@/pages/profile/bookings/seasons/id'));

export const profileRoutes: RouteObject[] = [
  {
    path: 'profile',
    errorElement: <Error500 />,
    element: <ProfileLayout />,
    children: [
      {
        path: '',
        element: <ProfileInfoPage />,
      },
      {
        path: 'bookings',
        element: <BookingHistoryPage />,
      },
      {
        path: 'bookings/:id',
        element: <OrderDetail />,
      },
      {
        path: 'bookings/experiences/:id',
        element: <ExperienceOrderDetail />,
      },
      {
        path: 'bookings/seasons/:id',
        element: <SeasonOrderDetail />,
      },
    ],
  },
];
