import { WebsiteConfig } from '@wbk/contentful/api';
import { useDeviceDetect } from '@wbk/hooks';
import HideOnWebview from '@/components/webview/HideOnWebview';
import MainFooter from './Main';
import MiniFooter from './Mini';

type Props = {
  type?: LayoutType;
  config: WebsiteConfig;
};

const Footer = ({ type = 'main', config }: Props) => {
  const { isWebView } = useDeviceDetect();

  return (
    <HideOnWebview>
      {isWebView || ['mini', 'booking', 'auth'].includes(type) ? (
        <MiniFooter config={config} />
      ) : (
        <MainFooter config={config} />
      )}
    </HideOnWebview>
  );
};

export default Footer;
