const StaticCheck = ({ className = '' }) => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <rect x='0.5' width='16' height='16' rx='8' fill='currentColor' />
      <path
        d='M11.8472 4.70553C12.0558 4.46006 12.424 4.43021 12.6694 4.63886C12.9149 4.84751 12.9448 5.21565 12.7361 5.46112L7.77779 11.2945C7.56586 11.5438 7.19035 11.57 6.94578 11.3526L4.32078 9.01931C4.07999 8.80528 4.0583 8.43657 4.27233 8.19578C4.48637 7.95499 4.85508 7.9333 5.09587 8.14734L7.27511 10.0844L11.8472 4.70553Z'
        fill='white'
      />
    </svg>
  );
};

export default StaticCheck;
