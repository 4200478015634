import React, { ComponentType, ErrorInfo } from 'react';
import {
  Link,
  Location,
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { i18n } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import Seo from '../headers/seo';
import { logger } from '../../../logger.config';

interface RouterProps {
  navigate: NavigateFunction;
  readonly params: Params<string>;
  location: Location;
  i18n: i18n;
}
export type WithRouterProps<T> = T & RouterProps;
type OmitRouter<T> = Omit<T, keyof RouterProps>;
type State = {
  hasError: boolean;
  eventId?: string;
};

export function withRouter<T>(Component: ComponentType<OmitRouter<T> & RouterProps>) {
  return (props: OmitRouter<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const { i18n } = useTranslation();

    return (
      <Component location={location} navigate={navigate} params={params} i18n={i18n} {...props} />
    );
  };
}

interface Props {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

class ErrorBoundary extends React.Component<WithRouterProps<Props>, State> {
  constructor(props: WithRouterProps<Props>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true, eventId: logger.lastId() };
  }

  initReportDialog = () => {
    logger.showReportDialog({
      eventId: this.state.eventId || 'unknown-id',
      lang: this.props.i18n.language,
      title: this.props.i18n.t('common:errors.boundry_error'),
      subtitle: this.props.i18n.t('common:errors.boundry_error_description'),
      subtitle2: '',
      labelClose: this.props.i18n.t('common:cancel'),
      labelSubmit: this.props.i18n.t('common:errors.send_report'),
      labelComments: this.props.i18n.t('common:errors.boundry_error_label_comments'),
      successMessage: this.props.i18n.t('common:errors.send_report_success'),
    });
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // If has custom fallback, don't show the report dialog
    if (!this.props.fallback) {
      this.initReportDialog();
      // error.name = '[Fatal] ErrorBoundary';
      // error.message = error.message || 'No message attached';
      logger.captureException(error, {
        extra: { errorInfo },
        level: 'fatal',
        tags: { ErrorBoundary: true },
      });
    }
  }

  componentDidUpdate(prevProps: WithRouterProps<Props>) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return (
        <>
          <Seo title={this.props.i18n.t('common:errors.boundry_error')} />

          <main className='flex h-full min-h-screen items-center justify-center p-4'>
            <section className='mx-auto max-w-lg rounded-lg bg-white/10 px-6 py-10'>
              <h1 className='mb-6 text-center text-3xl capitalize'>
                <strong>{this.props.i18n.t('common:errors.boundry_error').toString()}</strong>
              </h1>
              <p>{this.props.i18n.t('common:errors.boundry_error_description').toString()}</p>
              <div className='mx-auto max-w-xs'>
                <Button className='mt-6 w-full text-lg' onClick={this.initReportDialog}>
                  {this.props.i18n.t('common:errors.send_report')}
                </Button>
                <Button as={Link} shape='outlined' href='/' className='mt-6 w-full text-lg'>
                  {this.props.i18n.t('common:go_home')}
                </Button>
              </div>
            </section>
          </main>
        </>
      );
    }

    return this.props.children;
  }
}

export default withRouter<Props>(ErrorBoundary);
