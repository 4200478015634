import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { ContentfulEvent } from '@wbk/contentful/api';
import { resolveContentfulEventType } from '@/util/events';
import ContentfulImage from '../../images/ContentfulImage';
import EventBookingType from '../BookingType';
import EventItemType from './Type';
import EventItemPrice from './Price';
import EventItemZone from './Zone';
import EventLocation from './Location';

type Props = {
  event: ContentfulEvent;
  cardType?: 'default' | 'overlay';
  utm?: string;
};

const EventItem = ({ event, cardType = 'default', utm = '' }: Props) => {
  const resolvedType = resolveContentfulEventType(event.__typename);
  const { lang } = useParams<{ lang: Language }>();

  return (
    <Button
      key={event.id}
      theme='transparent'
      as={Link}
      queueProtected
      data-testid={`item_${resolvedType}_${event.slug}`}
      to={`/${lang}/${resolvedType}/${event.slug}${utm}`}
      className='group relative h-full w-full gap-0 bg-transparent p-px ring-offset-white focus:ring-1'
    >
      <div className='flex h-full w-full flex-col items-start overflow-hidden rounded-md'>
        <div
          className={`relative w-full overflow-hidden rounded-md ${cardType === 'overlay' ? 'h-full' : ''}`}
        >
          <EventItemZone event={event} />
          <ContentfulImage
            className='aspect-1 h-full object-cover transition duration-300 group-hover:scale-[1.02] group-focus:scale-[1.02]'
            src={event.image11?.url}
            width={600}
            height={600}
            ratio='1x1'
            alt={event.title}
            fit='thumb'
            placeholder
          />
        </div>

        {cardType === 'overlay' ? (
          <>
            <div className='absolute inset-0 scale-[1.02] bg-gradient-to-b from-transparent via-black/50 to-black/95 to-85%' />
            <div className='absolute inset-0 z-20 flex flex-col justify-between p-2'>
              <div />
              <div className='text-text space-y-0.5 pt-2 text-start'>
                <EventItemType event={event} />
                <p className='line-clamp-2'>{event.title}</p>
                <EventLocation location={event.location} className='text-gray-400' />
                <EventItemPrice event={event} />
                <EventBookingType className='py-1' type={event.eventType} mini />
              </div>
            </div>
          </>
        ) : (
          <div className='text-text space-y-0.5 pt-2 text-start'>
            <EventItemType event={event} />
            <p className='line-clamp-2'>{event.title}</p>
            <EventLocation location={event.location} className='text-gray-400' />
            <EventItemPrice event={event} />
            <EventBookingType className='py-1' type={event.eventType} mini />
          </div>
        )}
      </div>
    </Button>
  );
};

export default EventItem;
