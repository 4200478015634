import { useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BottomSheet, Button } from '@wbk/ui';
import { Zone, useGetZones } from '@wbk/contentful/api';
import LocationIcon from '@/components/svg/Location';
import CloseButton from '@/components/buttons/CloseButton';
import StaticCheck from '../svg/StaticCheck';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  render?: 'desktop' | 'mobile';
  category?: string;
};

const ZoneFilters = ({ render = 'desktop', category }: Props) => {
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [showAll, setShowAll] = useState(false);
  const { lang } = useParams<{ lang: Language }>();
  const { data, isLoading } = useGetZones({
    lang,
    where: category ? { category: { slug_contains: category } } : { category_exists: false },
  });

  const selectedZone = searchParams.get('zone');
  const [localZone, setLocalZone] = useState(selectedZone);

  const handleClear = () => {
    const newUrl = new URLSearchParams(searchParams);
    newUrl.delete('zone');
    navigate(`${pathname}?${newUrl.toString()}`, { replace: true });
  };

  const toggleCollapse = () => {
    setOpen((prev) => !prev);
  };

  const handleFilterClick = (slug: string) => {
    const newUrl = new URLSearchParams(searchParams);
    newUrl.delete('page');

    if (slug === selectedZone) {
      newUrl.delete('zone');
    } else {
      newUrl.set('zone', slug);
    }

    navigate(`${pathname}?${newUrl.toString()}`);
  };

  const handleMobileApply = () => {
    const newUrl = new URLSearchParams(searchParams.toString());
    newUrl.delete('page');
    if (localZone) {
      newUrl.set('zone', localZone);
    } else {
      newUrl.delete('zone');
    }
    const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
    navigate(`${pathname}${search}`, { replace: true });
  };

  const zoneTitle = category ? t('common:city') : t('common:zone');

  if (!data?.length) {
    return null;
  }

  if (render === 'mobile') {
    return (
      <BottomSheet
        rounded
        ignoreHistory
        render={({ open }) => (
          <Button
            theme='primary'
            onClick={open}
            className={`border-primary bg-body-light hover:bg-primary/30 focus:bg-primary/30 active:text-text shrink-0 border-2 px-2.5 py-1 text-sm text-gray-100 shadow-md lg:hidden ${
              selectedZone
                ? 'bg-primary/30 text-primary focus:border-2 active:border-2'
                : 'border-transparent'
            }`}
            phill={false}
            rounded
          >
            <LocationIcon />
            <span>{zoneTitle}</span>

            {!!selectedZone && (
              <a
                href='#!'
                className='text-primary relative -mt-px h-5 w-5 rounded-full bg-white/10 p-1 font-sans text-[16px] ltr:ml-1 rtl:mr-1'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const newUrl = new URLSearchParams(searchParams.toString());
                  newUrl.delete('zone');
                  const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
                  navigate(`${pathname}${search}`, { replace: true });
                }}
              >
                <span className='absolute left-1/2 top-1/2 -mt-px -translate-x-1/2 -translate-y-1/2 leading-none'>
                  x
                </span>
              </a>
            )}
          </Button>
        )}
        stickyFooter={({ close }) => (
          <div className='p-2 pb-4 text-center'>
            <Button
              className='w-full max-w-sm'
              onClick={() => {
                handleMobileApply();
                close();
              }}
            >
              {t('common:apply')}
            </Button>
          </div>
        )}
        body={({ close }) => (
          <div className='px-4 pb-24'>
            <div className='flex items-center justify-between'>
              <h4 className='font-bold'>{zoneTitle}</h4>
              <CloseButton onClick={close} className='relative left-0 right-0 top-0' />
            </div>
            <ul className='mt-4 grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6'>
              {isLoading
                ? [1, 2, 3, 4, 5, 6].map((idx) => (
                    <li
                      key={idx}
                      className='aspect-1 border-body bg-body-light h-[102px] w-full animate-pulse rounded-md border-2'
                    ></li>
                  ))
                : data?.map((zone) => (
                    <li key={zone.id} className='p-0.5 [&>button]:p-1'>
                      <ZoneItem
                        onClick={(slug) => setLocalZone(slug)}
                        selected={localZone}
                        zone={zone}
                      />
                    </li>
                  ))}
            </ul>
          </div>
        )}
      />
    );
  }

  return (
    <div className='hidden space-y-2 lg:block'>
      <Button
        theme='white'
        shape='text'
        className='w-full items-center justify-between p-2'
        onClick={toggleCollapse}
        phill={false}
        rounded
      >
        <div className='flex w-full items-baseline justify-between gap-2 text-start'>
          <div className='flex items-baseline gap-4'>
            <p className='grow font-bold'>{zoneTitle}</p>
            {searchParams.has('zone') && (
              <Button
                as='a'
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleClear();
                }}
                shape='text'
                className='text-primary-300 px-2 py-1 text-xs'
                theme='transparent'
              >
                {t('common:clear')}
              </Button>
            )}
          </div>

          <svg
            width='14'
            height='8'
            viewBox='0 0 14 8'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            className={`shrink-0 transform transition-transform duration-300 ${open ? 'rotate-180' : ''}`}
          >
            <path
              d='M12.2929 0.292892C12.6834 -0.0976324 13.3166 -0.0976324 13.7071 0.292892C14.0976 0.683416 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.32853 8.08568 6.71894 8.09893 6.32428 7.73715L0.324275 2.23715C-0.0828438 1.86396 -0.110347 1.23139 0.262846 0.824274C0.636039 0.417155 1.26861 0.389652 1.67572 0.762845L6.96992 5.61586L12.2929 0.292892Z'
              fill='#A1A1AA'
            />
          </svg>
        </div>
      </Button>
      <motion.div
        className={`overflow-hidden ${Number(data?.length) <= 6 ? 'pb-1' : ''}`}
        initial={false}
        animate={{ height: open ? 'auto' : 0 }}
        transition={{ duration: 0.3 }}
      >
        <motion.ul
          className='grid grid-cols-3 overflow-hidden'
          initial={false}
          animate={{ height: showAll ? 'auto' : 230 }}
          transition={{ duration: 0.3 }}
        >
          {isLoading
            ? [1, 2, 3, 4, 5, 6].map((idx) => (
                <li
                  key={idx}
                  className='aspect-1 border-body bg-body-light h-[102px] w-full animate-pulse rounded-md border-2'
                ></li>
              ))
            : data?.map((zone) => (
                <li key={zone.id} className='p-0.5'>
                  <ZoneItem onClick={handleFilterClick} selected={selectedZone} zone={zone} />
                </li>
              ))}
        </motion.ul>

        {Number(data?.length) > 6 && (
          <Button
            shape='text'
            theme='transparent'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAll((prev) => !prev);
            }}
            className='text-primary-300 p-2 text-xs ring-0 hover:underline focus:ring-0'
          >
            {t(`common:${showAll ? 'see_less' : 'see_all'}`)}
          </Button>
        )}
      </motion.div>

      <hr className='border-body-lighter' />
    </div>
  );
};

type ZoneItemProps = {
  onClick: (slug: string) => void;
  selected?: string | null;
  zone: Zone;
};
const ZoneItem = ({ onClick, selected, zone }: ZoneItemProps) => {
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        onClick(zone.slug);
      }}
      shape='text'
      theme='primary'
      className={`aspect-1 bg-body-light hover:bg-body-lighter relative w-full border-2 ${
        selected === zone.slug
          ? 'border-primary bg-primary/20 hover:bg-primary/30'
          : 'border-transparent'
      }`}
      phill={false}
      rounded
    >
      {selected === zone.slug && (
        <StaticCheck className='absolute right-1 top-1 h-6 w-6 rtl:left-1' />
      )}
      <div className='flex flex-col gap-2'>
        <ContentfulImage
          className='mx-auto shrink-0'
          src={zone.zoneLogo.url}
          width={zone.sponsorLogo ? 60 : 80}
          height={zone.sponsorLogo ? 60 : 80}
          alt={zone.title}
          fit='pad'
        />
        {zone.sponsorLogo && (
          <ContentfulImage
            className='mx-auto max-h-5 w-auto'
            width={zone?.sponsorLogo?.width || 200}
            height={zone?.sponsorLogo?.height || 100}
            src={zone.sponsorLogo.url}
            alt={zone.title}
            fit='crop'
          />
        )}
      </div>
    </Button>
  );
};

export default ZoneFilters;
