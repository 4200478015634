import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import FilterTypeIcon from '@/components/explore/FilterTypeIcon';
import useSearch from '@/context/search/useSearch';
import ContentfulImage from '../images/ContentfulImage';
import SearchFeaturedEvents from './SearchFeaturedEvents';

const SearchInitialScreen = () => {
  const { t } = useTranslation('common');
  const { lang } = useParams<{ lang: Language }>();
  const { searchHistory, onHistoryClear, onSearch, togglePopover, zoneLoading, zones } =
    useSearch();

  return (
    <>
      {searchHistory.length > 0 && (
        <div className='space-y-2'>
          <div className='flex items-center justify-between'>
            <p className='text-2xl font-semibold'>{t('common:recent_searches')}</p>
            <Button
              data-testid='header_search_clear_history'
              onClick={() => {
                onHistoryClear();
              }}
              shape='text'
              className='text-text-100 py-1 text-sm font-semibold'
            >
              {t('common:clear')}
            </Button>
          </div>
          <ul className='flex flex-wrap gap-2'>
            {searchHistory.map((term, i) => (
              <li key={i}>
                <Button
                  data-testid={`header_search_history_button_${term}`}
                  onClick={() => onSearch(term)}
                  theme='primary'
                  className='bg-body-lighter hover:bg-primary/30 focus:bg-primary/30 active:text-text shrink-0 px-2.5 py-1 text-sm text-gray-400 shadow-md'
                  phill={false}
                  rounded
                >
                  <img className='h-5 w-5' src='/icons/common/history-time.svg' alt='' />
                  <span>{term}</span>
                </Button>
              </li>
            ))}
          </ul>
          <hr className='border-body-lighter !my-6 -mx-4 md:mx-0' />
        </div>
      )}
      <SearchFeaturedEvents />
      <div className='space-y-2'>
        <p className='text-2xl font-semibold'>{t('common:explore_things')}</p>
        <ul className='flex flex-wrap gap-2'>
          {['events', 'experiences', 'restaurants', 'shows', 'streams', 'conferences'].map(
            (type) => (
              <li key={type}>
                <Button
                  data-testid={`header_search_other_options_${type}`}
                  as={Link}
                  to={`/${lang}/explore?type=${type}`}
                  onClick={() => togglePopover(false)}
                  theme='primary'
                  className='bg-body-lighter hover:bg-primary/30 focus:bg-primary/30 active:text-text shrink-0 px-2.5 py-1 text-sm text-gray-400 shadow-md'
                  phill={false}
                  rounded
                >
                  <FilterTypeIcon type={type as ExploreTypes} />
                  <span>{t(type)}</span>
                </Button>
              </li>
            )
          )}
        </ul>
        <hr className='border-body-lighter !my-6 -mx-4 md:mx-0' />
      </div>

      <div className='space-y-2'>
        <p className='text-2xl font-semibold'>{t('common:explore_zones')}</p>
        <ul className='grid grid-cols-3 gap-1 md:grid-cols-4'>
          {zoneLoading
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((idx) => (
                <li
                  key={idx}
                  className='aspect-1 bg-body-lighter h-[70px] w-full animate-pulse rounded-md'
                ></li>
              ))
            : zones?.map((page, i) => {
                const zone = page.sectionsCollection?.items?.[0]?.zone;
                if (!zone) return null;
                return (
                  <li key={zone.slug + i}>
                    <Button
                      data-testid={`search_zone_button_${zone.slug}`}
                      as={Link}
                      to={`/${lang}/zones/${page.slug}`}
                      onClick={() => togglePopover(false)}
                      className='group relative h-full w-full flex-col overflow-hidden bg-white/5 p-3 transition-colors hover:bg-white/10 focus:bg-white/10 active:bg-white/10'
                      theme='transparent'
                      shape='text'
                      phill={false}
                      rounded
                    >
                      <ContentfulImage
                        className='relative z-10 mx-auto shrink-0'
                        src={zone.zoneLogo.url}
                        width={zone.sponsorLogo ? 40 : 60}
                        height={zone.sponsorLogo ? 40 : 60}
                        alt={zone.title}
                        fit='pad'
                      />
                      {zone.sponsorLogo && (
                        <ContentfulImage
                          className='relative z-10 mx-auto max-h-4 w-auto'
                          width={zone?.sponsorLogo?.width || 200}
                          height={zone?.sponsorLogo?.height || 100}
                          src={zone.sponsorLogo.url}
                          alt={zone.title}
                          fit='crop'
                        />
                      )}
                    </Button>
                  </li>
                );
              })}
        </ul>
      </div>
    </>
  );
};

export default SearchInitialScreen;
