const EventIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          opacity='0.3'
          d='M10.292 13.4835L6.51699 9.7085L4.41699 15.5835L10.292 13.4835Z'
          fill='currentColor'
        />
        <path
          d='M1.66699 18.3332L13.3337 14.1665L5.83366 6.6665L1.66699 18.3332ZM10.292 13.4832L4.41699 15.5832L6.51699 9.70817L10.292 13.4832Z'
          fill='currentColor'
        />
        <path
          d='M12.1089 10.4419L16.7673 5.78353C17.1756 5.3752 17.8339 5.3752 18.2423 5.78353L18.7339 6.2752L19.6173 5.39186L19.1256 4.9002C18.2339 4.00853 16.7756 4.00853 15.8839 4.9002L11.2256 9.55853L12.1089 10.4419Z'
          fill='currentColor'
        />
        <path
          d='M7.8916 6.22546L8.77493 7.10879L9.2666 6.61712C10.1583 5.72546 10.1583 4.26712 9.2666 3.37546L8.77493 2.88379L7.8916 3.77546L8.38327 4.26712C8.78327 4.66712 8.78327 5.33379 8.38327 5.73379L7.8916 6.22546Z'
          fill='currentColor'
        />
        <path
          d='M14.2166 9.9002L12.8916 11.2252L13.7749 12.1085L15.0999 10.7835C15.5083 10.3752 16.1666 10.3752 16.5749 10.7835L17.9166 12.1252L18.7999 11.2419L17.4583 9.9002C16.5583 9.00853 15.1083 9.00853 14.2166 9.9002Z'
          fill='currentColor'
        />
        <path
          d='M12.5503 4.90013L9.55859 7.8918L10.4419 8.77513L13.4336 5.78346C14.3253 4.8918 14.3253 3.43346 13.4336 2.5418L12.1086 1.2168L11.2253 2.10013L12.5503 3.42513C12.9503 3.83346 12.9503 4.50013 12.5503 4.90013Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default EventIcon;
