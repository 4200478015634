import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { addDays, endOfWeek, format, startOfWeek } from 'date-fns';
import { parseDate } from '@wbk/utils';
import { DEFAULT_PAGINATION } from '@/constants/pagination';

type Props = {
  detectedLang?: Language;
  category?: string;
};

const useExploreQuery = ({ detectedLang, category }: Props = {}) => {
  const [searchParams] = useSearchParams();
  const { lang, zoneSlug } = useParams<{ lang: Language; zoneSlug?: string }>();
  const types = (searchParams.getAll('type') || []) as ExploreTypes[];
  const zone = searchParams.get('zone') || zoneSlug;
  const startPrice = Number(searchParams.get('price_from')) || null;
  const endPrice = Number(searchParams.get('price_to')) || null;
  const withStream = types.includes('streams');
  const isConferences = types.includes('conferences');
  const categories = searchParams
    .getAll('category')
    ?.concat(isConferences ? 'conferences' : [])
    ?.concat(category || []);

  const page = searchParams.get('page');
  const perPage = DEFAULT_PAGINATION;

  const sortQuery = useMemo(() => {
    const sortParam = searchParams.get('sort');
    switch (sortParam) {
      case 'latest':
        return 'sys_publishedAt_DESC';
      case 'oldest':
        return 'sys_publishedAt_ASC';
      case 'high_price':
        return 'startingPrice_DESC';
      case 'low_price':
        return 'startingPrice_ASC';
      default:
        return ['order_ASC', 'sys_publishedAt_DESC'];
    }
  }, [searchParams]);

  const searchQuery = useMemo(() => {
    const search = searchParams.get('q');
    if (search) {
      return {
        OR: [
          { title_contains: search },
          { description_contains: search },
          { category: { title_contains: search } },
          { location: { title_contains: search } },
          { zone: { title_contains: search } },
          {
            seo: {
              OR: [
                { title_contains: search },
                { keywords_contains: search },
                { description_contains: search },
              ],
            },
          },
        ],
      };
    }
    return {};
  }, [searchParams]);

  const dateQuery = useMemo(() => {
    const date = searchParams.get('date');
    const isRange = date?.includes('_');

    let from = '';
    let to = '';

    if (isRange) {
      const [qFrom, qTo] = date?.split('_') || [];
      const safeFrom = parseDate(qFrom);
      const safeTo = parseDate(qTo);

      if (!!safeFrom?.getTime() && !!safeTo?.getTime()) {
        from = format(safeFrom, "yyyy-MM-dd'T00:00:00.000+03:00'");
        to = format(safeTo, "yyyy-MM-dd'T23:59:59.000+03:00'");
      }
    } else if (date === 'today') {
      from = format(new Date(), "yyyy-MM-dd'T00:00:00.000+03:00'");
      to = format(new Date(), "yyyy-MM-dd'T23:59:59.000+03:00'");
    } else if (date === 'tomorrow') {
      from = format(addDays(new Date(), 1), "yyyy-MM-dd'T00:00:00.000+03:00'");
      to = format(addDays(new Date(), 1), "yyyy-MM-dd'T23:59:59.000+03:00'");
    } else if (date === 'thisweek') {
      from = format(
        startOfWeek(new Date(), { weekStartsOn: 0 }),
        "yyyy-MM-dd'T00:00:00.000+03:00'"
      );
      to = format(endOfWeek(new Date(), { weekStartsOn: 0 }), "yyyy-MM-dd'T23:59:59.000+03:00'");
    }

    if (from && to) {
      return {
        OR: [
          {
            schedule: {
              openDateTime_lte: to,
              closeDateTime_gte: from,
            },
          },
          {
            schedule: {
              openDateTime_gte: from,
              openDateTime_lte: to,
            },
          },
          {
            schedule: {
              openDateTime_lte: to,
              closeDateTime_exists: false,
            },
          },
        ],
      };
    }
  }, [searchParams]);

  const query: CommonFetchProps = {
    lang: detectedLang || lang,
    limit: perPage,
    skip: (Number(page) - 1) * perPage,
    order: sortQuery,
    where: {
      zone: { slug: zone },
      category: { slug_in: categories.length ? categories : null },
      startingPrice_gte: startPrice,
      startingPrice_lte: endPrice,
      ...searchQuery,
      ...dateQuery,
      ...(withStream ? { isStreamingEvent: true } : {}),
    },
  };

  return {
    types: types.filter((type) => type !== 'streams' && type !== 'conferences'),
    query,
    perPage,
  };
};

export default useExploreQuery;
