import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ContentfulEvent,
  useGetEventsList,
  useGetExperiences,
  useGetShows,
} from '@wbk/contentful/api';
import { getItem } from '@wbk/utils';
import EventsGridCarousel from '../events/EventsGridCarousel';

const SEARCH_LIMIT = 4;

/**
 * This component should suggest some activities based on selected filters
 * However, since filters filters are not yet applied
 * will try to suggest based on local history search
 */
const SearchMightLikeSection = () => {
  const { t } = useTranslation('common');
  const { lang } = useParams<{ lang: Language }>();

  const query = useMemo(() => {
    const prevHistory = getItem('search_history');
    const history = (prevHistory ? JSON.parse(prevHistory) : []) as string[];
    const suggestions = [] as Array<{ [k: string]: unknown }>;

    for (const his of history) {
      // take first 3 letters
      suggestions.push(
        { title_contains: his.slice(0, 3) },
        { description_contains: his.slice(0, 3) },
        { category: { title_contains: his.slice(0, 3) } },
        { location: { title_contains: his.slice(0, 3) } },
        { zone: { title_contains: his.slice(0, 3) } },
        {
          seo: {
            OR: [
              { title_contains: his.slice(0, 3) },
              { keywords_contains: his.slice(0, 3) },
              { description_contains: his.slice(0, 3) },
            ],
          },
        }
      );
    }

    return {
      lang,
      limit: SEARCH_LIMIT,
      where: {
        OR: suggestions,
      },
    };
  }, [lang]);

  const canFetch = query.where.OR.length > 0;

  const { data: events } = useGetEventsList(query, { enabled: canFetch });

  const { data: experiences } = useGetExperiences(query, { enabled: canFetch });

  const { data: shows } = useGetShows(query, { enabled: canFetch });

  const allMergedEvents = useMemo(() => {
    const eventsData = events?.items || [];
    const experiencesData = experiences?.items || [];
    const showsData = shows?.items || [];

    return eventsData.concat(experiencesData, showsData);
  }, [events, experiences, shows]);

  if (allMergedEvents.length <= 0) {
    return null;
  }

  return (
    <div className='border-body-lighter container border-t px-4 pb-20 pt-4 lg:[&>section>div>div>div>div]:basis-1/3 xl:[&>section>div>div>div>div]:basis-1/4 [&>section]:space-y-0 [&>section]:py-4'>
      <div className=''>
        <p>{t('common:empty_search_suggestion')}</p>
      </div>
      <EventsGridCarousel
        section={{
          categoriesCollection: {
            items: [],
          },
          contentCollection: {
            items: allMergedEvents as ({ __typename: 'Event' } & ContentfulEvent)[],
          },
          title: '',
          id: 'might-like-events',
          webLinksCollection: {
            items: [],
          },
        }}
      />
    </div>
  );
};

export default SearchMightLikeSection;
