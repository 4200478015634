import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAnalytics } from '@wbk/analytics';
import { useGetAuctionDetails } from '@wbk/contentful/api';
import { QueueView } from '@wbk/queue';
import { ImageWithPlaceholder } from '@wbk/ui';
import JoinAuctionButton from '@/components/auction/JoinButton';
import ShareModal from '@/components/common/ShareModal';
import Error404 from '@/components/error/404';
import Seo from '@/components/headers/seo';
import { eventJsonLd } from '@/components/headers/seo/json-ld';
import CollapsableText from '@/components/text/CollapsableText';
import MarkdownRender from '@/components/text/MarkdownRender';
import FullpageAnimatedLogo from '@/components/ui/spinners/FullpageAnimatedLogo';
import { parseContentfulToAnalyticsEvent } from '@/util/events';

const AuctionDetailPage = () => {
  const { slug, lang } = useParams<{ lang: Language; slug: string }>();
  const { data, isLoading } = useGetAuctionDetails({ lang, limit: 1, where: { slug } });
  const { t } = useTranslation('common');
  const { viewItemEvent } = useAnalytics();

  useEffect(() => {
    // GA4 Event
    if (data) {
      // type AnalyticsEvent issue
      viewItemEvent({
        event: parseContentfulToAnalyticsEvent(data),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) {
    return <FullpageAnimatedLogo />;
  }

  if (!data) {
    return <Error404 />;
  }

  return (
    <>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(eventJsonLd(data, `/${lang}/auctions/${slug}`)),
        }}
      />

      <Seo
        title={data.seo?.title || data.title}
        description={data.seo?.description}
        image={data.seo?.image?.url || data.image11.url}
      />

      <section className='container max-w-xl space-y-2 rounded-md pb-10 pt-4'>
        <figure className=''>
          <ImageWithPlaceholder
            className='aspect-1 mx-auto w-full max-w-sm overflow-hidden rounded-lg object-cover sm:hidden'
            src={data.image11?.url}
            width={600}
            height={600}
            alt={data.title}
          />
          <ImageWithPlaceholder
            className='mx-auto hidden aspect-[3/1] w-full max-w-2xl overflow-hidden rounded-lg object-cover sm:block'
            src={data.image31?.url}
            width={600}
            height={400}
            alt={data.title}
          />
        </figure>

        <h1 className='text-xl uppercase'>{data.title}</h1>

        <CollapsableText
          className='prose-p:text-gray-500 text-sm'
          text={data.description}
          btnProps={{ 'aria-label': `Read More About ${data.title}` }}
          markdown={typeof data.description === 'string'}
        />

        <div data-testid='buy-tickets-cta' className='space-y-4'>
          <QueueView />
          <JoinAuctionButton event={data} />
          <ShareModal
            key='desktop'
            label={t('common:invite_people')}
            share_description={data?.title}
            share_title={data?.title}
            className='w-full py-3'
            buttonProps={{ shape: 'outlined' }}
          />
        </div>

        {data.whatToKnow && <MarkdownRender text={data.whatToKnow} />}
      </section>
    </>
  );
};

export default AuctionDetailPage;
