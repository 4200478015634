import { Link } from 'react-router-dom';
import { WebCard, WebSection } from '@wbk/contentful/api';
import { Button } from '@wbk/ui';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  section: WebSection;
};

const SpotlightCarousel = ({ section }: Props) => {
  const cards = section.contentCollection.items as WebCard[];

  if (!cards.length) return null;

  const card = cards[0];

  return (
    <section
      data-testid={`home_spotlight_carousel_${section.id}`}
      className='relative overflow-hidden py-12'
    >
      {!!section.sectionBackground?.url && (
        <ContentfulImage
          src={section.sectionBackground.url}
          width={section.sectionBackground.width}
          height={section.sectionBackground.height}
          className='absolute left-0 -z-10 object-cover object-center'
          alt=''
        />
      )}

      <div className='container relative space-y-8'>
        {section.title && <h2 className='text-white'>{section.title}</h2>}

        <div
          className='relative flex rounded-md bg-cover bg-no-repeat p-2 md:p-5'
          style={{
            backgroundImage: card.background?.url ? `url(${card.background.url})` : 'none',
            backgroundColor: card.color || '#492b99',
          }}
        >
          <ContentfulImage
            src={card.image.url}
            className='aspect-1 w-1/3 max-w-52 rounded-md object-contain'
            alt={card.title}
          />
          <div className='flex w-full grow flex-col justify-center space-y-0 text-white md:space-y-2 md:px-2 ltr:ml-4 rtl:mr-4'>
            <ContentfulImage
              src={card.icon?.url}
              width={30}
              height={30}
              alt={card.title}
              className='hidden md:block'
            />

            <h3 className='text-lg sm:text-3xl'>{card.title}</h3>
            <p className='text-sm sm:text-xl'>{card.subtitle}</p>

            <div className='pt-2'>
              <Button
                as={Link}
                to={card.href}
                data-testid='home_spotlight_button'
                className='w-full text-sm md:w-auto md:px-8 md:text-base'
              >
                {card.buttonLabel}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpotlightCarousel;
