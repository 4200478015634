import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { ContentfulEvent, Experience } from '@wbk/contentful/api';
import { TicketingEvent } from '@wbk/api';
import { AdSlotLB, AdSlotMLB, useAds } from '@smc/ads';
import CollapsableText from '../text/CollapsableText';
import LocationMap from '../location';
import StreamBanner from '../stream/StreamBanner';
import Sponsors from '../sponsors/Sponsors';
import MarkdownRender from '../text/MarkdownRender';
import ContentfulButton from '../buttons/ContentfulButton';
import ContentfulImage from '../images/ContentfulImage';
import ExperienceDetailInfo from '../experiences/DetailInfo';
import EventDateTime from './DateTime';

type Props = {
  event: ContentfulEvent;
  ticketingEvent?: TicketingEvent;
};

const EventTitleDescription = ({ event }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const { t } = useTranslation(['common', 'event']);
  const { canRenderAds, resolveExploreType } = useAds();
  const { pathname } = useLocation();
  const adUnitType = resolveExploreType(pathname.split('/')?.[2] as unknown as ExploreTypes);
  const zoneSlug =
    event?.zone?.linkedFrom?.zoneHeroSectionCollection?.items?.[0]?.linkedFrom
      ?.webZonePageCollection?.items?.[0]?.slug || event?.zone?.slug;
  const isHyExperience = !!(event as Experience).hyProperties;

  return (
    <>
      {/* Ad Mobile */}
      {canRenderAds && <AdSlotMLB adUnit={`${adUnitType}/mlb`} className='-mt-4 mb-0 pt-0' />}

      {event.schedule && (
        <div className='space-y-1 border-b border-white/20 py-6'>
          <h2 className='text-xl'>{event?.schedule?.openTitle || t('event:date_time')}</h2>
          <EventDateTime schedule={event.schedule} />
        </div>
      )}

      <div className='space-y-2 border-b border-white/20 py-6' data-testid='event-description'>
        <h2 className='text-xl'>{t('event:about')}</h2>
        <CollapsableText
          text={event.description}
          btnProps={{ 'aria-label': `Read More About ${event.title}` }}
          markdown={typeof event.description === 'string'}
        />
      </div>

      {/* Ad Desktop */}
      {canRenderAds && <AdSlotLB adUnit={`${adUnitType}/lb'`} />}

      <ExperienceDetailInfo experience={event} />

      {!!event.socialLinks?.iconLinksCollection?.items?.length && (
        <div className='space-y-2 border-b border-white/20 py-6' data-testid='event-description'>
          {event.socialLinks.title && <h2 className='text-xl'>{event.socialLinks.title}</h2>}
          <ul className='flex flex-wrap items-center gap-4'>
            {event.socialLinks?.iconLinksCollection?.items.map((link) => (
              <li key={link.id} className='rounded-md'>
                <ContentfulButton
                  link={link}
                  rel='noreferrer noopener'
                  className='event_social_media_follow overflow-hidden rounded-md p-0.5'
                  data-category={link.label}
                  rounded
                />
              </li>
            ))}
          </ul>
        </div>
      )}

      {event.buyLiveStream?.button?.link && (
        <div className='space-y-4 border-b border-white/20 py-6'>
          <StreamBanner event={event} />
        </div>
      )}

      {event.specialPromotion && (
        <div className='space-y-4 border-b border-white/20 py-6'>
          <MarkdownRender text={event.specialPromotion} />
        </div>
      )}

      {event.whatToKnow && (
        <div className='space-y-4 border-b border-white/20 py-6'>
          <CollapsableText
            minimum={4}
            text={event.whatToKnow}
            markdown
            btnProps={{ 'aria-label': 'Read More About What To Know' }}
          />
        </div>
      )}

      {event?.zoneEntryIncluded && (
        <div className='space-y-4 border-b border-white/20 py-6'>
          <div className='space-y-3'>
            <h2 className='text-xl'>{t('event:zone_entry_label')}</h2>
            <p>{t('event:zone_entry_description')}</p>
          </div>
        </div>
      )}

      {event.terms && (
        <div className='space-y-4 border-b border-white/20 py-6'>
          <h2 className='text-xl'>{t('common:nav.terms_and_conditions')}</h2>
          <CollapsableText
            text={event.terms}
            markdown
            btnProps={{ 'aria-label': 'Read More About Terms' }}
          />
        </div>
      )}

      {event.zone && !isHyExperience && (
        <div className='block space-y-2 border-b border-white/20 py-6'>
          <h2 className='text-xl'>{t('event:zone')}</h2>
          <Button
            as={Link}
            to={`/${lang}/zones/${zoneSlug}`}
            theme='white'
            shape='text'
            className='bg-body-light w-full rounded-lg p-4'
          >
            <div className='flex w-full items-center gap-4'>
              <div className='flex flex-col items-center gap-2 sm:flex-row'>
                <ContentfulImage
                  className='w-auto max-w-[60px] rounded-lg'
                  src={event.zone.zoneLogo.url}
                  width={60}
                  height={60}
                  alt={event.title}
                  fit='pad'
                />

                {event.zone.sponsorLogo && (
                  <ContentfulImage
                    className='max-h-5 w-auto max-w-[80px] shrink-0 grow'
                    src={event.zone.sponsorLogo.url}
                    width={event.zone.sponsorLogo.width || 200}
                    height={event.zone.sponsorLogo.height || 100}
                    alt={event.zone.sponsorLogo.title || ''}
                    fit='crop'
                  />
                )}
              </div>
              <div className='flex flex-col items-start'>
                <span className='text-xl font-light'>{event.zone?.title}</span>
                {zoneSlug && (
                  <div className='flex items-center gap-2 hover:underline'>
                    <span className='text-sm font-light'>
                      {t('event:explore_more_things_to_do')}
                    </span>
                    <img
                      src='/icons/common/arrow-white.svg'
                      width={18}
                      height={18}
                      className='rtl:rotate-180'
                      alt={'breadcrumb seperator'}
                    />
                  </div>
                )}
              </div>
            </div>
          </Button>
        </div>
      )}

      {event.location && (
        <div className='space-y-2 py-6'>
          <h2 className='text-xl'>{event.location?.seactionHeader || t('event:location')}</h2>
          <div className='w-full'>
            <LocationMap location={event.location} isOnline={event.isStreamingEvent} />
          </div>
        </div>
      )}

      {!!event.sponsorsCollection?.items?.length && (
        <div className='space-y-4 border-t border-white/20 py-6'>
          <Sponsors sponsors={event.sponsorsCollection} />
        </div>
      )}
    </>
  );
};

export default EventTitleDescription;
