import { ComponentProps, ImgHTMLAttributes, useMemo } from 'react';
import { ImageWithPlaceholder } from '@wbk/ui';

type ImageWithPlaceholderProps = ComponentProps<typeof ImageWithPlaceholder>;

type BaseProps = ImgHTMLAttributes<HTMLImageElement> & {
  format?: 'webp' | 'png' | 'jpg' | null;
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';
};

type PropsWithoutPlaceholder = BaseProps & {
  ratio?: never;
  loaderSrc?: never;
  fallbackSrc?: never;
  placeholder?: false;
};

type PropsWithPlaceholder = BaseProps & {
  ratio?: ImageWithPlaceholderProps['ratio'];
  loaderSrc?: ImageWithPlaceholderProps['loaderSrc'];
  fallbackSrc?: ImageWithPlaceholderProps['fallbackSrc'];
  placeholder: true;
};

type Props = PropsWithoutPlaceholder | PropsWithPlaceholder;

const ContentfulImage = ({
  format = 'webp',
  fit,
  width,
  height,
  src,
  placeholder,
  ...props
}: Props) => {
  const query = useMemo(() => {
    if (!src) return '';
    const imgSrcParams = new URL(src).searchParams;
    const searchParams = new URLSearchParams(imgSrcParams);

    if (format) searchParams.set('fm', format);
    if (fit) searchParams.set('fit', fit);
    if (width && typeof width === 'number') searchParams.set('w', width.toString());
    if (height && typeof height === 'number') searchParams.set('h', height.toString());

    return '?' + searchParams.toString();
  }, [src, format, width, fit, height]);

  const newSrc = useMemo(() => {
    if (!src) return '';
    const srcWithoutParams = src.replace(/\?.*/, '');
    return `${srcWithoutParams}${query}`;
  }, [src, query]);

  const Comp = placeholder ? ImageWithPlaceholder : 'img';

  return <Comp src={newSrc} width={width} height={height} {...props} />;
};

export default ContentfulImage;
