import { motion } from 'framer-motion';
import { Package } from '@wbk/contentful/api';
import { useDeviceDetect, useWindowScroll } from '@wbk/hooks';
import { useQueue } from '@wbk/queue';
import BuyTicketButton from './BuyButton';

type Props = {
  event: Package;
  offset?: number;
};

const MobileStickyBuy = ({ event, offset = 500 }: Props) => {
  const { isActive, shouldWaitQueue } = useQueue();
  const { y } = useWindowScroll();
  const { isWebView } = useDeviceDetect();

  if (isActive || shouldWaitQueue) return null;

  return (
    <>
      <style>{`
        @media (max-width: 1023px) {
          footer {margin-bottom:100px;}
        }
      `}</style>
      <motion.div
        initial={{ y: 200 }}
        animate={{ y: y > offset ? 0 : 200 }}
        transition={{ type: 'tween' }}
        className={`bg-body-light fixed bottom-0 left-0 z-20 flex min-h-[70px] w-full items-start border-t border-white/10 px-4 py-2 lg:hidden [&>div]:w-full [&>div]:justify-center ${isWebView ? 'pb-6' : 'safe-bottom'}`}
        data-testid='buy-tickets-cta-mobile'
      >
        <BuyTicketButton
          event={event}
          containerClassName='max-w-lg mx-auto !justify-between p-0 pb-1 gap-1 [&>div]:scale-90 md:[&>div]:scale-100'
          className='line-clamp-1 inline-flex min-h-[40px] w-auto py-2 text-sm'
        />
      </motion.div>
    </>
  );
};

export default MobileStickyBuy;
