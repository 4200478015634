import { ContentfulEvent } from '@wbk/contentful/api';
import { useTranslation } from 'react-i18next';
import ContentfulImage from '@/components/images/ContentfulImage';

type Props = {
  event: ContentfulEvent;
};

const EventItemZone = ({ event }: Props) => {
  const isStreaming = event.isStreamingEvent;
  const { t } = useTranslation(['event']);

  return isStreaming ? (
    <div className='bg-body-light/70 absolute top-0 z-10 m-2 flex h-14 w-14 flex-col items-center justify-center gap-0.5 rounded-lg text-center'>
      <img
        className='mx-auto'
        src='/icons/common/tv-play.svg'
        width={24}
        height={24}
        alt={event.title}
      />
      <p className='text-xs text-white'>{t('common:online')}</p>
    </div>
  ) : (
    event.zone?.zoneLogo?.url && (
      <div className='bg-body-light/80 absolute top-0 z-10 m-2 flex items-center justify-center gap-2 rounded-lg p-1.5'>
        <ContentfulImage
          className='shrink-0 grow'
          src={event.zone?.zoneLogo?.url}
          width={50}
          height={50}
          alt={event.title || ''}
          fit='pad'
        />
        {event.zone.sponsorLogo && (
          <ContentfulImage
            className='max-h-5 w-auto shrink-0 grow'
            src={event.zone.sponsorLogo.url}
            width={event.zone.sponsorLogo.width || 200}
            height={event.zone.sponsorLogo.height || 100}
            alt={event.zone.sponsorLogo.title || ''}
            fit='crop'
          />
        )}
      </div>
    )
  );
};

export default EventItemZone;
