const ExperienceIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          opacity='0.3'
          d='M6.33268 6.33317C5.94102 8.28317 6.35768 10.3165 7.49102 12.0248L12.0327 7.48317C10.7577 6.63317 9.29935 6.18317 7.79935 6.18317C7.29935 6.18317 6.80768 6.23317 6.33268 6.33317ZM10.9327 4.1665C10.1577 4.1665 9.41602 4.29984 8.70768 4.54984C10.2993 4.70817 11.866 5.2915 13.241 6.27484L14.3994 5.1165C13.3577 4.49984 12.1744 4.1665 10.9327 4.1665ZM4.16602 10.9332C4.16602 12.1748 4.49935 13.3582 5.11602 14.3915L6.27435 13.2332C5.29102 11.8582 4.70768 10.2998 4.54935 8.69984C4.29935 9.4165 4.16602 10.1665 4.16602 10.9332Z'
          fill='currentColor'
        />
        <path
          d='M10.9379 12.1333L12.1279 10.9433L17.4963 16.3117L16.3046 17.5017L10.9379 12.1333ZM10.9329 2.5C8.78294 2.5 6.63294 3.31667 4.98294 4.95833L4.97461 4.96667C1.68294 8.25833 1.68294 13.6 4.97461 16.8917L16.8913 4.96667C15.2496 3.325 13.0913 2.5 10.9329 2.5ZM5.11628 14.3917C4.49961 13.3583 4.16628 12.175 4.16628 10.9333C4.16628 10.1583 4.29961 9.41667 4.54961 8.70833C4.70794 10.3 5.29128 11.8667 6.27461 13.2417L5.11628 14.3917ZM7.48294 12.025C6.35794 10.3167 5.93294 8.275 6.33294 6.33333C6.81628 6.23333 7.29961 6.18333 7.79128 6.18333C9.29128 6.18333 10.7496 6.64167 12.0246 7.48333L7.48294 12.025ZM8.70794 4.55C9.41628 4.3 10.1579 4.16667 10.9329 4.16667C12.1746 4.16667 13.3579 4.5 14.3913 5.11667L13.2329 6.275C11.8579 5.29167 10.2996 4.70833 8.70794 4.55Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default ExperienceIcon;
