import { PageData } from '@wbk/contentful/api';
import EventFeaturedSection from '../events/FeaturedSection';
import ReferralBanner from '../referral/ReferralBanner';
import ContentfulWebSection from './WebSection';

type Props = {
  section: PageData['sectionsCollection']['items'][0];
};

const PageSection = ({ section }: Props) => {
  switch (section.__typename) {
    case 'FeaturedSection':
      return <EventFeaturedSection section={section} />;
    case 'WebSection':
      return <ContentfulWebSection section={section} />;
    case 'AppReferralCard':
      return <ReferralBanner section={section} />;
    default:
      return null;
  }
};

export default PageSection;
