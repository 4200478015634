import { useTranslation } from 'react-i18next';
import Seo from '@/components/headers/seo';
import AdvertiseForm from '@/components/advertise/AdvertiseForm';

const AdvertisePage = () => {
  const { t } = useTranslation('advertise');
  return (
    <>
      <Seo title={t('advertise:title')} />
      <div className='relative -mt-20 flex h-[250px] w-full flex-col justify-between pb-8 before:absolute before:inset-0 before:z-10 before:bg-gradient-to-b before:from-black before:from-5% before:via-transparent before:to-black after:absolute after:inset-0 after:-z-10 after:bg-[url(/images/wheel.png)] after:bg-cover after:bg-[50%_35%]'></div>

      <AdvertiseForm />
    </>
  );
};

export default AdvertisePage;
