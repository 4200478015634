const ShowIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          opacity='0.3'
          d='M12.084 9.00801C12.1257 8.16634 13.034 7.49967 14.159 7.49967C15.309 7.49967 16.2423 8.19967 16.2423 9.06634H12.084V10.083C12.659 10.5413 13.3757 10.833 14.1673 10.833C16.009 10.833 17.5007 9.34134 17.5007 7.49967V3.33301H10.834V6.24967H12.084V9.00801ZM15.834 4.58301C16.2923 4.58301 16.6673 4.95801 16.6673 5.41634C16.6673 5.87467 16.2923 6.24967 15.834 6.24967C15.3757 6.24967 15.0007 5.87467 15.0007 5.41634C15.0007 4.95801 15.3757 4.58301 15.834 4.58301ZM11.6673 5.41634C11.6673 4.95801 12.0423 4.58301 12.5007 4.58301C12.959 4.58301 13.334 4.95801 13.334 5.41634C13.334 5.87467 12.959 6.24967 12.5007 6.24967C12.0423 6.24967 11.6673 5.87467 11.6673 5.41634Z'
          fill='currentColor'
        />
        <path
          d='M9.16797 1.6665V6.24984H10.8346V3.33317H17.5013V7.49984C17.5013 9.3415 16.0096 10.8332 14.168 10.8332C13.3763 10.8332 12.6596 10.5415 12.0846 10.0832V12.0415C12.718 12.3332 13.4263 12.4998 14.168 12.4998C16.9263 12.4998 19.168 10.2582 19.168 7.49984V1.6665H9.16797Z'
          fill='currentColor'
        />
        <path
          d='M15.8343 6.24967C16.2945 6.24967 16.6676 5.87658 16.6676 5.41634C16.6676 4.9561 16.2945 4.58301 15.8343 4.58301C15.3741 4.58301 15.001 4.9561 15.001 5.41634C15.001 5.87658 15.3741 6.24967 15.8343 6.24967Z'
          fill='currentColor'
        />
        <path
          d='M12.5013 6.24967C12.9615 6.24967 13.3346 5.87658 13.3346 5.41634C13.3346 4.9561 12.9615 4.58301 12.5013 4.58301C12.0411 4.58301 11.668 4.9561 11.668 5.41634C11.668 5.87658 12.0411 6.24967 12.5013 6.24967Z'
          fill='currentColor'
        />
        <path
          d='M14.159 7.49951C13.034 7.49951 12.1257 8.16618 12.084 9.00785V9.06618H16.2423C16.2423 8.19951 15.309 7.49951 14.159 7.49951Z'
          fill='currentColor'
        />
        <path
          d='M0.833984 13.3328C0.833984 16.0912 3.07565 18.3328 5.83398 18.3328C8.59232 18.3328 10.834 16.0912 10.834 13.3328V7.49951H0.833984V13.3328ZM2.50065 9.16618H9.16732V13.3328C9.16732 15.1745 7.67565 16.6662 5.83398 16.6662C3.99232 16.6662 2.50065 15.1745 2.50065 13.3328V9.16618Z'
          fill='currentColor'
        />
        <path
          opacity='0.3'
          d='M5.83431 16.6665C7.67598 16.6665 9.16764 15.1748 9.16764 13.3332V9.1665H2.50098V13.3332C2.50098 15.1748 3.99264 16.6665 5.83431 16.6665ZM5.83431 14.8998C4.68431 14.8998 3.75098 14.1998 3.75098 13.3332H7.91764C7.91764 14.1998 6.98431 14.8998 5.83431 14.8998ZM7.50098 10.4165C7.95931 10.4165 8.33431 10.7915 8.33431 11.2498C8.33431 11.7082 7.95931 12.0832 7.50098 12.0832C7.04264 12.0832 6.66764 11.7082 6.66764 11.2498C6.66764 10.7915 7.04264 10.4165 7.50098 10.4165ZM4.16764 10.4165C4.62598 10.4165 5.00098 10.7915 5.00098 11.2498C5.00098 11.7082 4.62598 12.0832 4.16764 12.0832C3.70931 12.0832 3.33431 11.7082 3.33431 11.2498C3.33431 10.7915 3.70931 10.4165 4.16764 10.4165Z'
          fill='currentColor'
        />
        <path
          d='M4.16732 12.0832C4.62756 12.0832 5.00065 11.7101 5.00065 11.2498C5.00065 10.7896 4.62756 10.4165 4.16732 10.4165C3.70708 10.4165 3.33398 10.7896 3.33398 11.2498C3.33398 11.7101 3.70708 12.0832 4.16732 12.0832Z'
          fill='currentColor'
        />
        <path
          d='M7.5013 12.0832C7.96154 12.0832 8.33464 11.7101 8.33464 11.2498C8.33464 10.7896 7.96154 10.4165 7.5013 10.4165C7.04106 10.4165 6.66797 10.7896 6.66797 11.2498C6.66797 11.7101 7.04106 12.0832 7.5013 12.0832Z'
          fill='currentColor'
        />
        <path
          d='M5.83431 14.8997C6.98431 14.8997 7.91764 14.1997 7.91764 13.333H3.75098C3.75098 14.1997 4.68431 14.8997 5.83431 14.8997Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default ShowIcon;
