const CalendarIcon = ({ className = '' }) => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.8333 3.8748H15V2.20813H13.3333V3.8748H6.66667V2.20813H5V3.8748H4.16667C3.24167 3.8748 2.50833 4.6248 2.50833 5.54146L2.5 17.2081C2.5 18.1248 3.24167 18.8748 4.16667 18.8748H15.8333C16.75 18.8748 17.5 18.1248 17.5 17.2081V5.54146C17.5 4.6248 16.75 3.8748 15.8333 3.8748ZM15.8333 17.2081H4.16667V8.04146H15.8333V17.2081Z'
        fill='currentColor'
      />
      <path
        opacity='0.6'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.83301 9.70813H9.99967V13.8748H5.83301V9.70813Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default CalendarIcon;
