import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const AnimatedCross = ({ className = '' }) => {
  return (
    <motion.svg
      viewBox='0 0 510 510'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={twMerge('text-red-500', className)}
    >
      <motion.path
        initial={{ scale: 0.2, opacity: 0 }}
        animate={{ scale: 1, opacity: 0.2 }}
        transition={{ duration: 0.5, delay: 0.2, type: 'spring' }}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M510 255C510 395.833 395.833 510 255 510C114.167 510 0 395.833 0 255C0 114.167 114.167 0 255 0C395.833 0 510 114.167 510 255Z'
        fill='currentColor'
      />
      <motion.path
        initial={{ pathLength: 0, strokeLinecap: 'inherit', strokeLinejoin: 'inherit' }}
        animate={{ pathLength: 1, strokeLinecap: 'round', strokeLinejoin: 'round' }}
        transition={{ duration: 0.8, delay: 0.3, type: 'spring' }}
        d='M378 132L132 378'
        stroke='currentColor'
        strokeWidth={50}
      />
      <motion.path
        initial={{ pathLength: 0, strokeLinecap: 'inherit', strokeLinejoin: 'inherit' }}
        animate={{ pathLength: 1, strokeLinecap: 'round', strokeLinejoin: 'round' }}
        transition={{ duration: 0.8, delay: 0.4, type: 'spring' }}
        d='M132 132L378 378'
        stroke='currentColor'
        strokeWidth={50}
      />
    </motion.svg>
  );
};

export default AnimatedCross;
