import { Fragment, Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useWebsiteConfig } from '@wbk/contentful/api';
import { globalJsonLd, searchJsonLd } from '@/components/headers/seo/json-ld';
import HideOnTawakkalna from '@/components/twk/HideOnTawakkalna';
import GlobalHead from '../headers/Index';
import Seo from '../headers/seo';
import FullpageAnimatedLogo from '../ui/spinners/FullpageAnimatedLogo';
import Header from './header';
import Footer from './footer';

type Props = {
  type?: LayoutType;
};

const Layout = ({ type = 'main' }: Props) => {
  const { lang } = useParams();
  const { data, isLoading } = useWebsiteConfig({ lang, slug: 'webook-config' });

  return (
    <Fragment>
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            ...globalJsonLd,
            image: data?.seo?.image?.url || globalJsonLd.image,
          }),
        }}
      />
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(searchJsonLd),
        }}
      />

      <GlobalHead />
      <Seo
        title={data?.seo.title}
        description={data?.seo?.description}
        keywords={data?.seo?.keywords}
        image={data?.seo?.image?.url}
      />

      <HideOnTawakkalna>
        <Suspense
          fallback={
            <header className='border-body-lighter relative z-20 h-[48px] w-full border-b md:h-[70px] md:bg-black/50'></header>
          }
        >
          {type === 'booking' ? null : isLoading ? (
            <header className='border-body-lighter relative z-20 h-[48px] w-full border-b md:h-[70px] md:bg-black/50'></header>
          ) : (
            data && <Header config={data} type={type} />
          )}
        </Suspense>
      </HideOnTawakkalna>

      <main id='main'>
        <Suspense fallback={<FullpageAnimatedLogo withLogo={false} />}>
          <Outlet />
        </Suspense>
      </main>

      <HideOnTawakkalna>
        <Suspense>{data && <Footer config={data} type={type} />}</Suspense>
      </HideOnTawakkalna>
    </Fragment>
  );
};

export default Layout;
