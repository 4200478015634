import { Link, useParams } from 'react-router-dom';
import { WebSection, WebZonePages } from '@wbk/contentful/api';
import { parseSectionUtm } from '@wbk/utils';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  section: WebSection;
};

const ZonePagesGrid = ({ section }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const zonePages = section.contentCollection.items as WebZonePages[];

  return (
    <section
      data-testid={`home_zone_pages_grid_${section.id}`}
      className='container space-y-6 py-12'
    >
      <h2>{section.title}</h2>
      <ul className='grid grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-6 xl:grid-cols-12'>
        {zonePages.map((page, i) => {
          const zone = page?.sectionsCollection?.items?.[0]?.zone;
          const utm = parseSectionUtm({ utm: section.utm, idx: i, id: page.id });
          if (!zone) return null;
          return (
            <li key={i} className='group overflow-hidden rounded-md'>
              <Link
                className='border-primary group relative flex h-36 outline-none focus:border-2'
                to={`/${lang}/zones/${zone.slug}${utm}`}
                data-testid={`home_zone_pages_grid_item_${zone.id}`}
              >
                <ContentfulImage
                  alt={zone.title || ''}
                  src={zone.featuredBanner.url}
                  sizes='300px'
                  width={300}
                  height={300}
                  fit='crop'
                  className='absolute -z-10 h-full w-full object-cover transition duration-500 group-hover:scale-[1.02]'
                  placeholder
                />
                <div className='flex h-full w-full items-center justify-center bg-black/70 p-4 transition group-hover:bg-black/60'>
                  {zone?.zoneLogo && (
                    <ContentfulImage
                      className='w-20 shrink-0'
                      src={zone?.zoneLogo?.url}
                      width={80}
                      height={80}
                      alt={zone.title || ''}
                      fit='pad'
                    />
                  )}
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default ZonePagesGrid;
