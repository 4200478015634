import { createContext } from 'react';
// import { defaultLang } from '@/i18n/config';

type Props = {
  // language: Language;
  setLanguage: (language: Language) => void;
};
const LanguageContext = createContext<Props>({
  // language: defaultLang,
  setLanguage: () => {},
});

export default LanguageContext;
