import Reveal from '../animation/Reveal';

const Title = ({ text }: { text: string }) => {
  return (
    <Reveal as='div' once={false} threshold={0.5} className='flex items-center gap-2'>
      <img
        className='h-[30px] w-[46px] object-contain md:block md:h-[40px] md:w-[56px]'
        src={'/saudi-cup/title.svg'}
        alt={`${text}}-svg`}
      />
      <h2 className='text-2xl uppercase md:text-3xl'>{text}</h2>
    </Reveal>
  );
};

export default Title;
