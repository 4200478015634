import EmptySearchResult from '@/components/search/EmptyResult';
import SearchInitialScreen from '@/components/search/InitialScreen';
import SearchEventItem from '@/components/search/SearchEventItem';
import SearchZoneItem from '@/components/search/SearchZoneItem';
import SearchSeeAllButton from '@/components/search/SeeAllButton';
import useSearch from '@/context/search/useSearch';

const SearchContent = () => {
  const {
    allEventsMerged,
    zones,
    search,
    togglePopover,
    zoneLoading,
    isLoading,
    hasResult,
    hasZoneResult,
    fetchedDone,
  } = useSearch();

  return (
    <>
      {isLoading || zoneLoading ? (
        <div className='space-y-2'>
          <div className='bg-body-lighter h-6 w-1/3 rounded-md' />
          <div className='bg-body-lighter h-16 rounded-md' />
          <div className='bg-body-lighter h-16 rounded-md' />
        </div>
      ) : hasResult ? (
        <div className='space-y-4'>
          {hasZoneResult && hasResult && !!zones?.length && (
            <>
              <ul className='space-y-2'>
                {zones.map((zone) => (
                  <li key={zone.slug} className='flex items-center gap-2'>
                    <SearchZoneItem zonePage={zone} onClick={() => togglePopover(false)} />
                  </li>
                ))}
              </ul>
              <hr className='border-body-lighter !my-4' />
            </>
          )}

          <ul className='space-y-2'>
            {allEventsMerged.map((event) => (
              <li key={event.slug} className='flex items-center gap-2'>
                <SearchEventItem event={event} onClick={() => togglePopover(false)} />
              </li>
            ))}
          </ul>

          <SearchSeeAllButton />
        </div>
      ) : search && fetchedDone ? (
        <EmptySearchResult />
      ) : (
        !search && <SearchInitialScreen />
      )}
    </>
  );
};

export default SearchContent;
