import { useCallback, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useParams } from 'react-router-dom';
import { Button, Carousel } from '@wbk/ui';
import { ContentfulEvent, WebSection } from '@wbk/contentful/api';
import { parseSectionUtm } from '@wbk/utils';
import EventItem from './EventItem';
import type { UseEmblaCarouselType } from 'embla-carousel-react';

type Props = {
  section: WebSection;
};

const TWEEN_FACTOR = 1;

const numberWithinRange = (number: number, min: number, max: number) =>
  Math.min(Math.max(number, min), max);

const EventsFocusedCarousel = ({ section }: Props) => {
  const events = section.contentCollection.items as ContentfulEvent[];
  const { lang } = useParams<{ lang: Language }>();
  const [carousel, setCarousel] = useState<UseEmblaCarouselType[1]>();
  const slidesRef = useRef<HTMLDivElement[]>([]);

  const onScroll = useCallback(() => {
    if (!carousel) return;

    const engine = carousel.internalEngine();
    const scrollProgress = carousel.scrollProgress();
    const dir = lang?.startsWith('ar') ? 1 : -1;
    carousel.scrollSnapList().map((scrollSnap, index) => {
      let diffToTarget = scrollSnap - scrollProgress;
      let sign = Math.sign(diffToTarget);

      engine.slideLooper.loopPoints.forEach((loopItem) => {
        const target = loopItem.target();
        if (index === loopItem.index && target !== 0) {
          sign = Math.sign(target);
          if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
          if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
        }
      });

      const tweenValue = 1 - Math.abs(diffToTarget * TWEEN_FACTOR);
      const scale = numberWithinRange(tweenValue, 0, 1);
      const slide = slidesRef.current[index];
      if (!slide) return;
      slide.style.transform = `translateX(${
        100 * (1 - scale) * diffToTarget * 3.5 * dir
      }%) scale(${scale})`;
    });
  }, [carousel, lang]);

  useEffect(() => {
    if (!carousel) return;

    onScroll();
    carousel.on('scroll', () => {
      flushSync(() => onScroll());
    });
    carousel.on('reInit', onScroll);
  }, [carousel, onScroll]);

  return (
    <section
      data-testid={`home_events_focused_carousel_${section.id}`}
      className='container relative py-12'
    >
      <Carousel
        locale={lang}
        options={{ align: 'center', loop: true }}
        className='py-0.5'
        onInit={(carousel) => {
          setCarousel(carousel);
        }}
        renderHeader={({ scrollPrev, scrollNext }) => (
          <div className='flex items-center justify-between space-y-4 md:flex-row md:space-y-0'>
            <div>
              {section.title && <h2>{section.title}</h2>}
              {section.subtitle && (
                <p className='mt-1 text-[15px] text-gray-400'>{section.subtitle}</p>
              )}
            </div>

            <div className='flex gap-2'>
              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollPrev}
                aria-label='Previous'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='ltr:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>

              <Button
                shape='outlined'
                theme='white'
                className='flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-white/30 p-0'
                onClick={scrollNext}
                aria-label='Next'
              >
                <img
                  src='/icons/common/arrow.svg'
                  className='rtl:rotate-180'
                  alt=''
                  width={24}
                  height={24}
                />
              </Button>
            </div>
          </div>
        )}
      >
        {events.map((event, i) => {
          return (
            <div
              className='xs:basis-1/2 flex shrink-0 basis-[85%] px-2 py-4 md:basis-[28%] lg:basis-[22%]'
              key={event.id}
            >
              <div className='flex h-full w-full' ref={(el) => el && (slidesRef.current[i] = el)}>
                <EventItem
                  event={event}
                  cardType={section.itemRenderType}
                  utm={parseSectionUtm({ utm: section.utm, idx: i, id: event.id })}
                />
              </div>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
};

export default EventsFocusedCarousel;
