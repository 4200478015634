import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Button, ImageWithPlaceholder } from '@wbk/ui';
import { Zone } from '@wbk/contentful/api';
import { QueueView, useQueue } from '@wbk/queue';
import EventDateTime from '@/components/events/DateTime';
import ContentfulButton from '@/components/buttons/ContentfulButton';
import CollapsableText from '../text/CollapsableText';
import ShareModal from '../common/ShareModal';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  zone: Zone;
};

const ZoneSidebar = ({ zone }: Props) => {
  const [showDescription, setShowDescription] = useState(false);
  const { lang } = useParams<{ lang: Language }>();
  const { t } = useTranslation(['common', 'event']);
  const { isActive } = useQueue();

  return (
    <aside className='relative w-full pb-8 lg:sticky lg:top-4 lg:max-w-sm'>
      <ContentfulImage
        src={zone.featuredBanner?.url}
        placeholder
        className='h-[200px] w-full overflow-hidden object-cover lg:h-[300px] lg:rounded-lg'
      />
      <div className='aspect-1 border-body bg-body-light mx-auto -mb-10 flex h-28 w-28 -translate-y-1/2 items-center justify-center overflow-hidden rounded-full border-4 p-2'>
        <ContentfulImage
          src={zone.zoneLogo?.url}
          className='h-20 w-20 p-1'
          alt={zone.title}
          fit='pad'
          width={200}
          height={200}
        />
      </div>
      <div className='-mb-10 flex -translate-y-14 items-center justify-end gap-1.5 px-4 lg:px-0'>
        {zone.location && (
          <Button
            as='a'
            theme='black'
            shape='text'
            target='_blank'
            rel='noreferrer'
            className='bg-body-lighter h-8 w-8 justify-center p-1.5'
            href={`https://www.google.com/maps/search/?api=1&query=${zone.location.location.lat},${zone.location.location.lon}`}
          >
            <img
              src='/icons/common/location-marker.svg'
              width={14}
              height={14}
              className='shrink-0'
              alt='direction'
            />
          </Button>
        )}
        <ShareModal
          share_title={zone.title}
          share_description=''
          render={({ share }) => (
            <Button
              theme='black'
              shape='text'
              className='bg-body-lighter h-8 w-8 justify-center p-1.5'
              onClick={share}
            >
              <img
                src='/icons/common/share.svg'
                width={18}
                height={18}
                className='shrink-0'
                alt='direction'
              />
            </Button>
          )}
        />
      </div>
      <h2 className='text-center text-2xl'>{zone.title}</h2>

      {zone.sponsorLogo && (
        <ContentfulImage
          className='mx-auto my-4 max-h-8 w-auto max-w-[120px] shrink-0 grow'
          src={zone.sponsorLogo.url}
          width={zone.sponsorLogo.width || 200}
          height={zone.sponsorLogo.height || 100}
          alt={zone.sponsorLogo.title || ''}
          fit='crop'
        />
      )}

      {zone.button && (
        <div className='mx-auto max-w-xl space-y-6 px-4 py-4 lg:px-0'>
          {isActive ? (
            <QueueView className='p-0' />
          ) : (
            <Button
              queueProtected
              as={Link}
              to={`/${lang}/zones/${zone.button.link}/book`}
              className='buy-entry-ticket mx-auto flex w-full gap-2 py-3 font-bold capitalize leading-none'
              data-zone-name={zone.title}
              data-category='zone'
            >
              <img
                className='rtl:rotate-180'
                src='/icons/common/single-ticket.svg'
                width={18}
                height={18}
                alt=''
              />
              <span> {zone.button.label}</span>
            </Button>
          )}
        </div>
      )}

      <div className='mx-auto max-w-xl divide-y divide-white/20 px-4 lg:px-0'>
        {zone.banner && (
          <ImageWithPlaceholder
            src={zone.banner.url}
            alt={zone.banner.title}
            className='max h-full w-full py-4 lg:hidden'
          />
        )}
        {zone.description && (
          <div>
            <div className='hidden py-2 lg:block'>
              <CollapsableText minimum={2} text={zone.description} markdown />
            </div>
            <div className='lg:hidden'>
              <button
                className='flex w-full items-center justify-between gap-2 py-4'
                onClick={() => setShowDescription((prev) => !prev)}
              >
                <div className='flex items-center gap-2'>
                  <span className='bg-body-lighter h-6 w-6 rounded-full p-0.5 text-sm'>!</span>
                  <h2 className='text-lg'>{t('common:about_this_zone')}</h2>
                </div>
                <img
                  className={`transition ${showDescription ? '-rotate-90' : 'rotate-90'}`}
                  src='/icons/common/arrow.svg'
                  width={20}
                  height={20}
                  alt='chevron'
                />
              </button>
              <motion.div
                className='overflow-hidden'
                initial={{ height: 0 }}
                animate={{ height: showDescription ? 'auto' : 0 }}
                transition={{ duration: 0.3 }}
              >
                <div
                  className='text-text max-w-full overflow-hidden whitespace-pre-wrap pb-4 ltr:pl-8 rtl:pr-8'
                  dangerouslySetInnerHTML={{ __html: zone.description }}
                />
              </motion.div>
            </div>
          </div>
        )}

        {zone.booklet && (
          <div className='space-y-1 py-4'>
            <div className='flex items-center gap-2'>
              <div className='bg-body-lighter flex h-6 w-6 items-center justify-center rounded-full p-0.5 text-sm lg:hidden'>
                <img
                  src='/icons/common/attachment.svg'
                  width={16}
                  height={18}
                  alt='attachment icon'
                />
              </div>
              <h2 className='text-lg'>{zone.booklet.title || t('event:booklet')}</h2>
            </div>
            <div className='lg:!px-0 ltr:pl-8 rtl:pr-8'>
              {zone.booklet?.link ? (
                <a
                  className='text-primary py-1'
                  target='_blank'
                  rel='noreferrer'
                  href={zone.booklet.link}
                >
                  {zone.booklet.text}
                </a>
              ) : (
                <p> {zone.booklet.text}</p>
              )}
            </div>
          </div>
        )}

        {zone.schedule && (
          <div className='space-y-1 py-4'>
            <div className='flex items-center gap-2'>
              <div className='bg-body-lighter flex h-6 w-6 items-center justify-center rounded-full p-0.5 text-sm lg:hidden'>
                <img src='/icons/common/calendar.svg' width={16} height={16} alt='calendar icon' />
              </div>
              <h2 className='text-lg'>{zone?.schedule?.openTitle || t('event:date_time')}</h2>
            </div>

            <EventDateTime
              schedule={zone.schedule}
              showIcon={false}
              className='lg:!px-0 ltr:pl-8 rtl:pr-8'
            />
          </div>
        )}

        {zone.location?.location && zone.location.title && (
          <div className='space-y-1 py-4'>
            <div className='flex items-center gap-2'>
              <div className='bg-body-lighter flex h-6 w-6 items-center justify-center rounded-full p-0.5 text-sm lg:hidden'>
                <img
                  src='/icons/common/location-marker.svg'
                  width={12}
                  height={12}
                  alt='location icon'
                />
              </div>
              <h2 className='text-lg'>{zone?.location?.seactionHeader || t('event:location')}</h2>
            </div>
            <div className='lg:!px-0 ltr:pl-8 rtl:pr-8'>
              <a
                className='text-primary py-1'
                target='_blank'
                rel='noreferrer'
                href={`https://www.google.com/maps/search/?api=1&query=${zone.location.location.lat},${zone.location.location.lon}`}
              >
                {zone.location.address || zone.location.title}
              </a>
            </div>
          </div>
        )}

        {zone.terms && (
          <div className='hidden py-4 lg:block'>
            <h2 className='text-lg'>{t('common:privacy.tnc')}</h2>
            <CollapsableText minimum={2} text={zone.terms} markdown />
          </div>
        )}

        {zone.socialLinks?.iconLinksCollection?.items?.length && (
          <div className='hidden space-y-1 py-4 lg:block'>
            <h2 className='text-lg'>{t('common:more_about_this_zone')}</h2>
            <ul className='flex items-center gap-4'>
              {zone?.socialLinks?.iconLinksCollection?.items?.map((link) => {
                if (!link) return null;
                return (
                  <li key={link.id} className='shrink-0 rounded-md'>
                    <ContentfulButton
                      link={link}
                      rel='noreferrer noopener'
                      className='social_media_follow overflow-hidden rounded-md p-0'
                      data-location='zone-detail'
                      data-zone={zone.title}
                      data-category={link.label}
                      rounded
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </aside>
  );
};

export default ZoneSidebar;
