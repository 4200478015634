import { useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';
import { useWebsiteLocale } from '@wbk/hooks';
import { createCookie } from '@wbk/utils';
import { languages } from '@/i18n/config';

const LanguageSwitch = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation('common');
  const { pathname } = useLocation();
  const { lang } = useWebsiteLocale();
  const [search] = useSearchParams();

  const handleDir = async (goto: string) => {
    try {
      const currentLang = lang || i18n.language;
      const pathWithoutLang = pathname.replace(`/${currentLang}`, '');

      setLoading(true);
      await i18n.changeLanguage(goto);
      document.documentElement.lang = goto;
      document.documentElement.dir = goto.startsWith('ar') ? 'rtl' : 'ltr';

      createCookie({
        name: 'lang',
        value: goto,
        expiration: 30,
      });

      const searchParams = search.toString();
      const cleanSearch = searchParams ? `?${searchParams}` : '';

      const dest = `/${goto}${pathWithoutLang}${cleanSearch}`;
      navigate(dest);
    } catch (error) {
      // under investigation if really helpful or not
      // window.location.reload();
    } finally {
      setLoading(false);
    }
  };

  const otherLang = useMemo(() => {
    const shortLang = lang?.split('-')?.[0] || 'en';
    return languages.filter((l) => l !== shortLang);
  }, [lang]);

  const shortLang = otherLang?.[0]?.split('-')?.[0] || 'en';

  return (
    <Button
      shape='text'
      theme='white'
      className='gap-1 py-1 uppercase underline-offset-2 hover:underline'
      onClick={() => handleDir(otherLang[0])}
      data-testid='lang-switcher'
      data-location='header'
      data-target-language={otherLang[0]}
      role='menuitem'
      loading={loading}
    >
      <span className='ltr:font-vazirmatn rtl:font-gellix hidden md:block'>
        {t(`common:${otherLang[0]}`)}
      </span>
      <span className='ltr:font-vazirmatn rtl:font-gellix md:hidden'>
        {t(`common:${shortLang}`)}
      </span>
    </Button>
  );
};

export default LanguageSwitch;
