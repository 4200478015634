import Sponsors from './Sponsors';
import type { SponserList, WebSection } from '@wbk/contentful/api';

type Props = {
  section: WebSection;
};

const SponsorList = ({ section }: Props) => {
  const sponsorList = section.contentCollection as unknown as SponserList;
  return (
    <section data-testid={`sponsors_list_${section.id}`} className='container space-y-8 py-12'>
      {section.title && <h2>{section.title}</h2>}
      <Sponsors sponsors={sponsorList} className='max-w-full' />
    </section>
  );
};
export default SponsorList;
