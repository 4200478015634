import { ContentfulEvent } from '@wbk/contentful/api';
import ContentfulImage from '../images/ContentfulImage';
import GalleryWithThumbs from './GalleryWithThumbs';

type Props = {
  event: ContentfulEvent;
};

const StickyEventInfo = ({ event }: Props) => {
  return (
    <div className='hidden space-y-8 xl:block'>
      {event.carousalCollection.items?.length > 0 ? (
        <GalleryWithThumbs poster={event.image11} carousel={event.carousalCollection.items} />
      ) : (
        <figure className='rounded-lg'>
          <ContentfulImage
            className='aspect-1 overflow-hidden rounded-lg object-cover'
            src={event?.image11?.url}
            width={600}
            height={600}
            alt={event.title}
            placeholder
          />
        </figure>
      )}
    </div>
  );
};

export default StickyEventInfo;
