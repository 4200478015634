import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { WebZonePages } from '@wbk/contentful/api';
import EventDateTime from '@/components/events/DateTime';
import LocationIcon from '@/components/svg/Location';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  zonePage: WebZonePages;
  onClick?: () => void;
  className?: string;
};

const SearchZoneItem = ({ zonePage, onClick, className = '' }: Props) => {
  const zone = zonePage?.sectionsCollection?.items?.[0]?.zone;

  const { lang } = useParams<{ lang: Language }>();
  const { t } = useTranslation('common');

  if (!zone) return null;

  return (
    <Button
      data-testid={`search_zone_button_${zone.slug}`}
      queueProtected
      as={Link}
      to={`/${lang}/zones/${zonePage.slug}`}
      onClick={onClick}
      className='flex w-full items-center justify-start gap-2 p-0.5 ltr:text-left rtl:text-right'
      theme='white'
      shape='text'
      phill={false}
      rounded
    >
      <div
        style={{ backgroundImage: `url(${zone.featuredBanner?.url})` }}
        className={`before:bg-body/80 relative h-20 w-20 rounded-md bg-cover before:absolute before:inset-0 before:z-10 before:rounded-md ${className}`}
      >
        <div className='relative z-10 flex h-full flex-col items-center justify-center gap-1'>
          <ContentfulImage
            className='mx-auto shrink-0'
            src={zone.zoneLogo?.url}
            width={60}
            height={60}
            alt={zone.title}
            fit='pad'
          />
          {/* {zone.sponsorLogo && (
            <img
              className='mx-auto max-h-4 w-auto'
              width={zone?.sponsorLogo?.width}
              height={zone?.sponsorLogo?.height}
              src={`${zone.sponsorLogo?.url}?w=200&h=100&fit=crop`}
              alt={zone.title}
            />
          )} */}
        </div>
      </div>

      <div className='space-y-px'>
        <p className='flex items-center gap-1 text-xs text-gray-400'>
          <LocationIcon className='h-4 w-4' />
          <span>{t('common:zone')}</span>
        </p>
        <p className='text-sm font-semibold'>{zone.title}</p>
        {zone.schedule && (
          <EventDateTime schedule={zone.schedule} className='text-xs' showIcon={false} />
        )}
      </div>
    </Button>
  );
};

export default SearchZoneItem;
