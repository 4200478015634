import { twMerge } from 'tailwind-merge';
import { WebsiteConfig } from '@wbk/contentful/api';
import { ImageWithFallback } from '@wbk/ui';

type Props = {
  sponsors?: WebsiteConfig['footer']['sponsorsCollection'];
  className?: string;
};

const Sponsors = ({ sponsors, className = '' }: Props) => {
  if (!sponsors) return null;

  return (
    <div className='space-y-6 text-center'>
      {sponsors?.items?.map((sponsorList) => {
        if (!sponsorList) return null;
        return (
          <div key={sponsorList.id} className={twMerge('mx-auto max-w-4xl space-y-2', className)}>
            {sponsorList.title && (
              <p className='text-xl font-semibold' style={{ color: sponsorList.titleColor || '' }}>
                {sponsorList.title}
              </p>
            )}
            <div className='flex flex-wrap items-center justify-center gap-6'>
              {sponsorList.sponsorsCollection?.items?.map((sponsor) => {
                if (!sponsor) return null;
                const Image = (
                  <ImageWithFallback
                    key={sponsor.id}
                    src={sponsor.logo.url ? `${sponsor.logo.url}` : ''}
                    alt={sponsor.title}
                    width={sponsor.logo.width}
                    height={sponsor.logo.height}
                    className='h-full max-h-[80px]'
                  />
                );

                if (sponsor.link) {
                  return (
                    <a
                      href={sponsor.link}
                      target='_blank'
                      rel='noreferrer'
                      className='transition hover:scale-105'
                      key={sponsor.id}
                    >
                      {Image}
                    </a>
                  );
                }
                return Image;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Sponsors;
