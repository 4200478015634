import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, Modal } from '@wbk/ui';
import { useWindowScroll } from '@wbk/hooks';
import MobileSearchContent from '@/components/search/MobileSearchContent';
import useSearch from '@/context/search/useSearch';

const MobileSearch = () => {
  const { y } = useWindowScroll();
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { lang } = useParams<{ lang: Language }>();
  const isHome = pathname?.replace(lang || '', '') === '/';
  const show = !isHome || y > 150;
  const { togglePopover } = useSearch();

  return (
    <AnimatePresence initial={false}>
      {show && (
        <Modal
          fullscreen
          ignoreHistory
          unstyled
          className='data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:slide-out-to-top-[45%] data-[state=open]:slide-in-from-top-[45%] fixed top-0 z-50 duration-1000'
          render={({ open }) => {
            const handleClick = () => {
              open();
              togglePopover(true);
            };
            return (
              <motion.div
                key={pathname}
                className='w-full overflow-hidden md:max-w-[180px]'
                initial={{ width: 0, padding: 0 }}
                animate={{ width: '100%', padding: '0 1px' }}
                exit={{ width: 0, padding: 0 }}
                transition={{ duration: 0.4 }}
              >
                <Button
                  shape='text'
                  theme='white'
                  className='bg-body-light my-px w-full max-w-xs grow justify-start truncate px-2 py-2 sm:px-1'
                  onClick={handleClick}
                  data-testid='header_mobile_search_button'
                  data-location='header'
                  data-title='search'
                >
                  <img
                    className='h-3 w-3 opacity-50 sm:h-4 sm:w-4'
                    src='/icons/common/search.svg'
                    alt=''
                  />
                  <p className='shrink-0 text-[10px] text-gray-400 sm:text-xs'>
                    {t('common:search_placeholder')}
                  </p>
                </Button>
              </motion.div>
            );
          }}
          body={({ close }) => {
            const handleClose = () => {
              close();
              togglePopover(false);
            };
            return <MobileSearchContent close={handleClose} />;
          }}
        />
      )}
    </AnimatePresence>
  );
};

export default MobileSearch;
