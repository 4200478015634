import { Toaster } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsProvider } from '@wbk/analytics';
import { AuthProvider } from '@wbk/auth';
import { Trans, useTranslation } from 'react-i18next';
import { useWebsiteLocale } from '@wbk/hooks';
import { HyExperienceProvider } from '@hy/experiences';
import { WbkTicketingProvider } from '@wbk/ticketing';
import { WbkPackagesProvider } from '@wbk/packages';
import { WbkQueueProvider } from '@wbk/queue';
import { SmcAdsProvider } from '@smc/ads';
import { UiProvider } from '@wbk/ui';
import LanguageProvider from './context/language/provider';
import Layout from './components/layout';
import ScrollToTop from './components/common/ScrollToTop';
import ErrorBoundary from './components/error/ErrorBoundary';
import CookieConsent from './components/common/CookieConsent';
import { POSTER_1X1_FADED, POSTER_3X1_FADED } from './constants/fallbackLogo';
import { gtmTracker, postHogTracker } from '../trackers.config';

type Props = {
  type?: LayoutType;
};

const AppLayout = ({ type }: Props) => {
  const { pathname } = useLocation();
  const { shortLang } = useWebsiteLocale();
  const navigate = useNavigate();

  return (
    <ErrorBoundary>
      <UiProvider
        dir={shortLang === 'ar' ? 'rtl' : 'ltr'}
        imgFallbacks={{ '1x1': POSTER_1X1_FADED, '3x1': POSTER_3X1_FADED }}
      >
        <LanguageProvider>
          <AnalyticsProvider trackers={[gtmTracker, postHogTracker]} currentLocation={pathname}>
            <AuthProvider
              locale={shortLang}
              autoInit={false}
              i18n={{ useTranslation, Trans }}
              router={{ Link, push: navigate, replace: (u) => navigate(u, { replace: true }) }}
            >
              <WbkTicketingProvider
                locale={shortLang}
                bookingSource={process.env.VITE_PUBLIC_APP_SOURCE!}
                resell={{ domain: process.env.VITE_PUBLIC_RESELL_DOMAIN }}
                seatsIoWorkspace={process.env.VITE_PUBLIC_SEATIO_WORKSPACE_KEY}
                i18n={{ useTranslation, Trans }}
                router={{ Link, push: navigate, replace: (u) => navigate(u, { replace: true }) }}
              >
                <WbkPackagesProvider
                  bookingSource={process.env.VITE_PUBLIC_APP_SOURCE!}
                  locale={shortLang}
                  i18n={{ useTranslation, Trans }}
                  router={{ Link, push: navigate, replace: (u) => navigate(u, { replace: true }) }}
                >
                  <WbkQueueProvider locale={shortLang} i18n={{ useTranslation, Trans }}>
                    <SmcAdsProvider
                      env={
                        process.env.VITE_PUBLIC_ENV === 'production' ? 'production' : 'development'
                      }
                    >
                      <HyExperienceProvider
                        locale={shortLang}
                        i18n={{ useTranslation, Trans }}
                        router={{
                          Link,
                          push: navigate,
                          replace: (u) => navigate(u, { replace: true }),
                        }}
                      >
                        <ScrollToTop />
                        <Layout type={type} />
                        <Toaster />
                        <CookieConsent />
                      </HyExperienceProvider>
                    </SmcAdsProvider>
                  </WbkQueueProvider>
                </WbkPackagesProvider>
              </WbkTicketingProvider>
            </AuthProvider>
          </AnalyticsProvider>
        </LanguageProvider>
      </UiProvider>
    </ErrorBoundary>
  );
};

export default AppLayout;
