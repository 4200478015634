import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@wbk/ui';

const LoginButton = () => {
  const { lang } = useParams();
  const { t } = useTranslation('common');
  const { pathname } = useLocation();

  return (
    <Button
      as={Link}
      to={`/${lang}/login?redirect=${pathname}`}
      theme='white'
      shape='outlined'
      data-testid='header_nav_login_button'
      data-location='header'
      className='w-full !px-1.5 !py-1.5 md:!px-2.5'
      role='menuitem'
    >
      <p className='hidden sm:block'>{t('common:auth.login_signup')}</p>
      <div className='block shrink-0 rounded-full md:hidden'>
        <img
          className='shrink-0'
          src='/icons/profile/user-large.svg'
          width={20}
          height={20}
          alt='user icon'
        />
      </div>
    </Button>
  );
};

export default LoginButton;
