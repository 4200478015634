import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { useGetZonePages } from '@wbk/contentful/api';
import { isArabic } from '@wbk/utils';
import Seo from '@/components/headers/seo';
import useExploreFetch from '@/hooks/useExploreFetch';
import Reveal from '@/components/animation/Reveal';
import EventItem from '@/components/events/EventItem';
import EventItemSkeleton from '@/components/ui/spinners/EventItemSkeleton';
import EmptySearchResult from '@/components/search/EmptyResult';
import SearchMightLikeSection from '@/components/search/MightLike';
import SearchZoneItem from '@/components/search/SearchZoneItem';
import SearchEventItem from '@/components/search/SearchEventItem';

const SearchPage = () => {
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const search = searchParams.get('q');
  const isArabicSearch = isArabic(search);
  const searchLang = isArabicSearch ? 'ar-SA' : 'en-US';
  const { data: zones, isLoading: zoneLoading } = useGetZonePages(
    { lang: searchLang, where: { title_contains: search } },
    { enabled: Number(search?.length) > 1 }
  );

  const {
    allMergedEvents,
    anyLoading,
    fetchMoreEvents,
    fetchMoreExperiences,
    fetchMorePackages,
    fetchMoreRestaurants,
    fetchMoreShows,
    hasMoreExperiences,
    hasMoreEvents,
    hasMorePackages,
    hasMoreRestaurants,
    hasMoreShows,
  } = useExploreFetch({ detectedLang: searchLang });

  return (
    <>
      <Seo title={t('common:search_result_of', { search })} />

      <section className='container pb-4 pt-12'>
        <h1 className='text-2xl md:text-3xl'>{t('common:search_result_of', { search })}</h1>
      </section>

      <section className='container pb-20 pt-4'>
        {/* Desktop */}
        <div className='xs:grid-cols-2 hidden grid-cols-1 gap-4 pt-1 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
          {!!zones?.length && (
            <div className='col-span-full'>
              <ul className='grid grid-cols-2 gap-4 lg:grid-cols-3 xl:grid-cols-4'>
                {zones.map((zone) => (
                  <li key={zone.slug} className='flex items-center gap-2'>
                    <SearchZoneItem zonePage={zone} className='h-28 w-28' />
                  </li>
                ))}
              </ul>
              {allMergedEvents && allMergedEvents.length > 0 && (
                <hr className='border-body-lighter !my-4' />
              )}
            </div>
          )}

          {allMergedEvents.map((event, i) => {
            const delay = (i % 3) * 0.1;
            return (
              <Reveal
                key={event.id + i}
                direction='bottom'
                delay={i % 2 > 0 ? delay : -delay}
                className='mx-auto w-full'
              >
                <EventItem event={event} />
              </Reveal>
            );
          })}

          {anyLoading && allMergedEvents.length <= 0 && <EventItemSkeleton number={6} />}
        </div>
        {/* Mobile */}
        <div className='space-y-2 sm:hidden'>
          {zoneLoading && (
            <div className='space-y-2'>
              <div className='bg-body-lighter h-6 w-1/3 rounded-md' />
              <div className='bg-body-lighter h-16 rounded-md' />
              <div className='bg-body-lighter h-16 rounded-md' />
            </div>
          )}
          {!!zones?.length && (
            <>
              <ul className='space-y-4'>
                {zones.map((zone) => (
                  <li key={zone.slug} className='flex items-center gap-2'>
                    <SearchZoneItem zonePage={zone} />
                  </li>
                ))}
              </ul>
              {allMergedEvents && allMergedEvents.length > 0 && (
                <hr className='border-body-lighter !my-4' />
              )}
            </>
          )}

          <ul className='space-y-4'>
            {allMergedEvents.map((event, i) => {
              const delay = (i % 1) * 0.1;
              return (
                <Reveal
                  as='li'
                  key={event.id + i}
                  direction='bottom'
                  delay={delay}
                  className='flex items-center gap-2'
                >
                  <SearchEventItem event={event} />
                </Reveal>
              );
            })}
          </ul>
        </div>

        {!allMergedEvents.length && !anyLoading && !zoneLoading && !zones?.length && (
          <div className='flex flex-col items-center justify-center space-y-10 text-center'>
            <EmptySearchResult />
          </div>
        )}

        {allMergedEvents.length > 0 && (
          <div className='py-12 text-center'>
            <Button
              theme='white'
              shape='outlined'
              className='w-full max-w-[200px]'
              loading={anyLoading}
              disabled={
                !hasMoreExperiences &&
                !hasMoreEvents &&
                !hasMoreShows &&
                !hasMoreRestaurants &&
                !hasMorePackages
              }
              onClick={() => {
                if (hasMoreEvents) {
                  fetchMoreEvents();
                }
                if (hasMoreExperiences) {
                  fetchMoreExperiences();
                }
                if (hasMoreShows) {
                  fetchMoreShows();
                }
                if (hasMoreRestaurants) {
                  fetchMoreRestaurants();
                }
                if (hasMorePackages) {
                  fetchMorePackages();
                }
              }}
            >
              {hasMoreEvents ||
              hasMoreExperiences ||
              hasMoreShows ||
              hasMoreRestaurants ||
              hasMorePackages
                ? t('common:load_more')
                : t('common:no_load_more')}
            </Button>
          </div>
        )}
      </section>

      {!allMergedEvents.length && <SearchMightLikeSection />}
    </>
  );
};

export default SearchPage;
