import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import {
  isSameDayFromMilli,
  isSameMonthFromMilli,
  isSameYearFromMilli,
  splitDateTime,
} from '@wbk/utils';
import type { ContentfulSchedule } from '@wbk/contentful/api';

type Props = {
  schedule: ContentfulSchedule;
  className?: string;
  showIcon?: boolean;
};

const EventDateTime = ({ schedule, className, showIcon = true }: Props) => {
  const { lang } = useParams<{ lang: Language }>();

  if (schedule?.openScheduleText) {
    return (
      <div className={twMerge('flex items-start gap-1.5 text-sm', className)}>
        {showIcon && (
          <img
            src='/icons/common/calendar.svg'
            className='mt-px'
            width={20}
            height={20}
            alt='calendar'
          />
        )}
        <p data-testid='event-date'>{schedule.openScheduleText}</p>
      </div>
    );
  }

  const open = schedule?.openDateTime
    ? new Date(schedule?.openDateTime).getTime() / 1000
    : Date.now() / 1000;
  const close = schedule?.closeDateTime ? new Date(schedule?.closeDateTime).getTime() / 1000 : open;
  const locale = lang?.split('-')?.[0] || 'en';

  const start = splitDateTime(open, `${locale}-GB`);
  const end = splitDateTime(close, `${locale}-GB`);

  const isSameYear = isSameYearFromMilli(open, close);
  const isSameMonth = isSameMonthFromMilli(open, close);
  const isSameDay = isSameDayFromMilli(open, close);

  return (
    <div className={twMerge('flex items-start gap-1.5 text-sm uppercase', className)}>
      {showIcon && <img src='/icons/common/calendar.svg' width={20} height={20} alt='calendar' />}
      <p data-testid='event-date'>
        {isSameDay
          ? `${start.day} ${start.month} ${start.year} - ${start.time}`
          : isSameYear && isSameMonth
            ? `${start.day} - ${end.day} ${start.month} ${start.year} | ${start.time}`
            : isSameYear
              ? `${start.day} ${start.month} - ${end.day} ${end.month} ${end.year}`
              : `${start.day} ${start.month} ${start.year} - ${end.day} ${end.month} ${end.year}`}
      </p>
    </div>
  );
};

export default EventDateTime;
