import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import useSearch from '@/context/search/useSearch';

const SearchSeeAllButton = () => {
  const { t } = useTranslation('common');
  const { lang } = useParams<{ lang: Language }>();
  const { searchState, togglePopover } = useSearch();

  return (
    <Button
      data-testid='header_search_see_all_button'
      theme='white'
      shape='text'
      as={Link}
      to={`/${lang}/search?q=${searchState?.trim()}`}
      onClick={() => togglePopover(false)}
      className='w-full bg-white/5 text-sm'
    >
      {t('common:see_all')}
    </Button>
  );
};

export default SearchSeeAllButton;
