const LocationIcon = ({ className = '' }) => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.45113 17.2122C7.69727 15.6352 4.16602 12.1102 4.16602 9.2915C4.16602 5.5415 6.66602 3.0415 10.416 3.0415C14.166 3.0415 16.666 6.1665 16.666 9.2915C16.666 11.6598 13.0763 15.464 11.3379 17.1675C10.8134 17.6814 9.9972 17.7031 9.45113 17.2122ZM12.4993 8.45817C12.4993 9.60876 11.5666 10.5415 10.416 10.5415C9.26542 10.5415 8.33268 9.60876 8.33268 8.45817C8.33268 7.30758 9.26542 6.37484 10.416 6.37484C11.5666 6.37484 12.4993 7.30758 12.4993 8.45817Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default LocationIcon;
