import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, RadioGroup, ToggleGroup } from '@wbk/ui';
import { Poll, WebSection } from '@wbk/contentful/api';
import { useAuth } from '@wbk/auth';
import { CheckIcon } from '@wbk/svg';
import AnimatedCheck from '../svg/Check';
import ContentfulImage from '../images/ContentfulImage';

type Props = {
  section: WebSection;
};

type FormValues = { answer: string | string[] };

const PollSection = ({ section }: Props) => {
  const poll = section?.contentCollection?.items?.[0] as Poll;
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { lang } = useParams<{ lang: Language }>();
  const { user } = useAuth({ autoFetch: true });
  const { pathname } = useLocation();

  const { t } = useTranslation('common');
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>();

  const noOptionsAvailable = !poll.isTextInput && !poll.optionsCollection.items.length;

  if (!poll || noOptionsAvailable) {
    return null;
  }

  const onSubmit = async (data: FormValues) => {
    try {
      setIsLoading(true);
      await fetch(poll.formBucketUrl, {
        method: 'POST',
        headers: {
          accept: 'application/javascript',
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          user_id: user?._id,
          user_email: user?.email,
          user_first_name: user?.first_name,
          user_last_name: user?.last_name,
          user_country: user?.cell_country_code,
          user_cell_number: user?.cell_number,
          type: 'quiz',
          date: new Date().toISOString(),
          question: poll.title,
          answer: data.answer,
        }),
      });

      setIsSuccess(true);
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section data-testid={`pollSection_${section.id}`} className='container bg-cover py-12'>
      <div
        className='overflow-hidden rounded-2xl bg-cover bg-left-bottom bg-no-repeat px-4 py-8 md:bg-cover md:px-8'
        style={{
          backgroundImage: `url(${section.sectionBackground?.url})`,
        }}
      >
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='font-semibold'>{poll.title || section.title}</h2>
          {poll.subtitle && <p>{poll.subtitle}</p>}
        </div>

        <form
          data-multi={poll.isMultiOption}
          className='mx-auto w-full max-w-lg space-y-2 rounded-xl bg-cover p-4 data-[multi=true]:max-w-full'
          onSubmit={handleSubmit(onSubmit)}
        >
          {isSuccess ? (
            <div className='text-center'>
              <AnimatedCheck className='text-success mx-auto h-32 w-32' />
              <p className='text-lg'> {t('common:answer_submitted')} </p>
            </div>
          ) : (
            <>
              <fieldset>
                {poll.isTextInput ? (
                  <textarea
                    id='message'
                    className='max-h-[120px] min-h-[80px] w-full appearance-none rounded-lg border border-white/20 bg-white/20 p-2 hover:bg-white/30 focus:border-gray-400 focus:bg-white/20 focus:!outline-none focus:ring-0'
                    placeholder={t('common:write_answer')}
                    {...register('answer', {
                      required: t('common:validation.required'),
                      minLength: {
                        value: 20,
                        message: t('common:validation.min_length', { min: 20 }),
                      },
                    })}
                  />
                ) : (
                  <Controller
                    name='answer'
                    control={control}
                    rules={{ required: t('common:validation.required') }}
                    render={({ field: { value, ...props } }) => {
                      if (poll.isMultiOption) {
                        return (
                          <ToggleGroup
                            {...props}
                            label=''
                            groupClassName='justify-center grid grid-cols-2 xs:flex'
                            className='xs:basis-40 grow-0 border-2 border-transparent bg-[#242427] data-[state=on]:border-blue-500 data-[state=on]:bg-[#242427]'
                            type='multiple'
                            value={value as string[]}
                            options={poll.optionsCollection.items.map((option) => {
                              const isSelected = value?.includes(option.slug);
                              return {
                                text: (
                                  <>
                                    {isSelected && (
                                      <CheckIcon className='absolute top-0 m-1 h-5 w-5 text-blue-500 ltr:right-0 rtl:left-0' />
                                    )}
                                    {option.icon?.url && (
                                      <ContentfulImage
                                        className='mx-auto pb-1'
                                        src={option.icon.url}
                                        alt={option.icon.title}
                                        width={25}
                                        height={25}
                                      />
                                    )}
                                    {option.title}
                                  </>
                                ),
                                value: option.slug,
                              };
                            })}
                          />
                        );
                      }

                      return (
                        <RadioGroup
                          {...props}
                          label=''
                          className='bg-[#242427]'
                          options={poll.optionsCollection.items.map((option) => ({
                            text: (
                              <p className='flex items-center gap-2'>
                                {option.icon?.url && (
                                  <ContentfulImage
                                    className=''
                                    src={option.icon.url}
                                    alt={option.icon.title}
                                    width={20}
                                    height={20}
                                  />
                                )}
                                {option.title}
                              </p>
                            ),
                            value: option.slug,
                          }))}
                          value={(value as string) || ''}
                        />
                      );
                    }}
                  />
                )}
              </fieldset>

              {errors?.answer?.message && (
                <div className='text-error my-1 min-h-[16px] text-xs'>
                  <p>{errors?.answer?.message}</p>
                </div>
              )}

              <div className='mx-auto max-w-lg pt-4'>
                {user ? (
                  <Button type='submit' className='w-full' loading={isLoading}>
                    {t('common:submit')}
                  </Button>
                ) : (
                  <Button
                    type='button'
                    as={Link}
                    to={`/${lang}/login?redirect=${pathname}`}
                    theme='secondary'
                    className='w-full'
                  >
                    {t('common:login_to_submit')}
                  </Button>
                )}
              </div>
            </>
          )}
        </form>
      </div>
    </section>
  );
};

export default PollSection;
