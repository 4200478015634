const RestaurantIcon = (props: React.ComponentProps<'svg'>) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g>
        <path
          opacity='0.3'
          d='M14.2326 12.6165C13.6742 11.9998 12.9076 11.6665 12.0826 11.6665C10.9576 11.6665 9.93255 12.3248 9.44922 13.3332H14.7159C14.5992 13.0748 14.4326 12.8332 14.2326 12.6165Z'
          fill='currentColor'
        />
        <path
          d='M1.66602 15.833L3.33268 17.4997H16.666L18.3327 15.833H1.66602Z'
          fill='currentColor'
        />
        <path
          d='M2.49902 15H16.6407C16.8824 12.2833 14.7407 10 12.074 10C10.1157 10 8.44902 11.2333 7.79069 12.9583C7.44902 12.7667 7.06569 12.6417 6.66569 12.5667V7.5H8.12402C9.15736 7.5 9.99902 6.65833 9.99902 5.625H17.499V4.375H9.99902C9.99902 3.34167 9.15736 2.5 8.12402 2.5H2.49902V3.75H3.33236V4.375H2.49902V5.625H3.33236V6.25H2.49902V7.5H3.33236V13.6583C2.96569 14.0417 2.68236 14.4917 2.49902 15ZM12.0824 11.6667C12.9074 11.6667 13.674 12 14.2324 12.6167C14.4324 12.8333 14.599 13.075 14.7157 13.3333H9.44902C9.93236 12.325 10.9574 11.6667 12.0824 11.6667ZM6.66569 3.75H8.33236V4.375H6.66569V3.75ZM6.66569 5.625H8.33236V6.25H6.66569V5.625ZM4.58236 3.75H5.41569V4.375H4.58236V3.75ZM4.58236 5.625H5.41569V6.25H4.58236V5.625ZM4.58236 7.5H5.41569V12.55C5.12402 12.6 4.84902 12.6917 4.58236 12.8V7.5Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default RestaurantIcon;
