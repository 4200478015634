import { Link, useParams } from 'react-router-dom';
import { Package } from '@wbk/contentful/api';
import { useWindowScroll } from '@wbk/hooks';
import { useQueue } from '@wbk/queue';
import { ImageWithFallback } from '@wbk/ui';
import EventDateTime from '../events/DateTime';
import BuyTicketButton from './BuyButton';

type Props = {
  event: Package;
};

const StickyPackageBuyNav = ({ event }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const { y } = useWindowScroll();
  const { isActive, shouldWaitQueue } = useQueue();

  if (isActive || shouldWaitQueue) {
    return null;
  }

  return (
    <div
      className={`bg-body-light fixed inset-x-0 top-0 z-50 hidden h-20 items-center gap-2 p-4 transition-all duration-300 lg:flex ${y > 0 ? 'translate-y-0' : '-translate-y-full'}`}
    >
      <Link
        to={`/${lang}`}
        className='logo-image relative block shrink-0 hover:opacity-90'
        data-location='header'
        aria-label='webook.com Logo'
      >
        <img src='/icons/logo.svg' width={100} height={48} alt='' />
      </Link>

      <div>
        <div className='flex items-center gap-4'>
          <ImageWithFallback
            className='aspect-1 mx-auto max-w-2xl overflow-hidden rounded-md object-cover'
            src={event.image11?.url}
            width={60}
            height={60}
            alt={event.title}
          />
          <div>
            <p className='mb-1 font-medium uppercase'>{event.title}</p>
            {event.schedule && (
              <EventDateTime schedule={event.schedule} className='text-xs' showIcon={false} />
            )}
          </div>
        </div>
      </div>
      <div className='grow' />
      <div>
        <div
          className='hidden w-full gap-4 lg:flex lg:justify-between [&>div]:w-full'
          data-testid='buy-tickets-cta'
        >
          <BuyTicketButton
            event={event}
            className='min-h-[40px] px-4 text-sm'
            containerClassName='[&>div]:scale-[0.85] [&>div]:shrink-0'
          />
        </div>
      </div>
    </div>
  );
};

export default StickyPackageBuyNav;
