import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@wbk/ui';
import { PriceLabel } from '@wbk/ticketing';
import BuyTicketButton from '../buttons/BuyButton';
import ContentfulButton from '../buttons/ContentfulButton';
import ContentfulRichText from '../text/ContentfulRichText';
import StaticCheck from '../svg/StaticCheck';
import Gallery from './Gallery';
import type { ContentfulImage, Restaurant } from '@wbk/contentful/api';

type Props = {
  data: Restaurant;
};

const RestaurantSummary = ({ data }: Props) => {
  const { t } = useTranslation(['common', 'event']);
  const [menu, setMenu] = useState(-1);

  const features = data.features?.qaListCollection?.items;
  const socialAccounts = data.socialLinks?.iconLinksCollection?.items;

  return (
    <div className='rounded-xl bg-white/10 px-4 py-3 backdrop-blur-xl'>
      <div className='space-between flex items-center justify-between gap-4'>
        <div className='shrink-0 leading-none'>
          <span className='text-xs'>{t('event:booking_fee')}</span>
          <PriceLabel
            originalPrice={data.startingPrice}
            currency={data.currencyCode || 'SAR'}
            className='text-xl font-semibold md:text-2xl'
          />
          <span className='text-xs text-gray-400'>{t('event:per_person')}</span>
        </div>

        <BuyTicketButton
          event={{
            ...data,
            startingPrice: 0,
          }}
          containerClassName='bg-transparent w-[234px] max-w-56 p-0'
          className='font-medium'
          id='buyButton'
        />
      </div>

      <Modal
        render={({ open }) => {
          return (
            <>
              {!!data.menuCollection?.items?.length && (
                <>
                  <div className='my-2 block h-px bg-white/20 md:hidden' />

                  <div className='block md:hidden'>
                    <Button
                      shape='outlined'
                      theme='white'
                      className='flex h-11 w-full rounded-lg border-white/30 font-medium capitalize'
                      onClick={() => {
                        open();
                        setMenu(0);
                      }}
                    >
                      <img
                        src='/icons/common/clipboard.svg'
                        alt='view menu'
                        width={20}
                        height={20}
                      />
                      <span>{t('event:menu')}</span>
                    </Button>
                  </div>
                </>
              )}
            </>
          );
        }}
        className='overflow-y-auto p-4'
        body={() => (
          <Gallery
            posters={data?.menuCollection?.items as ContentfulImage[]}
            autoPlay={false}
            selectedIndex={menu}
          />
        )}
      />

      {features && features.length > 0 && (
        <>
          <div className='my-2 h-px bg-white/20' />

          <ul className='space-y-3 py-2 text-sm'>
            {features.map((item, index) => {
              return (
                <li className='flex items-center gap-2' key={index}>
                  <StaticCheck className='h-6 w-6 shrink-0 text-green-700 [&_path]:fill-green-300' />
                  <span>{item.title}</span>
                </li>
              );
            })}
          </ul>
        </>
      )}

      {socialAccounts && socialAccounts.length > 0 && (
        <>
          <div className='my-2 h-px bg-white/20' />

          <div className='flex items-center justify-between gap-2 md:gap-4'>
            <p className='shrink-0 font-semibold'>{t('event:social_accounts')}</p>

            <ul className='flex flex-wrap items-start'>
              {socialAccounts.map((link) => {
                if (!link) return null;

                return (
                  <li key={link.id}>
                    <ContentfulButton
                      link={link}
                      rel='noreferrer noopener'
                      className='event_social_media_follow h-11 w-11 overflow-hidden rounded-md p-0.5'
                      data-category={link.label}
                      rounded
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}

      {data.contact?.description && (
        <>
          <div className='my-2 h-px bg-white/20' />

          <div className='flex items-center justify-between py-3'>
            <p className='font-semibold'>{data.contact?.title || t('event:contact_info')}</p>

            <div dir='ltr' className='[&_a]:text-blue-400'>
              <ContentfulRichText description={data.contact.description} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RestaurantSummary;
