import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button, Input, MobileInput, Select } from '@wbk/ui';
import { User, advertise } from '@wbk/api';
import { useAdvertise } from '@wbk/api/hooks';
import { useEmailProviders } from '@wbk/hooks';
import { WithGrecaptcha } from '@wbk/api/grecaptcha';
import { COUNTRIES, EMAIL_PATTERN } from '@wbk/utils';
import AnimatedCheck from '@/components/svg/Check';

type AdvertiseProps = Parameters<typeof advertise>[0];

type FormValues = AdvertiseProps & {
  success: boolean;
  country: (typeof COUNTRIES)[number];
};

const getDefaultValues = (user?: User): Partial<FormValues> => {
  const country = COUNTRIES.find((c) => c.code === user?.cell_country_code);
  return {
    country: country || COUNTRIES.find((c) => c.code === 'SA'),
    phone: user?.cell_number?.replace(`+${country?.dial_code}`, '') || '',
  };
};

const INTEREST_OPTIONS: FormValues['interest'][] = [
  'advertising',
  'partnering',
  'sponsorships',
  'other',
];

const AdvertiseForm = ({ user }: { user?: User }) => {
  const { lang } = useParams();
  const { t } = useTranslation(['common', 'profile', 'advertise']);
  const { mutateAsync, isPending, error } = useAdvertise();
  const { data: emailProviders } = useEmailProviders();

  const {
    register,
    control,
    setValue,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: getDefaultValues(user),
  });

  const country = watch('country');
  const success = watch('success');

  const selectedCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === country.code);
  }, [country.code]);

  const onSubmit = async (data: FormValues) => {
    try {
      await mutateAsync({
        full_name: data.full_name,
        email: data.email,
        phone: data.phone,
        interest: data.interest,
        company_name: data.company_name,
      });

      setValue('success', true);
    } catch (error) {
      //
    }
  };

  return (
    <WithGrecaptcha>
      <div className='container mb-10'>
        <div className='space-y-4 pb-5'>
          <h1 className='text-5xl'>{t('advertise:title')}</h1>
        </div>

        <div className='bg-body-light mx-auto w-full max-w-xl rounded-lg px-4 py-6'>
          {success ? (
            <div className='text-center'>
              <AnimatedCheck className='text-tertiary mx-auto h-40 w-40' />
              <h2 className='pb-1'>{t('advertise:send_success')}</h2>
              <p className='text-lg'> {t('advertise:send_success_desc')} </p>
              <Button as={Link} to={`/${lang}`} className='mx-auto mt-8 w-full max-w-sm' replace>
                <p className='text-lg font-bold'>{t('common:go_home')}</p>
              </Button>
            </div>
          ) : (
            <form className='space-y-3' onSubmit={handleSubmit(onSubmit)}>
              <div className='space-y-1'>
                <p className='text-2xl font-bold'>{t('advertise:title')}</p>
              </div>

              <div>
                <Input
                  label={<span className='font-normal'>{t('profile:form.full_name')}</span>}
                  id='full_name'
                  error={errors.full_name?.message}
                  {...register('full_name', {
                    required: t('common:validation.required'),
                    maxLength: {
                      value: 50,
                      message: t('common:validation.max_length', { length: 50 }),
                    },
                  })}
                  onChange={(value) => {
                    setValue('full_name', value);
                  }}
                />
              </div>

              <div>
                <Input
                  label={<span className='font-normal'>{t('advertise:company_name')}</span>}
                  id='company_name'
                  error={errors.company_name?.message}
                  {...register('company_name', {
                    required: t('common:validation.required'),
                    maxLength: {
                      value: 50,
                      message: t('common:validation.max_length', { length: 50 }),
                    },
                  })}
                  onChange={(value) => {
                    setValue('company_name', value);
                  }}
                />
              </div>

              <div>
                <Controller
                  name='interest'
                  control={control}
                  rules={{ required: t('common:validation.required') }}
                  render={({ field: { onChange, ...props } }) => (
                    <Select
                      id='interest'
                      {...props}
                      autoComplete='off'
                      onChange={(selected) => onChange(selected?.value || '')}
                      label={<span className='font-normal'>{t('advertise:interests.title')}</span>}
                      options={INTEREST_OPTIONS.map((option) => {
                        return {
                          id: option,
                          value: option,
                          text: t(`advertise:interests.${option}`),
                        };
                      })}
                      error={errors.interest?.message}
                    />
                  )}
                />
              </div>

              <Controller
                control={control}
                name='email'
                rules={{
                  required: t('common:validation.required'),
                  pattern: {
                    value: EMAIL_PATTERN,
                    message: t('common:validation.invalid_email'),
                  },
                  validate: (value) => {
                    const containsEmailProvider = emailProviders?.some((provider) =>
                      value.endsWith(provider)
                    );
                    return containsEmailProvider
                      ? t('common:validation.only_company_emails')
                      : true;
                  },
                }}
                defaultValue=''
                render={({ field: { onChange, ...props } }) => (
                  <div>
                    <Input
                      {...props}
                      type='email'
                      label={<span className='font-normal'>{t('profile:form.email')}</span>}
                      placeholder='you@email.com'
                      onChange={onChange}
                      error={errors.email?.message}
                    />
                  </div>
                )}
              />

              <fieldset className='w-full'>
                <Controller
                  name='phone'
                  control={control}
                  rules={{
                    required: t('common:validation.required'),
                    validate: (value) => {
                      const hasValidation = selectedCountry?.mobile_starts_with?.length;
                      if (hasValidation) {
                        const validStart = selectedCountry?.mobile_starts_with?.some((start) =>
                          value.startsWith(start)
                        );
                        return validStart || t('common:validation.invalid_mobile');
                      }

                      return true;
                    },
                  }}
                  render={({ field: { value, ...props } }) => (
                    <MobileInput
                      {...props}
                      label={<span className='font-normal'>{t('profile:form.mobile')}</span>}
                      country={country}
                      mobile={value}
                      onChange={(name, value) => {
                        if (name === 'country') {
                          setValue('country', value as FormValues['country']);
                        } else {
                          setValue('phone', value as FormValues['phone']);
                        }
                      }}
                      error={errors?.phone?.message || errors?.country?.message}
                      placeholder={
                        selectedCountry
                          ? `${selectedCountry?.mobile_starts_with?.[0] || ''}${'x'.repeat(
                              (selectedCountry.phone_number_lengths?.[0] || 7) - 1
                            )}`
                          : ''
                      }
                    />
                  )}
                />
              </fieldset>

              <div className=''>
                {error?.message && (
                  <p className='text-error pb-4 text-center text-sm'>{error.message}</p>
                )}
                <Button
                  type='submit'
                  loading={isPending}
                  className='w-full py-3 text-lg font-bold capitalize'
                  id='advertise-submit'
                  data-location='advertise'
                >
                  {t('common:submit')}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </WithGrecaptcha>
  );
};

export default AdvertiseForm;
