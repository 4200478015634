import EventIcon from '@/components/svg/Event';
import ExperienceIcon from '@/components/svg/Experience';
import RestaurantIcon from '@/components/svg/Restaurant';
import ShowIcon from '@/components/svg/Show';
import StreamIcon from '@/components/svg/Stream';
import TypeIcon from '@/components/svg/Type';
import { EXPLORE_TYPE_COLOR } from '@/constants/exploreTypes';
import PeopleIcon from '../svg/People';

type Props = {
  type: ExploreTypes;
  colored?: boolean;
  className?: string;
};

const FilterTypeIcon = ({ type, colored, className = '' }: Props) => {
  const color = colored ? EXPLORE_TYPE_COLOR[type] : '';

  const props = {
    className,
    style: { color },
  };

  switch (type) {
    case 'experiences':
      return <ExperienceIcon {...props} />;
    case 'restaurants':
      return <RestaurantIcon {...props} />;
    case 'shows':
      return <ShowIcon {...props} />;
    case 'streams':
      return <StreamIcon {...props} />;
    case 'packages':
      return <TypeIcon {...props} />;
    case 'conferences':
      return <PeopleIcon {...props} />;
    default:
      return <EventIcon {...props} />;
  }
};

export default FilterTypeIcon;
