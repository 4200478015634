import { ContentfulEvent } from '@wbk/contentful/api';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@wbk/utils';
import { useParams } from 'react-router-dom';
import { resolveContentfulEventType } from '@/util/events';

type Props = {
  event: {
    __typename: ContentfulEvent['__typename'];
    isStreamingEvent?: ContentfulEvent['isStreamingEvent'];
    location?: Partial<ContentfulEvent['location']>;
    schedule?: Partial<ContentfulEvent['schedule']>;
  };
};

const EventItemType = ({ event }: Props) => {
  const type = event.isStreamingEvent ? 'streams' : resolveContentfulEventType(event.__typename);
  const { t } = useTranslation(['event']);
  const { lang } = useParams<{ lang: Language }>();

  const name = t(`event:type.${type}`);

  // Date only visible in events
  const date =
    type === 'events' && event?.schedule?.openDateTime
      ? formatDate(event.schedule.openDateTime, lang, { month: 'short', day: '2-digit' })
      : '';

  return (
    <div className='text-sm leading-none text-gray-400'>
      <span>{name}</span>
      {date && <span>{` · ${date}`}</span>}
    </div>
  );
};

export default EventItemType;
