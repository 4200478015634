import { twMerge } from 'tailwind-merge';
import { Link, useParams } from 'react-router-dom';
import { ContentfulEvent } from '@wbk/contentful/api';
import { useWindowScroll } from '@wbk/hooks';
import { useQueue } from '@wbk/queue';
import { ImageWithFallback } from '@wbk/ui';
import BuyTicketButton from '../buttons/BuyButton';
import { NotifyButton } from '../buttons/NotifyButton';
import EventDateTime from './DateTime';

type Props = {
  event: ContentfulEvent;
  className?: string;
};

export const StickyEventNav = ({ event, className }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const { y } = useWindowScroll();
  const { isActive, shouldWaitQueue } = useQueue();

  if (isActive || shouldWaitQueue) {
    return null;
  }

  return (
    <div
      className={`bg-body-light fixed inset-x-0 top-0 z-50 hidden h-20 items-center gap-2 p-4 transition-all duration-300 lg:flex ${y > 300 ? 'translate-y-0' : '-translate-y-full'}`}
    >
      <Link
        to={`/${lang}`}
        className='logo-image relative block shrink-0 hover:opacity-90'
        data-location='header'
        aria-label='webook.com Logo'
      >
        <img src='/icons/logo.svg' width={100} height={48} alt='' />
      </Link>

      <div className='flex grow items-center justify-start gap-4'>
        <ImageWithFallback
          className='aspect-1 max-w-2xl overflow-hidden rounded-md object-cover'
          src={event.image11?.url + '?fm=webp'}
          width={60}
          height={60}
          alt={event.title}
        />
        <div>
          <p className='mb-1 font-medium uppercase'>{event.title}</p>
          {event.schedule && (
            <EventDateTime schedule={event.schedule} className='text-xs' showIcon={false} />
          )}
        </div>
      </div>

      <div
        className='hidden shrink-0 gap-4 lg:flex lg:justify-between [&>div]:w-full'
        data-testid='buy-tickets-cta'
      >
        {event.eventType?.includes('offline') ? (
          <NotifyButton
            event={event}
            type={event.__typename === 'Experience' ? 'experience' : 'event'}
          />
        ) : (
          <BuyTicketButton
            event={event}
            className={twMerge('min-h-[44px] px-6 text-base font-normal', className)}
            containerClassName='!bg-transparent'
          />
        )}
      </div>
    </div>
  );
};
