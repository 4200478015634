import { GtmTracker, PostHogTracker } from '@wbk/analytics';

export const postHogTracker = new PostHogTracker({
  apiKey: process.env.VITE_PUBLIC_POSTHOG_API_KEY!,
  log: process.env.VITE_PUBLIC_ENV !== 'production',
  capture_pageview: false,
});

export const gtmTracker = new GtmTracker({
  gtmIds: process.env.VITE_PUBLIC_GOOGLE_TAG_MANAGER?.split(',') || [],
  log: process.env.VITE_PUBLIC_ENV !== 'production',
});
