import { Link } from 'react-router-dom';
import { parseSectionUtm } from '@wbk/utils';
import ContentfulButton from '../buttons/ContentfulButton';
import ContentfulImage from '../images/ContentfulImage';
import type { WebCard, WebSection } from '@wbk/contentful/api';

type Props = {
  section: WebSection;
};

const CardsGrid = ({ section }: Props) => {
  const cards = section.contentCollection.items as WebCard[];

  if (!cards?.length) return null;

  return (
    <section
      data-testid={`home_cards_grid_${section.id}`}
      className='container relative space-y-6 overflow-hidden py-12'
    >
      <h2>{section.title}</h2>
      {!!section.sectionBackground?.url && (
        <img
          src={section.sectionBackground.url}
          width={section.sectionBackground.width}
          height={section.sectionBackground.height}
          className='absolute left-0 -z-10 object-cover object-center'
          alt=''
        />
      )}
      <div className='flex flex-wrap gap-3 lg:[&>div:nth-child(n+2)]:basis-[calc(33.3%-12px)] lg:[&>div:nth-child(n+4)]:basis-[calc(25%-12px)] [&>div]:basis-full sm:[&>div]:basis-[calc(50%-12px)] md:[&>div]:basis-[calc(50%-12px)] lg:[&>div]:basis-[calc(33.3%-12px)]'>
        {cards.map((card, i) => {
          const isLink = !!card.href && card.href !== '#';
          const isExternal = isLink && card.href?.startsWith('http');
          const El = isLink ? Link : 'div';
          const utm = parseSectionUtm({ utm: section.utm, idx: i, id: card.id });

          return (
            <div
              key={card.id}
              className='[&:nth-child(n+1)]:aspect-1 [&:nth-child(n+3)]:aspect-1 lg:[&:nth-child(n+1)]:aspect-[4/3]'
            >
              <El
                data-testid={`home_cards_grid_item_${card.id}`}
                to={card.href ? `${card.href}${utm}` : '#'}
                target={isExternal ? '_blank' : undefined}
                rel='noreferrer'
                className='group relative flex h-full w-full flex-col justify-between'
              >
                <div className='absolute inset-0 z-10 scale-105 bg-gradient-to-b from-black/5 via-black/10 to-black/95 to-90%' />
                <div className='flex-co justify-betweenl relative flex h-full overflow-hidden rounded-lg transition duration-500'>
                  <ContentfulImage
                    src={card.image.url}
                    alt={card.title}
                    sizes='800px'
                    placeholder
                    className='absolute -z-10 h-full w-full object-cover transition duration-500 group-hover:scale-[1.02]'
                  />
                  <div />
                  <div className='relative z-10 flex items-end justify-between p-4'>
                    <div className='space-y-2'>
                      <p className='text-xl'>{card.title}</p>
                      {card.subtitle && <p className='text-sm'>{card.subtitle}</p>}
                    </div>
                    {card.icon && (
                      <div className='shrink-0'>
                        <ContentfulImage
                          src={card.icon.url}
                          width={30}
                          height={31}
                          alt=''
                          fit='pad'
                        />
                      </div>
                    )}
                  </div>
                </div>
              </El>
            </div>
          );
        })}
      </div>

      <div className='flex items-center justify-center gap-4'>
        {section.webLinksCollection.items.map((link) => (
          <ContentfulButton key={link.id} link={link} />
        ))}
      </div>
    </section>
  );
};

export default CardsGrid;
