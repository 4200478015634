import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { ContentfulEvent, WebSection } from '@wbk/contentful/api';
import { parseSectionUtm } from '@wbk/utils';
import { AdSlotMPU, useAds } from '@smc/ads';
import ContentfulImage from '../images/ContentfulImage';
import ContentfulButton from '../buttons/ContentfulButton';
import EventItem from './EventItem';

type Props = {
  section: WebSection;
};

const EventsGrid = ({ section }: Props) => {
  const events = section.contentCollection.items as ContentfulEvent[];
  const { canRenderAds, shouldRenderMpu } = useAds();
  const { slug } = useParams<{ slug: string }>();
  const adSlug = slug || 'home';

  if (!events.length) return null;

  return (
    <section
      data-testid={`home_events_grid_${section.id}`}
      className='relative space-y-8 overflow-hidden py-12'
    >
      {!!section.sectionBackground?.url && (
        <ContentfulImage
          src={section.sectionBackground.url}
          width={section.sectionBackground.width}
          height={section.sectionBackground.height}
          className='absolute left-0 -z-10 object-cover object-center'
          alt=''
        />
      )}

      {section.title && (
        <div className='container'>
          <h2 className='text-white'>{section.title}</h2>
        </div>
      )}

      <div className='container flex flex-wrap gap-3 lg:[&>*:nth-child(n+2)]:basis-[calc(33.3%-12px)] lg:[&>*:nth-child(n+4)]:basis-[calc(25%-12px)] [&>*]:basis-full sm:[&>*]:basis-[calc(50%-12px)] md:[&>*]:basis-[calc(50%-12px)] lg:[&>*]:basis-[calc(33.3%-12px)]'>
        {events.map((event, i) => (
          <Fragment key={event.id}>
            <EventItem
              key={event.id}
              event={event}
              cardType={section.itemRenderType}
              utm={parseSectionUtm({ utm: section.utm, idx: i, id: event.id })}
            />
            {/* Ads */}
            {canRenderAds && shouldRenderMpu(events.length, i) && (
              <AdSlotMPU
                adUnit={adSlug}
                slotId={`${section.id}-${i}`}
                className='mx-auto flex grow flex-col overflow-hidden rounded-md'
              >
                <div className='grow'></div>
              </AdSlotMPU>
            )}
          </Fragment>
        ))}
      </div>

      <div className='flex items-center justify-center gap-4'>
        {section.webLinksCollection.items.map((link) => (
          <ContentfulButton key={link.id} link={link} />
        ))}
      </div>
    </section>
  );
};

export default EventsGrid;
