import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { BottomSheet, Button } from '@wbk/ui';
import CloseButton from '@/components/buttons/CloseButton';
import FilterType from '@/components/explore/FilterType';
import TypeIcon from '@/components/svg/Type';

const TYPES: ExploreTypes[] = [
  'events',
  'experiences',
  'restaurants',
  'shows',
  'packages',
  'streams',
  'conferences',
];

const FilterTypes = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const types = searchParams.getAll('type') || [];
  const [localType, setLocalTypes] = useState(types);
  const hasSelection = types.length > 0;

  const handleFilter = (type: string) => {
    const newUrl = new URLSearchParams(searchParams.toString());
    newUrl.delete('page');
    if (types.includes(type)) {
      newUrl.delete('type', type);
    } else {
      newUrl.append('type', type);
    }

    const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';

    navigate(`${pathname}${search}`, { replace: true });
  };

  const handleMobileFilter = (type: string) => {
    setLocalTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : prev.concat(type)
    );
  };

  const handleMobileApply = () => {
    const newUrl = new URLSearchParams(searchParams.toString());
    newUrl.delete('page');
    newUrl.delete('type');
    localType.forEach((type) => {
      newUrl.append('type', type);
    });
    const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
    navigate(`${pathname}${search}`, { replace: true });
  };

  useEffect(() => {
    if (types.length === 0) {
      setLocalTypes([]);
    }
  }, [types.length]);

  return (
    <>
      <ul className='hidden gap-1.5 overflow-x-auto p-1 lg:flex [&>*]:shrink-0'>
        {TYPES.map((type) => (
          <li key={type}>
            <FilterType selected={types} onSelect={() => handleFilter(type)} type={type} />
          </li>
        ))}
      </ul>

      {/* Mobile */}
      <BottomSheet
        rounded
        ignoreHistory
        render={({ open }) => (
          <Button
            theme='primary'
            onClick={() => {
              open();
              setLocalTypes(types);
            }}
            className={`border-primary bg-body-light hover:bg-primary/30 focus:bg-primary/30 active:text-text shrink-0 border-2 px-2.5 py-1 text-sm text-gray-100 shadow-md lg:hidden ${
              hasSelection
                ? 'bg-primary/30 text-primary focus:border-2 active:border-2'
                : 'border-transparent'
            }`}
            phill={false}
            rounded
          >
            <TypeIcon />
            <span>{t('common:type')}</span>
            {hasSelection && <span>{`(${types.length})`}</span>}

            {hasSelection && (
              <a
                href='#!'
                className='text-primary relative -mt-px h-5 w-5 rounded-full bg-white/10 p-1 font-sans text-[16px] ltr:ml-1 rtl:mr-1'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  const newUrl = new URLSearchParams(searchParams.toString());
                  newUrl.delete('type');
                  const search = newUrl.size > 0 ? `?${newUrl.toString()}` : '';
                  navigate(`${pathname}${search}`, { replace: true });
                }}
              >
                <span className='absolute left-1/2 top-1/2 -mt-px -translate-x-1/2 -translate-y-1/2 leading-none'>
                  x
                </span>
              </a>
            )}
          </Button>
        )}
        stickyFooter={({ close }) => (
          <div className='p-2 pb-4 text-center'>
            <Button
              className='w-full max-w-sm'
              onClick={() => {
                handleMobileApply();
                close();
              }}
            >
              {t('common:apply')}
            </Button>
          </div>
        )}
        body={({ close }) => (
          <div className='px-4 pb-24'>
            <div className='flex items-center justify-between'>
              <h4 className='font-bold'>{t('common:type')}</h4>
              <CloseButton onClick={close} className='relative left-0 right-0 top-0' />
            </div>
            <ul className='mt-4'>
              {TYPES.map((type) => (
                <li key={type} className='relative flex items-start py-4'>
                  <FilterType
                    type={type}
                    selected={localType}
                    onSelect={() => handleMobileFilter(type)}
                    render='checkbox'
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      />
    </>
  );
};

export default FilterTypes;
