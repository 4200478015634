import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel, ImageWithPlaceholder } from '@wbk/ui';

type Props = {
  poster: string;
  carousel: string[];
  title: string;
};

const GalleryWithThumbs = ({ poster, carousel, title }: Props) => {
  const { lang } = useParams<{ lang: string }>();

  const list = useMemo(() => {
    return [poster].concat(carousel);
  }, [carousel, poster]);

  return (
    <Carousel
      locale={lang}
      className='aspect-1 mx-auto max-w-[600px]'
      thumbClassName='mx-auto max-w-[600px] pt-2'
      thumbs={({ scrollTo, selectedIndex }) => (
        <div className='flex gap-2'>
          {list.map((image, i) => (
            <button
              key={image + i}
              className='aspect-1 relative shrink-0 overflow-hidden rounded-lg'
              aria-label={`${title} image ${i + 1}`}
              onClick={() => scrollTo(i)}
            >
              {i !== selectedIndex && <div className='absolute inset-0 z-10 bg-black/70' />}
              <ImageWithPlaceholder
                className='relative block h-20 w-20 object-cover'
                src={image}
                alt={`${title} image ${i + 1}`}
              />
            </button>
          ))}
        </div>
      )}
    >
      {list.map((image, i) => (
        <div className='aspect-1 mr-1 shrink-0 basis-full' key={image + i}>
          <figure className='h-full w-full'>
            <ImageWithPlaceholder
              className='h-full w-full overflow-hidden rounded-lg object-cover'
              src={image}
              alt={`${title} image`}
            />
          </figure>
        </div>
      ))}
    </Carousel>
  );
};

export default GalleryWithThumbs;
