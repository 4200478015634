import { ContentfulEvent } from '@wbk/contentful/api';
import { BellIcon, ClockIcon, ThunderIcon, UpRightIcon } from '@wbk/svg';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type Props = {
  type: ContentfulEvent['eventType'];
  className?: string;
  mini?: boolean;
};

type TypeProps = {
  bar: {
    background: string;
    iconBackground: string;
    icon: string;
  };
  mini: {
    color: string;
  };
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
};

const VARIABLES: Record<Props['type'], TypeProps | null> = {
  internal: {
    bar: {
      background: '#bae50020',
      iconBackground: '#bae500',
      icon: '#000000',
    },
    mini: {
      color: '#bae500',
    },
    Icon: ThunderIcon,
  },
  external: {
    bar: {
      background: '#34343460',
      iconBackground: '#343434',
      icon: '#E4E4E7',
    },
    mini: {
      color: '#E4E4E7',
    },
    Icon: UpRightIcon,
  },
  '24_hours_confirmation': {
    bar: {
      background: '#8653ff20',
      iconBackground: '#8653ff',
      icon: '#ffffff',
    },
    mini: {
      color: '#8653ff',
    },
    Icon: ClockIcon,
  },
  offline: {
    bar: {
      background: '#701e1e50',
      iconBackground: '#701e1e',
      icon: '#ffffff',
    },
    mini: {
      color: '#701e1e',
    },
    Icon: BellIcon,
  },
  offline_experience: {
    bar: {
      background: '#701e1e50',
      iconBackground: '#701e1e',
      icon: '#ffffff',
    },
    mini: {
      color: '#701e1e',
    },
    Icon: BellIcon,
  },
  online_experience: null,
};

const EventBookingTypeBadge = ({ type, mini, className }: Props) => {
  const { t } = useTranslation(['event']);

  const vars = VARIABLES[type];

  if (!vars) {
    return null;
  }

  if (mini) {
    const miniText = t(`event:${type}_mini`);
    const text = miniText === `${type}_mini` ? t(`event:${type}`) : miniText;
    return (
      <div className={twMerge('flex items-center gap-1 rounded-md text-xs', className)}>
        <div style={{ color: vars.mini.color }}>
          <vars.Icon className='h-4 w-4' />
        </div>
        <p className='leading-none text-gray-400'>{text}</p>
      </div>
    );
  }

  return (
    <div
      className={twMerge('flex items-start gap-3 rounded-md p-4', className)}
      style={{ background: vars.bar.background }}
    >
      <div
        className='mt-px rounded-md p-1 text-white'
        style={{ background: vars.bar.iconBackground, color: vars.bar.icon }}
      >
        <vars.Icon className='h-8 w-8' />
      </div>
      <div>
        <p className='font-semibold'>{t(`event:${type}`)}</p>
        <p className='text-text text-sm'>{t(`event:${type}_desc`)}</p>
      </div>
    </div>
  );
};

export default EventBookingTypeBadge;
