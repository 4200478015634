import { useTranslation } from 'react-i18next';
import AppsDownload from '../app/AppsDownload';

const Download = () => {
  const { t } = useTranslation(['about']);

  return (
    <section className='relative mb-60 w-full bg-[url("/images/about/super_app.jpg")] bg-cover bg-center bg-no-repeat py-20'>
      <div className='container flex flex-col items-center justify-center space-y-8 text-center'>
        <div>
          <p className='text-base uppercase'>{t('about:all_new_webook')}</p>
          <h2 className='text-4xl md:text-5xl'>{t('about:super_app')}</h2>
        </div>

        <p className='text-2xl font-semibold md:text-3xl'>
          {t('about:one_app_endless_possibilities')}
        </p>

        <AppsDownload />

        <div>
          <img
            width={320}
            height={658}
            alt='webook mobile app'
            src='/images/about/wbk_app.png'
            className='-mb-[300px]'
          />
        </div>
      </div>
    </section>
  );
};

export default Download;
