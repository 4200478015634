import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import FilterTypeIcon from '@/components/explore/FilterTypeIcon';
import useSearch from '@/context/search/useSearch';

const EmptySearchResult = () => {
  const { t } = useTranslation('common');
  const { lang } = useParams<{ lang: Language }>();
  const { togglePopover } = useSearch();

  return (
    <>
      <div className='mx-auto max-w-lg space-y-4 p-4 text-center'>
        <img className='mx-auto h-20 w-20' src='/icons/common/empty-search.svg' alt='' />
        <p>{t('common:empty_search')}</p>
      </div>

      <div className='space-y-2'>
        <p className='text-sm text-gray-400'>{t('common:explore_things')}</p>
        <ul className='flex flex-wrap gap-2'>
          {['events', 'experiences', 'restaurants', 'shows', 'streams', 'conferences'].map(
            (type) => (
              <li key={type}>
                <Button
                  data-testid={`header_search_other_options_${type}`}
                  as={Link}
                  to={`/${lang}/explore?type=${type}`}
                  onClick={() => togglePopover(false)}
                  theme='primary'
                  className='bg-body-lighter hover:bg-primary/30 focus:bg-primary/30 active:text-text shrink-0 px-2.5 py-1 text-sm text-gray-400 shadow-md'
                  phill={false}
                  rounded
                >
                  <FilterTypeIcon type={type as ExploreTypes} />
                  <span>{t(type)}</span>
                </Button>
              </li>
            )
          )}
        </ul>
      </div>
    </>
  );
};

export default EmptySearchResult;
