import { Button } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SearchContent from '@/components/search/SearchContent';
import useSearch from '@/context/search/useSearch';

const MobileSearchContent = ({ close }: { close: () => void }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { lang } = useParams<{ lang: Language }>();
  const { searchState, onSearch } = useSearch();

  return (
    <>
      <header className='sticky top-0 flex flex-row-reverse items-center gap-2 px-4 py-2 shadow-sm'>
        <form
          className='relative grow'
          onSubmit={(event) => {
            event.preventDefault();
            if (searchState?.trim()) {
              navigate(`/${lang}/search?q=${searchState}`);
              close();
            }
          }}
        >
          <img
            src='/icons/common/search.svg'
            className='absolute top-1/2 -translate-y-1/2 transform ltr:left-2 rtl:right-2'
            width={24}
            height={24}
            alt=''
          />
          <input
            data-testid='header_mobile_search_input'
            type='search'
            inputMode='search'
            className='bg-body h-9 w-full rounded-md transition-all duration-500 focus:w-full ltr:pl-9 rtl:pr-9'
            placeholder={t('common:search_placeholder')}
            value={searchState || ''}
            onChange={(e) => onSearch(e.target.value)}
          />
        </form>
        <Button
          data-testid='header_mobile_close_search_button'
          shape='text'
          theme='white'
          className='p-1 ltr:rotate-180'
          onClick={close}
          aria-label='Search'
        >
          <img src='/icons/common/arrow.svg' width={24} height={24} alt='Search' />
        </Button>
      </header>

      <div className='mb-20 max-h-[calc(100vh-60px)] space-y-4 overflow-y-auto p-4 pb-24'>
        <SearchContent />
      </div>
    </>
  );
};

export default MobileSearchContent;
