import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@wbk/ui';
import FilterTypeIcon from '@/components/explore/FilterTypeIcon';

type Props = {
  type: ExploreTypes;
  selected: string[];
  render?: 'button' | 'checkbox';
  onSelect: () => void;
};

const FilterType = ({ type, selected, onSelect, render = 'button' }: Props) => {
  const { t } = useTranslation('common');

  if (render === 'checkbox') {
    return (
      <Checkbox
        groupClassName='[&>label]:flex-row-reverse w-full justify-between'
        className='h-5 w-5'
        onChange={onSelect}
        checked={selected.includes(type)}
        label={
          <div className='flex grow items-center gap-2'>
            <FilterTypeIcon type={type} className='h-5 w-5' />
            <p className='text-[15px]'>{t(type)}</p>
          </div>
        }
      />
    );
  }

  return (
    <Button
      theme='primary'
      onClick={onSelect}
      className={`border-primary bg-body-light hover:bg-primary/30 focus:bg-primary/30 active:bg-body active:text-text shrink-0 border-2 px-2.5 py-1 text-sm text-gray-100 shadow-md ${selected.includes(type) ? 'bg-primary/30 text-primary' : 'border-transparent'}`}
      phill={false}
      rounded
    >
      <FilterTypeIcon type={type} />
      <span>{t(type)}</span>
    </Button>
  );
};

export default FilterType;
