import { createContext } from 'react';
import { ContentfulEvent, WebZonePages } from '@wbk/contentful/api';

export type SearchStateProps = {
  search?: string;
  searchState?: string;
  searchHistory: string[];
  showPopover: boolean;
};

type SearchContextProps = {
  events?: ContentfulEvent[];
  experiences?: ContentfulEvent[];
  shows?: ContentfulEvent[];
  restaurants?: ContentfulEvent[];
  allEventsMerged: ContentfulEvent[];
  onSearch: (value: string) => void;
  onHistoryClear: () => void;
  togglePopover: (state: boolean) => void;
  isLoading: boolean;
  hasResult: boolean;
  hasZoneResult: boolean;
  fetchedDone: boolean;
  zoneLoading: boolean;
  zones?: WebZonePages[];
};

const SearchContext = createContext<SearchStateProps & SearchContextProps>({
  allEventsMerged: [],
  searchHistory: [],
  onSearch: () => {},
  onHistoryClear: () => {},
  showPopover: false,
  togglePopover: () => {},
  isLoading: false,
  fetchedDone: false,
  hasResult: false,
  hasZoneResult: false,
  zoneLoading: false,
});

export default SearchContext;
