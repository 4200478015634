import { Experience } from '@wbk/contentful/api';
import { useWebsiteLocale } from '@wbk/hooks';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@wbk/svg';
import StaticCheck from '../svg/StaticCheck';
import CollapsableText from '../text/CollapsableText';

type Props = {
  experience: Experience;
  type: 'included' | 'excluded' | 'guest_requirements';
};

const ExperienceListItems = ({ experience, type }: Props) => {
  const { shortLang } = useWebsiteLocale();
  const { t } = useTranslation(['event']);

  const items = experience.hyProperties?.[type];

  if (!items?.length) return null;

  return (
    <div className='border-b border-white/20 py-6'>
      <h2 className='mb-4 text-xl'>{t(`event:${type}`)}</h2>
      <CollapsableText>
        <ul className='space-y-2'>
          {items.map((item, index) => {
            return (
              <li key={index} className='inline-flex w-full items-center gap-2'>
                {type === 'excluded' ? (
                  <CloseIcon className='text-error mt-0.5 h-4 w-4 shrink-0' />
                ) : (
                  <StaticCheck className='text-success mt-0.5 h-5 w-5 shrink-0' />
                )}
                {item[shortLang]}
              </li>
            );
          })}
        </ul>
      </CollapsableText>
    </div>
  );
};

export default ExperienceListItems;
