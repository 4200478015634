import React from 'react';
import { RouteObject } from 'react-router-dom';
import HouseOfHypePage from '@/pages/experiences/house-of-hype';
import Error404 from '@/components/error/404';
import SaudiCupPage from '@/pages/events/saudi-cup';

const AhliFcPage = React.lazy(() => import('@/pages/events/ahli-fc'));
const EWCPage = React.lazy(() => import('@/pages/events/ewc'));
const WTAPage = React.lazy(() => import('@/pages/events/wta'));
const WTADetailPage = React.lazy(() => import('@/pages/events/wta-detail'));
const IttihadPage = React.lazy(() => import('@/pages/events/ittihad'));
const PageBySlug = React.lazy(() => import('@/pages/[slug]'));

export const customPagesRoutes: RouteObject[] = [
  {
    path: 'experiences/house-of-hype',
    element: <HouseOfHypePage />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/ahli-fc',
    element: <AhliFcPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'ittihad',
    element: <IttihadPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/esports-world-cup',
    element: <EWCPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/wta-finals-tickets',
    element: <WTAPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/wta-finals-tickets-697196',
    element: <WTADetailPage />,
    errorElement: <Error404 />,
  },
  {
    path: 'events/saudi-cup',
    element: <SaudiCupPage />,
    errorElement: <Error404 />,
  },
  // Must be last
  {
    path: ':slug',
    element: <PageBySlug />,
    errorElement: <Error404 />,
  },
];
