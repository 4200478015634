import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';
import { ContentfulEvent } from '@wbk/contentful/api';
import { useDeviceDetect, useWindowScroll } from '@wbk/hooks';
import { useQueue } from '@wbk/queue';
import BuyTicketButton from '@/components/buttons/BuyButton';
import { NotifyButton } from '../buttons/NotifyButton';

type Props = {
  event: ContentfulEvent;
  className?: string;
  offset?: number;
} & React.ComponentProps<typeof BuyTicketButton>;

const MobileStickyBuy = ({ event, className, offset = 500, ...props }: Props) => {
  const { isActive, shouldWaitQueue } = useQueue();
  const { y } = useWindowScroll();
  const { isWebView } = useDeviceDetect();

  if (isActive || shouldWaitQueue) return null;

  return (
    <>
      <style>{`
        @media (max-width: 1023px) {
          footer {margin-bottom:100px;}
        }
      `}</style>

      <motion.div
        initial={{ y: 200 }}
        animate={{ y: y > offset ? 0 : 200 }}
        transition={{ type: 'tween' }}
        className={`bg-body-light fixed bottom-0 left-0 z-30 flex min-h-[70px] w-full items-start border-t border-white/10 lg:hidden [&>div]:w-full [&>div]:justify-center ${isWebView ? 'px-4 pb-6 pt-2' : 'safe-bottom px-4 py-2'}`}
        data-testid='buy-tickets-cta-mobile'
      >
        {event.eventType?.includes('offline') ? (
          <NotifyButton
            event={event}
            className='py-2 text-base'
            type={event.__typename === 'Experience' ? 'experience' : 'event'}
          />
        ) : (
          <BuyTicketButton
            event={event}
            containerClassName='max-w-lg mx-auto !justify-between bg-transparent p-0 pb-1'
            className={twMerge('py-2 text-base', className)}
            {...props}
          />
        )}
      </motion.div>
    </>
  );
};

export default MobileStickyBuy;
