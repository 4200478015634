// import { SortFilter } from '@/components/explore/SortFilter';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { cleanSearchParam } from '@wbk/utils';
import { useAuth } from '@wbk/auth';
import FilterTypes from '@/components/explore/FilterTypes';
import ZoneFilters from '@/components/zone/ZoneFilters';
import DateFilters from '@/components/explore/DateFilters';
import PriceFilters from '@/components/explore/PriceFilters';
// import CategoryFilters from './CategoryFilters';

interface Props {
  category?: string;
}

const ExploreTopFilters = ({ category }: Props) => {
  const { isTawakkalnaUser } = useAuth();
  const { t } = useTranslation('common');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleClearAll = () => {
    navigate(pathname, { replace: true });
  };

  const notZonePage = !pathname.includes('/zones/');

  return (
    <div
      id='explore-top-filters'
      className={`sticky z-30 -mx-4 flex items-start justify-between px-4 py-2 backdrop-blur-xl md:top-16 md:mx-0 md:px-0 lg:py-0.5 ${isTawakkalnaUser ? 'top-0' : 'top-12'}`}
    >
      <div className='flex gap-2 overflow-x-auto px-1 pb-2 pt-1 lg:px-px'>
        {!category && <FilterTypes />}
        {notZonePage && <ZoneFilters render='mobile' category={category} />}
        <DateFilters render='mobile' />
        {/* <CategoryFilters render='mobile' /> */}
        <PriceFilters render='mobile' />
        {cleanSearchParam(searchParams).size > 1 && (
          <Button
            shape='text'
            className='-mt-1 shrink-0 self-center px-2.5 py-1 text-sm'
            onClick={handleClearAll}
          >
            {t('common:clear_all')}
          </Button>
        )}
      </div>
      {/* <SortFilter /> */}
    </div>
  );
};

export default ExploreTopFilters;
