import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ContentfulEvent, useGetExperiences } from '@wbk/contentful/api';
import EventsGridCarousel from '../events/EventsGridCarousel';

type Props = {
  event: ContentfulEvent;
};

const SimilarExperiences = ({ event }: Props) => {
  const { t } = useTranslation('event');
  const { lang } = useParams<{ lang: Language }>();
  const zoneSlug = event.zone?.slug;
  const { data: similars } = useGetExperiences({
    lang,
    limit: 5,
    where: { slug_not: event.slug, zone: { slug: zoneSlug } },
  });

  if (!similars?.items?.length) return null;

  return (
    <div className='space-y-4 border-t border-white/20 py-12 [&>section]:py-0'>
      <h2 className='container text-2xl font-semibold'>{t('event:you_might_also_like')}</h2>
      <EventsGridCarousel
        section={{
          categoriesCollection: {
            items: [],
          },
          contentCollection: {
            items: similars.items as ({ __typename: 'Experience' } & ContentfulEvent)[],
          },
          title: '',
          id: 'similar-events',
          webLinksCollection: {
            items: [],
          },
        }}
      />
    </div>
  );
};

export default SimilarExperiences;
