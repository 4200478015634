import { useParams } from 'react-router-dom';
import { parseSectionUtm } from '@wbk/utils';
import { Button, Carousel } from '@wbk/ui';
import ContentfulImage from '../images/ContentfulImage';
import type { WebCard, WebSection } from '@wbk/contentful/api';

type Props = {
  section: WebSection;
};

const SocialCardsCarousel = ({ section }: Props) => {
  const { lang } = useParams();
  const cards = section.contentCollection.items as WebCard[];

  if (!cards?.length) return null;

  return (
    <section
      data-testid={`home_social_cards_carousel_${section.id}`}
      className='container space-y-8 py-12'
    >
      <h2 className='text-2xl font-semibold text-gray-100'>{section.title}</h2>
      <Carousel locale={lang} options={{ dragFree: true }}>
        {cards.map((card, idx) => {
          const utm = parseSectionUtm({ utm: section.utm, idx, id: card.id });
          return (
            <div
              className='flex max-w-sm shrink-0 basis-[80%] px-2 md:basis-1/2 xl:basis-1/4'
              key={idx}
            >
              <div className='relative w-full overflow-hidden rounded-lg p-3'>
                <div className='relative z-[1] mb-3 flex w-full items-start gap-4'>
                  <p className='grow text-sm font-semibold text-black'>{card.title}</p>

                  <ContentfulImage
                    src={card.icon?.url}
                    width={40}
                    height={40}
                    alt={card.title}
                    format={null}
                    className='shrink-0 object-contain'
                  />
                </div>

                <Button
                  className='ring-offset-body-white relative z-[1] w-full'
                  theme='black'
                  data-testid={`social_cards_button_${card.id}`}
                  as='a'
                  href={`${card.href}${utm}`}
                  target='_blank'
                >
                  {card.buttonLabel}
                </Button>

                <ContentfulImage
                  src={card.image.url}
                  alt=''
                  className='absolute inset-0 w-full object-cover'
                />
              </div>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
};

export default SocialCardsCarousel;
