import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { LogoutModal, useAuth } from '@wbk/auth';

type Props = {
  open: boolean;
  onClose: () => void;
};
const SideUserMenu = ({ open, onClose }: Props) => {
  const { lang } = useParams<{ lang: Language }>();
  const { pathname } = useLocation();
  const { t } = useTranslation(['common', 'profile']);
  const { user, loading } = useAuth({ autoFetch: open });

  return (
    <div className='space-y-4 rounded-md pb-4'>
      {loading ? (
        <div className='space-y-1'>
          <div className='flex items-center gap-4'>
            <div className='bg-body-lighter flex h-12 grow animate-pulse items-center rounded-t-lg'>
              <div className='bg-body-light mx-2 h-10 w-10 animate-pulse rounded-full'></div>
              <div className='bg-body-light h-8 w-3/4 animate-pulse rounded-lg'></div>
            </div>
          </div>
          <div className='bg-body-lighter h-11 grow animate-pulse'></div>
          <div className='bg-body-lighter h-11 grow animate-pulse rounded-b-lg'></div>
        </div>
      ) : !user ? (
        <div className='mx-auto max-w-sm py-4'>
          <Button
            data-testid='header_mobile_side_menu_login_button'
            as={Link}
            to={`/${lang}/login?redirect=${pathname}`}
            onClick={onClose}
            data-location='header'
            className='w-full !px-1.5 !py-1.5 md:!px-2.5'
          >
            <p className='shrink-0'>{t('common:auth.login_signup')}</p>
          </Button>
        </div>
      ) : (
        <>
          <ul className='space-y-0.5'>
            <li>
              <Link
                data-testid='header_mobile_side_menu_link_/profile'
                to={`/${lang}/profile`}
                className='flex w-full items-center justify-between rounded-t-lg bg-white/5 p-2 hover:opacity-80'
                data-menuitem='profile'
                data-location='user-menu'
                onClick={onClose}
              >
                <div className='flex w-full items-center gap-4'>
                  <div className='bg-primary relative flex h-10 w-10 shrink-0 items-center justify-center rounded-full text-lg font-semibold uppercase text-white sm:mb-0'>
                    {`${user.first_name?.[0]}${user.last_name?.[0]}`}
                  </div>
                  <div className='text-lg font-semibold'>
                    <p className='line-clamp-1'>{user.name}</p>
                    <p className='line-clamp-1 text-xs font-normal text-gray-500'>{user.email}</p>
                  </div>
                </div>

                <img
                  className='shrink-0 opacity-50 rtl:rotate-180'
                  src='/icons/common/arrow.svg'
                  width={25}
                  height={25}
                  alt=''
                />
              </Link>
            </li>
            <li>
              <Link
                data-testid='header_mobile_side_menu_link_/profile/bookings'
                to={`/${lang}/profile/bookings`}
                className='flex w-full items-center justify-between bg-white/5 px-2 py-3 hover:opacity-80'
                data-menuitem='my_tickets'
                data-location='user-menu'
                onClick={onClose}
              >
                <div className='flex items-center gap-3 px-1'>
                  <img
                    className='shrink-0'
                    src='/icons/profile/tickets.svg'
                    width={25}
                    height={25}
                    alt=''
                  />
                  {t('profile:my_bookings')}
                </div>

                <img
                  className='shrink-0 opacity-50 rtl:rotate-180'
                  src='/icons/common/arrow.svg'
                  width={25}
                  height={25}
                  alt=''
                />
              </Link>
            </li>

            <li>
              <LogoutModal
                onClosed={onClose}
                render={({ open }) => (
                  <button
                    data-testid='header_mobile_side_menu_link_logout'
                    onClick={() => open()}
                    className='flex w-full items-center justify-between rounded-b-lg bg-white/5 px-2 py-3 hover:opacity-80'
                    data-menuitem='logout'
                    data-location='user-menu'
                  >
                    <div className='flex items-center gap-3 px-1 text-red-400'>
                      <img
                        className='shrink-0 ltr:rotate-180'
                        src='/icons/profile/logout.svg'
                        width={25}
                        height={25}
                        alt=''
                      />
                      <p>{t('common:nav.logout')}</p>
                    </div>
                  </button>
                )}
              />
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default SideUserMenu;
