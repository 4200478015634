import { ContentfulLocation } from '@wbk/contentful/api';
import { useWebsiteLocale } from '@wbk/hooks';
import { twMerge } from 'tailwind-merge';
import ErrorBoundary from '@/components/error/ErrorBoundary';
import LocationIcon from '@/components/svg/Location';

type Props = {
  location?: ContentfulLocation;
  showTitle?: boolean;
  icon?: boolean;
  className?: string;
};

const BaseEventLocation = ({ location, showTitle = false, icon = false, className }: Props) => {
  const { shortLang } = useWebsiteLocale();

  if (!location) return null;

  const regionNames = new Intl.DisplayNames([shortLang], { type: 'region' });
  const withTitle = location.title && showTitle;
  const title = location.title ? location.title : '';
  const city = location.city ? location.city : '';
  const country = location.countryCode ? regionNames.of(location.countryCode) : '';

  let text = '';

  if (withTitle) {
    text += title;
  }

  if (withTitle && city) {
    text += ', ';
  }

  if (city) {
    text += city;
  }

  if (city && country) {
    text += ', ';
  }

  if (country) {
    text += country;
  }

  if (!text) {
    text = title || '';
  }

  if (!text) {
    return null;
  }

  return (
    <div className='flex items-start gap-1 text-sm'>
      {icon && <LocationIcon />}
      <p className={twMerge('line-clamp-1 text-sm', className)}>{text}</p>
    </div>
  );
};

const EventLocation = ({ location, showTitle, icon, className }: Props) => {
  return (
    <ErrorBoundary
      fallback={<p className={twMerge('line-clamp-1 text-sm', className)}>{location?.title}</p>}
    >
      <BaseEventLocation
        location={location}
        showTitle={showTitle}
        icon={icon}
        className={className}
      />
    </ErrorBoundary>
  );
};

export default EventLocation;
