import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Popover } from '@wbk/ui';
import { useWindowScroll } from '@wbk/hooks';
import useSearch from '@/context/search/useSearch';
import SearchContent from '@/components/search/SearchContent';

const DesktopSearch = () => {
  const { t } = useTranslation('common');
  const { y } = useWindowScroll();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { lang } = useParams<{ lang: Language }>();
  const { searchState, onSearch, togglePopover, showPopover } = useSearch();
  const isHome = pathname?.replace(lang || '', '') === '/';
  const show = !isHome || y > 150;

  return (
    <div className='hidden w-full max-w-md md:block'>
      <AnimatePresence key={pathname} initial={false}>
        {show && (
          <Popover
            sideOffset={8}
            align='start'
            className='mini-scrollbar mini-scrollbar-rounded bg-body-light min-h-[160px] w-screen max-w-md space-y-4 overflow-y-auto rounded-lg p-3'
            content={<SearchContent />}
          >
            <motion.form
              className='relative overflow-hidden p-0.5'
              initial={{ width: 0 }}
              animate={{ width: 'auto' }}
              exit={{ width: 0 }}
              onSubmit={(event) => {
                event.preventDefault();
                if (searchState?.trim()) {
                  togglePopover(false);
                  navigate(`/${lang}/search?q=${searchState?.trim()}`);
                }
              }}
            >
              <img
                src='/icons/common/search.svg'
                className='absolute top-1/2 -translate-y-1/2 transform opacity-60 ltr:left-2 rtl:right-2'
                width={24}
                height={24}
                alt=''
              />
              <input
                data-testid='header_nav_search_input'
                type='search'
                inputMode='search'
                className='focus:ring-primary focus:border-primary bg-input/10 h-9 w-3/4 rounded-md border-transparent text-sm transition-all duration-500 focus:w-full ltr:pl-9 rtl:pr-9'
                placeholder={t('common:search_placeholder')}
                value={searchState || ''}
                onChange={(e) => {
                  if (!showPopover) {
                    togglePopover(true);
                  }
                  onSearch(e.target.value);
                }}
                onFocus={() => {
                  togglePopover(true);
                }}
                onBlur={() => {
                  togglePopover(false);
                }}
              />
            </motion.form>
          </Popover>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DesktopSearch;
