import { Fragment, useMemo, useState } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '@wbk/analytics';
import { Button, ButtonShape, Modal } from '@wbk/ui';
import { useQueue } from '@wbk/queue';

type ShareModalProps = {
  label?: string;
  share_title: string;
  share_description: string;
  render?: (props: { share(): void }) => JSX.Element;
  buttonProps?: React.ComponentProps<typeof Button>;
  className?: string;
  shape?: ButtonShape;
};

const ShareModal = ({
  label,
  share_title,
  share_description,
  className,
  buttonProps,
  shape = 'text',
  render,
}: ShareModalProps) => {
  const [sharing, setShare] = useState(false);
  const { t } = useTranslation('common');
  const { isActive, shouldWaitQueue } = useQueue();
  const { shareEvent } = useAnalytics();

  const share = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: window.location.href,
          title: share_title,
          text: share_description,
        });
      } catch (error) {
        //
      }
    } else {
      setShare(true);
    }
  };

  const shareUrl = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.location.href;
    }
    return '';
  }, []);

  if (isActive || shouldWaitQueue) return null;

  return (
    <Fragment>
      {render ? (
        render({ share })
      ) : (
        <Button
          onClick={share}
          shape={shape}
          theme='white'
          className={`share-action flex shrink-0 items-center justify-center gap-2 px-6 ${className}`}
          {...buttonProps}
        >
          <img
            alt={`${t('common:share')} ${share_title}`}
            src='/icons/common/share.svg'
            width={21}
            height={22}
          />
          <span>{label || t('common:share')}</span>
        </Button>
      )}

      <Modal
        title={t('common:share')}
        description={share_description}
        className='p-4 px-2 text-center md:px-4'
        show={sharing}
        onClose={() => setShare(false)}
        body={({ close }) => (
          <div className='space-y-6'>
            <div>
              <h2>{t('common:share')}</h2>
              <p>{share_title}</p>
            </div>
            <div className='flex justify-around'>
              <WhatsappShareButton
                className='w-12 overflow-hidden rounded-md'
                url={shareUrl}
                title={share_title}
                onClick={() => shareEvent({ method: 'Whatsapp', id: share_title })}
              >
                <WhatsappIcon size={48} />
              </WhatsappShareButton>
              <TwitterShareButton
                className='w-12 overflow-hidden rounded-md'
                url={shareUrl}
                title={share_title}
                onClick={() => shareEvent({ method: 'Twitter', id: share_title })}
              >
                <div className='flex h-12 w-12 items-center justify-center bg-white p-2'>
                  <svg className='h-8 w-8' viewBox='0 0 24 24' fill='black' aria-hidden='true'>
                    <path d='M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z'></path>
                  </svg>
                </div>
              </TwitterShareButton>
              <LinkedinShareButton
                className='w-12 overflow-hidden rounded-md'
                url={shareUrl}
                title={share_title}
                summary={share_description}
                onClick={() => shareEvent({ method: 'LinkedIn', id: share_title })}
              >
                <LinkedinIcon size={48} />
              </LinkedinShareButton>
              <FacebookShareButton
                className='w-12 overflow-hidden rounded-md'
                url={shareUrl}
                quote={share_description}
                onClick={() => shareEvent({ method: 'Facebook', id: share_title })}
              >
                <FacebookIcon size={48} />
              </FacebookShareButton>
              <EmailShareButton
                className='w-12 overflow-hidden rounded-md'
                url={shareUrl}
                subject={share_title}
                body={share_description}
                onClick={(_, link) => {
                  // https://github.com/nygardk/react-share/issues/419
                  window.location.href = link;
                  shareEvent({ method: 'Email', id: share_title });
                }}
              >
                <EmailIcon size={48} />
              </EmailShareButton>
            </div>
            <Button onClick={close} className='w-[140px] py-1' theme='white' shape='outlined'>
              {t('common:cancel')}
            </Button>
          </div>
        )}
      />
    </Fragment>
  );
};

export default ShareModal;
