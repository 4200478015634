import { twMerge } from 'tailwind-merge';
import { QAList } from '@wbk/contentful/api';
import CollapsableText from '../text/CollapsableText';
import ContentfulImage from '../images/ContentfulImage';
import ContentfulRichText from '../text/ContentfulRichText';

type Props = {
  data: QAList | undefined;
  additionalItems?: React.ReactNode;
  className?: string;
  iconsListClassName?: string;
};

const IconQAList = ({ data, additionalItems, className, iconsListClassName }: Props) => {
  if (!data) return null;

  return (
    <div
      className={twMerge('space-y-2 border-b border-white/20 py-4', className)}
      data-testid='event-description'
    >
      <h2 className='text-xl font-semibold md:text-2xl'>{data.title}</h2>

      {data.descriptionPosition === 'top' && (
        <CollapsableText
          minimum={4}
          text={data.description}
          btnProps={{ 'aria-label': `Read More About ${data.title}` }}
          markdown={typeof data.description === 'string'}
          className='text-text text-sm'
        />
      )}

      <div
        className={twMerge(
          'text-sm',
          data.renderType === 'horizontal'
            ? 'flex flex-row flex-wrap gap-4'
            : 'flex flex-col gap-4',
          iconsListClassName
        )}
      >
        {data.qaListCollection.items.map((item, index) => (
          <ListItem item={item} labelAlignment={data.labelAlignment} key={index} />
        ))}

        {additionalItems}
      </div>

      {data.descriptionPosition === 'bottom' && (
        <CollapsableText
          minimum={4}
          text={data.description}
          btnProps={{ 'aria-label': `Read More About ${data.title}` }}
          markdown={typeof data.description === 'string'}
          className='text-text text-sm'
        />
      )}
    </div>
  );
};

type ListItemProps = {
  item: QAList['qaListCollection']['items'][number];
  labelAlignment: QAList['labelAlignment'];
};

const ListItem = ({ item, labelAlignment }: ListItemProps) => {
  return (
    <div
      className={twMerge(
        'qa-item flex gap-4',
        labelAlignment === 'horizontal' ? 'flex-row items-start' : 'flex-col items-center',
        labelAlignment === 'vertical' && 'py-3'
      )}
    >
      <div
        className={twMerge(
          'shrink-0',
          labelAlignment === 'horizontal' &&
            'bg-body-light grid h-12 w-12 place-items-center rounded-full',
          labelAlignment === 'vertical' && 'flex h-8 w-8 items-center justify-center'
        )}
      >
        <ContentfulImage
          className='shrink-0 object-contain'
          src={item?.icon?.url}
          width={item?.icon?.width || 30}
          height={item?.icon?.height || 30}
          alt=''
          fit='pad'
        />
      </div>

      <div className='space-y-1'>
        <h4 className='qa-item-title text-[1em] text-white'>{item.title}</h4>
        {item.description && (
          <div className='qa-item-description text-[1em] text-gray-400'>
            <ContentfulRichText description={item.description} />
          </div>
        )}
      </div>
    </div>
  );
};

export default IconQAList;
