import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Button } from '@wbk/ui';
import ResellTopHand from '../svg/ResellTopHand';
import ResellBottomHand from '../svg/ResellBottomHand';

type Props = {
  slug?: string;
  className?: string;
  show?: boolean;
  primary?: boolean;
  mini?: boolean;
};

const ResellBanner = ({ slug, show, primary, mini, className = '' }: Props) => {
  const { t } = useTranslation('event');
  const { lang } = useParams<{ lang: Language }>();

  if (!slug || !show) return null;

  if (mini) {
    return (
      <div className={twMerge('flex items-center gap-2', className)}>
        <motion.div
          className='flex overflow-hidden'
          initial='hidden'
          animate='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.7 }}
        >
          <ResellTopHand className='h-10 w-6' />
          <ResellBottomHand className='h-10 w-6' />
        </motion.div>
        <Button
          className='flex flex-col items-start px-1 py-0'
          as='a'
          href={`${process.env.VITE_PUBLIC_RESELL_DOMAIN}/${lang}/events/${slug}`}
          target='_blank'
          rel='noopener noreferrer'
          theme='white'
          shape='text'
          phill={false}
        >
          <p className='text-sm font-semibold'>{t('event:resell.title')}</p>
          <p className='flex items-center gap-1 text-xs'>
            {t('event:resell.see_other')}
            <img className='h-2 w-2 rtl:rotate-180' src='/icons/common/right-arrow.svg' alt='' />
          </p>
        </Button>
      </div>
    );
  }

  return (
    <motion.div
      className={twMerge(
        'mb-6 overflow-hidden rounded-lg bg-cover',
        primary ? 'bg-[#001847]' : 'border border-white/10',
        className
      )}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
    >
      <div className='flex flex-col items-center justify-between gap-4 space-y-5 p-4 md:flex-row md:space-y-0'>
        <div className='-my-4 flex flex-col items-center gap-2 md:flex-row'>
          <motion.div
            className='flex overflow-hidden'
            initial='hidden'
            animate='hidden'
            whileInView='visible'
            viewport={{ once: false, amount: 0.7 }}
          >
            <ResellTopHand />
            <ResellBottomHand />
          </motion.div>
          <div className='space-y-0.5 text-center md:text-start'>
            <p className='text-sm font-semibold'>{t('event:resell.title')}</p>
            <p className='text-xs'>{t('event:resell.see_other')}</p>
          </div>
        </div>

        <div className='flex flex-col items-center justify-center space-y-2'>
          <Button
            as='a'
            href={`${process.env.VITE_PUBLIC_RESELL_DOMAIN}/${lang}/events/${slug}`}
            target='_blank'
            rel='noopener noreferrer'
            theme='white'
            shape={primary ? 'solid' : 'outlined'}
            className='py-2 text-xs font-semibold'
          >
            resell.webook.com
            <img
              className='shrink-0 ltr:rotate-90'
              src={`/icons/common/external-arrow${primary ? '-black' : ''}.svg`}
              width={14}
              height={14}
              alt=''
            />
          </Button>
          <p className='inline-flex items-start gap-1 text-[10px]'>
            <img className='mt-0.5' src='/icons/common/shield.svg' width={12} height={12} alt='' />
            <span> {t('event:resell.guarentee')}</span>
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default ResellBanner;
