import { useParams } from 'react-router-dom';
import { useWebsiteConfig } from '@wbk/contentful/api';
import Header from '../layout/header';
import Error404 from './404';

const HeaderWith404 = () => {
  const { lang } = useParams<{ lang: Language }>();
  const { data, isLoading } = useWebsiteConfig({ lang, slug: 'webook-config' });
  return (
    <>
      {isLoading ? (
        <header className='border-body-lighter relative z-20 h-16 w-full border-b md:bg-black/50'></header>
      ) : (
        data && <Header config={data} type='main' />
      )}
      <Error404 />
    </>
  );
};

export default HeaderWith404;
