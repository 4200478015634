import { ComponentProps, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAnalytics } from '@wbk/analytics';
import { Button } from '@wbk/ui';
import { ContentfulEvent } from '@wbk/contentful/api';
import { getUser } from '@wbk/api';
import { formatPrice } from '@wbk/utils';
import { useQueue } from '@wbk/queue';
import ContentfulImage from '../images/ContentfulImage';
import FreeStreamButton from './FreeStreamButton';

type Props = {
  event: ContentfulEvent;
  className?: string;
  containerClassName?: string;
  href?: string;
  pricePosition?: 'inside' | 'outside';
  queueProtected?: boolean;
} & ComponentProps<typeof Button>;

const BuyTicketButton = ({
  event,
  className,
  containerClassName,
  href,
  pricePosition = 'outside',
  queueProtected,
  ...props
}: Props) => {
  const { t } = useTranslation(['common', 'event']);
  const { isActive, shouldWaitQueue } = useQueue();
  const { pathname } = useLocation();
  const cleanPath = pathname?.endsWith('/') ? pathname.slice(0, -1) : pathname;

  const isSeason = event.slug.includes('--season--');
  const updatedPath = isSeason ? cleanPath.replace(/\/events\/|\/event\//, '/seasons/') : cleanPath;

  const { generateLeadEvent } = useAnalytics();
  const discount = event.discountedPrice || 0;

  if (isActive || shouldWaitQueue) {
    return null;
  }

  if (event.isStreamingEvent && !event.startingPrice) {
    return <FreeStreamButton />;
  }

  if (event.eventType === 'external' && event.buttonLink) {
    return <ExternalEventButton event={event} className={className} />;
  }

  const shouldDisable = event.isComingSoon || !event.ticketingUrlSlug;
  const shouldHyDisable = event.eventType === 'external' && !event.buttonLink;

  if (shouldDisable || shouldHyDisable) {
    return event.subtitle ? (
      <div className='text-center md:text-start'>
        <p>{event.subtitle}</p>
      </div>
    ) : (
      <Button className='mx-auto w-full max-w-xs !bg-gray-700 lg:mx-0' disabled>
        {event?.buttonLabel || t('common:coming_soon')}
      </Button>
    );
  }

  return (
    <div
      className={twMerge(
        'flex items-center justify-between gap-8 rounded-md bg-white/5 px-3 py-4 sm:gap-6',
        containerClassName
      )}
    >
      {event.startingPrice > 0 && pricePosition === 'outside' && (
        <div className='shrink-0'>
          <div className='flex items-end gap-1'>
            <p>{t('event:starts_from')}</p>
            <div>
              {discount > 0 && (
                <p className='block text-xs leading-none text-white/60 line-through'>
                  {t('common:price', {
                    price: formatPrice(event.startingPrice),
                    currency: t(event.currencyCode),
                  })}
                </p>
              )}
              <p className='flex items-center gap-1'>
                <strong className='text-lg leading-none'>
                  {formatPrice(discount || event.startingPrice)}
                </strong>
                <span>{t(event.currencyCode || 'SAR')}</span>
              </p>
            </div>
          </div>
          <p className='text-[11px] text-gray-500'>{t('event:vat_included')}</p>
        </div>
      )}

      <Button
        queueProtected={queueProtected}
        as={Link}
        to={href || `${updatedPath}/book`}
        onClick={() => {
          generateLeadEvent({
            event_start_price: event.startingPrice,
            currency: event.currencyCode,
            title: event.title,
            slug: event.slug,
          });
        }}
        disabled={shouldWaitQueue || isActive}
        className={twMerge(
          'buy-ticket mx-auto min-h-[50px] w-full max-w-[260px] gap-2 text-lg font-bold capitalize leading-none lg:mx-0',
          className
        )}
        data-event-name={event.title}
        data-event-start-date={event.schedule?.openDateTime}
        data-category='main'
        {...props}
      >
        {event.startingPrice > 0 && pricePosition === 'inside' ? (
          <div>
            <span>{event.buttonLabel || t('event:buy_tickets')}</span>
            <p className='text-xs font-normal'>{`${t('event:from')} ${t('common:price', {
              price: formatPrice(discount || event.startingPrice),
              currency: t(event.currencyCode || 'SAR'),
            })}`}</p>
          </div>
        ) : (
          <span>{event.buttonLabel || t('event:buy_tickets')}</span>
        )}
        {event.buttonIcon?.url && (
          <ContentfulImage src={event.buttonIcon?.url} width={16} height={16} alt={event.title} />
        )}
      </Button>
    </div>
  );
};

const ExternalEventButton = ({ event, className }: Pick<Props, 'event' | 'className'>) => {
  const { t } = useTranslation(['common', 'event']);
  const { lang } = useParams<{ lang: Language }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const externalLinkRef = useRef<HTMLAnchorElement>(null);
  const { generateLeadEvent } = useAnalytics();

  const handleClick = async () => {
    setIsLoading(true);
    const user = await getUser({ lang });
    setIsLoading(false);

    if (!user) {
      navigate(`/${lang}/login?redirect=${encodeURIComponent(pathname)}`);
    } else {
      handleGenerateLead();

      if (event.buttonLink) {
        window.location.href = event.buttonLink || '';
      }
    }
  };

  const handleGenerateLead = () => {
    generateLeadEvent({
      event_start_price: event.startingPrice,
      currency: event.currencyCode,
      title: event.title,
      slug: event.slug,
    });
  };

  return (
    <>
      <Button
        className={twMerge('mx-auto w-full max-w-xs gap-2 lg:mx-0', className)}
        {...(event.authGaurd
          ? {
              onClick: handleClick,
              loading: isLoading,
              disabled: isLoading,
            }
          : {
              as: 'a',
              href: event.buttonLink,
              target: '_blank',
              rel: 'noopener noreferrer',
              onClick: handleGenerateLead,
            })}
      >
        <span>{event.buttonLabel || t('event:more_details')}</span>
        <img
          className={!event.buttonIcon?.url ? 'ltr:rotate-90' : ''}
          src={event.buttonIcon?.url || '/icons/common/external-arrow.svg'}
          width={16}
          height={16}
          alt={t('event:more_details').toUpperCase()}
        />
      </Button>

      {event.authGaurd && (
        <a
          href={event.buttonLink}
          target='_blank'
          rel='noopener noreferrer'
          className='hidden'
          ref={externalLinkRef}
        >
          {' '}
        </a>
      )}
    </>
  );
};

export default BuyTicketButton;
