import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDeviceDetect } from '@wbk/hooks';
import { useAuth } from '@wbk/auth';
import CircleSpinner from '@/components/ui/spinners/CircleSpinner';
import { getSafeRedirectRoute } from '@/util/redirect';

const AuthLayout = () => {
  const { lang } = useParams<{ lang: Language }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, isNewUser, loading } = useAuth({ autoFetch: true });
  const isValidUser = !!user && !isNewUser;
  const keepLoading = loading || isValidUser;
  const { isWebView } = useDeviceDetect();

  useEffect(() => {
    if (isValidUser) {
      if (searchParams.has('redirect')) {
        const dest = getSafeRedirectRoute(lang, searchParams.get('redirect'));
        if (dest.startsWith('http')) {
          window.location.href = dest;
        } else {
          navigate(dest, { replace: true });
        }
      } else if (pathname.includes('/login') || pathname.includes('/signup')) {
        navigate('/', { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [isValidUser]);

  return (
    <section
      data-webview={!!isWebView}
      className={
        'relative flex min-h-[95vh] items-center after:absolute after:inset-0 after:bg-black/80 data-[webview=false]:before:absolute data-[webview=false]:before:inset-0 data-[webview=false]:before:z-[-1] data-[webview=false]:before:bg-[url(/images/default-cover.jpg)] data-[webview=false]:before:bg-cover data-[webview=false]:before:bg-no-repeat'
      }
    >
      <div className='container relative z-10 mx-auto grid grid-cols-1 py-10 lg:grid-cols-2'>
        {!isWebView && (
          <div className='flex items-center pb-8'>
            <figure className='mx-auto w-full'>
              <img
                className='mx-auto h-full w-full max-w-[160px] lg:max-w-xs'
                src='/icons/logo.svg'
                alt=''
              />
            </figure>
          </div>
        )}

        <div className='mx-auto w-full max-w-lg'>
          {keepLoading ? (
            <div className='bg-body-light flex h-80 max-w-lg items-center justify-center overflow-hidden rounded-lg p-4 lg:max-w-full'>
              <CircleSpinner width={100} className='text-primary' />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </section>
  );
};

export default AuthLayout;
