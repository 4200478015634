import { useState } from 'react';
import { useSubscribe } from '@wbk/api/hooks';
import { Button, Input } from '@wbk/ui';
import { EMAIL_PATTERN } from '@wbk/utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnimatedCheck, CheckIcon } from '@wbk/svg';
import { useWebsiteLocale } from '@wbk/hooks';
import { NewsletterOptionProps } from '@wbk/api';
import { twMerge } from 'tailwind-merge';

type Props = NewsletterOptionProps & {
  title: string;
  subtitle?: string;
  className?: string;
  as?: React.ElementType;
  slug?: string;
};

const NewsLetterSubscribe = ({
  title,
  subtitle,
  type,
  interests = [],
  className,
  as,
  slug = '',
}: Props) => {
  const { t } = useTranslation('common');
  const { shortLang } = useWebsiteLocale();
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{ email: string }>();
  const { mutateAsync, isPending, error } = useSubscribe();
  const email = watch('email') || '';
  const Component = as || 'section';

  const onSubmit = async (data: { email: string }) => {
    try {
      await mutateAsync({
        email: data.email,
        type,
        interests,
        lang: shortLang,
        slug,
      });
      setSuccess(true);
    } catch (error) {
      //
    }
  };

  return (
    <Component
      data-testid='home_newsletter_subscribe'
      className={twMerge('container py-12', className)}
    >
      <div className='flex flex-col-reverse items-center justify-between gap-4 rounded-xl border border-white/30 lg:flex-row lg:items-end'>
        {success ? (
          <div className='flex flex-1 grow flex-col items-center space-y-1 p-4 text-center lg:items-start lg:px-8 lg:pb-12 lg:pt-8 lg:text-start'>
            <AnimatedCheck className='h-24 w-24' />
            <h2 className='text-2xl uppercase md:text-4xl'>{t('common:newsletter.success')}</h2>
            <p>{t('common:newsletter.success_desc')}</p>
          </div>
        ) : (
          <div className='flex-1 space-y-4 p-4 lg:px-8 lg:pb-12 lg:pt-10'>
            <h2 className='leading-tight text-gray-100'>{title}</h2>
            {subtitle && <p className='text-text'>{subtitle}</p>}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className='mt-4 flex flex-col items-start gap-2 sm:flex-row'
            >
              <fieldset className='w-full grow md:max-w-md'>
                <Input
                  className='placeholder:text-text disabled:text-text/20 h-10'
                  type='email'
                  placeholder={t('common:newsletter.email_placeholder')}
                  data-testid='home_newsletter_subscribe_input'
                  {...register('email', {
                    required: t('common:validation.required'),
                    pattern: {
                      value: EMAIL_PATTERN,
                      message: t('common:validation.invalid_email'),
                    },
                  })}
                  onChange={(value) => {
                    setValue('email', value);
                  }}
                  error={errors.email?.message || error?.message}
                  value={email}
                  disabled={success}
                  hideErrorSpace
                />
              </fieldset>

              <Button
                type='submit'
                theme='white'
                data-testid='home_newsletter_subscribe_button'
                className='h-10 w-full shrink-0 px-8 sm:w-auto'
                loading={isPending}
                disabled={success}
              >
                {t('common:newsletter.subscribe')}
              </Button>
            </form>
          </div>
        )}
        <div className='flex flex-1 gap-4 px-4 pt-4 lg:pt-2'>
          <img src='/images/illustration/writing_girl.svg' className='w-full max-w-[50%]' alt='' />
          <div className='w-full space-y-4 self-center md:pb-4'>
            {[1, 2, 3, 4, 5].map((idx) => (
              <div key={idx} className='flex items-start gap-1 text-xs sm:text-base'>
                <CheckIcon className='text-success [&>path]:fill-success-dark h-6 w-6 shrink-0 [&>rect]:hidden' />
                <p>{t(`common:newsletter.feature_${idx}`)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Component>
  );
};

export default NewsLetterSubscribe;
