import { ApiConfig } from '@wbk/api';

ApiConfig.init({
  wbk: {
    api: process.env.VITE_PUBLIC_TICKETS_API_BASE!,
    authApi: process.env.VITE_PUBLIC_AUTH_API_BASE!,
    apiToken: process.env.VITE_PUBLIC_TICKETS_API_TOKEN!,
    appSource: process.env.VITE_PUBLIC_APP_SOURCE!,
    paymentApi: process.env.VITE_PUBLIC_TICKETS_PAYMENT_API_BASE!,
    ticketingDomain: process.env.VITE_PUBLIC_BOOK_DOMAIN!,
    socketApi: process.env.VITE_PUBLIC_SOCKET_HTTP_URL!,
  },
  hy: {
    api: process.env.VITE_PUBLIC_HY_API_BASE_URL!,
    apiToken: process.env.VITE_PUBLIC_HY_API_TOKEN!,
  },
  blog: {
    api: process.env.VITE_PUBLIC_BOOK_DOMAIN!,
  },
  grecaptcha: {
    v3Key: process.env.VITE_PUBLIC_CAPTCHA_KEY!,
  },
  cookie: {
    domain: process.env.VITE_COOKIE_DOMAIN!,
  },
});
