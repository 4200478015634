import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { HideOnQueue } from '@wbk/queue';
import HideOnWebview from '../webview/HideOnWebview';

export type BreadcrumbItem = {
  name: string;
  href: string;
  disabled?: boolean;
  className?: string;
  hide?: boolean;
};

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb = ({ items }: BreadcrumbProps) => {
  return (
    <HideOnWebview>
      <HideOnQueue>
        <ul className='flex list-none flex-wrap items-center text-sm'>
          {items?.map(({ name, disabled, href, className, hide }, idx) => {
            if (hide) return null;
            return (
              <li
                key={idx}
                className={`flex items-center capitalize leading-none ${
                  className ? className : ''
                }`}
              >
                {disabled ? (
                  <span>{name}</span>
                ) : (
                  <Fragment>
                    <Link to={href}>
                      <span className='block'>{name}</span>
                    </Link>
                  </Fragment>
                )}

                {idx !== items.length - 1 ? (
                  <img
                    src='/icons/common/arrow.svg'
                    width={22}
                    height={22}
                    className='rtl:rotate-180'
                    alt={'breadcrumb seperator'}
                  />
                ) : null}
              </li>
            );
          })}
        </ul>
      </HideOnQueue>
    </HideOnWebview>
  );
};

export default Breadcrumb;
