import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import CollapsableText from '../text/CollapsableText';
import Title from './Title';

const Terms = ({ terms }: { terms?: string }) => {
  const { t } = useTranslation('saudi-cup');

  if (!terms) return null;

  return (
    <section className='container space-y-8 py-8'>
      <Title text={t('saudi-cup:terms')} />

      <motion.div
        initial='hidden'
        animate='hidden'
        whileInView='visible'
        variants={{
          visible: {
            transition: { staggerChildren: 0.07, delayChildren: 0.2 },
          },
          hidden: {
            transition: { staggerChildren: 0.05, staggerDirection: -1 },
          },
        }}
        viewport={{ once: false, amount: 0.2 }}
      >
        <motion.div
          variants={{
            hidden: { opacity: 0, y: 25 },
            visible: { opacity: 1, y: 0 },
          }}
          className='flex-1 text-justify'
        >
          <CollapsableText
            minimum={6}
            text={terms}
            markdown
            btnProps={{ 'aria-label': 'Read More About Terms', theme: 'black' }}
            className='w-full max-w-full text-base [&>.prose]:text-[#71717A]'
          />
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Terms;
