import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@wbk/ui';
import { useDeviceDetect } from '@wbk/hooks';
import AnimatedCross from '../svg/Cross';

const SubscribeFailed = () => {
  const { isWebView } = useDeviceDetect();
  const navigate = useNavigate();
  const { lang } = useParams<{ lang: Language }>();
  const [searchParams] = useSearchParams();
  const reason = searchParams.get('error')?.split(':')?.[1];

  const { t } = useTranslation(['common', 'booking']);

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ eventName: 'SUBSCRIBE_FAIL' }));
  }, []);

  return (
    <section data-webview={isWebView} className='data-[webview=true]:bg-body-light min-h-svh py-12'>
      <div className='container flex min-h-[600px] max-w-xl flex-col justify-between'>
        <div className='space-y-6'>
          <AnimatedCross className='mx-auto max-w-[160px]' />
          <p className='text-center text-xl font-semibold'>{t('booking:payment_failed')}</p>

          {!!reason && (
            <div className='text-text-light py-3 text-center'>
              <p className='text-red-500'>{`${reason}`}</p>
            </div>
          )}
        </div>

        <div className='mx-auto w-full max-w-xs space-y-4'>
          {isWebView ? (
            <Button
              className='w-full'
              theme='white'
              shape='outlined'
              onClick={() => {
                window.ReactNativeWebView?.postMessage(
                  JSON.stringify({ eventName: 'CLOSE_SUBSCRIBE' })
                );
              }}
            >
              {t('common:ok')}
            </Button>
          ) : (
            <Button
              className='w-full'
              theme='white'
              shape='outlined'
              onClick={() => {
                navigate(`/${lang}`);
              }}
            >
              {t('common:ok')}
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default SubscribeFailed;
