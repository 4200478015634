import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@wbk/ui';
import Gallery from './Gallery';
import type { ContentfulImage, Restaurant } from '@wbk/contentful/api';

type IProps = {
  data: Restaurant['menuCollection'] | undefined;
};

const MenuCollection = ({ data }: IProps) => {
  const { t } = useTranslation(['event']);
  const [collapse, setCollapse] = useState(false);

  const [menu, setMenu] = useState(-1);

  if (!data || !data.items?.length) return null;

  return (
    <div className='border-b border-white/20 py-6'>
      <div
        className={'bg-body-light w-full cursor-pointer rounded-lg px-4'}
        onPointerUp={() => setCollapse(!collapse)}
      >
        <div className='relative flex h-[62px] w-full items-center justify-between'>
          <div className='flex grow items-center gap-4 px-2'>
            <img
              src={data.items[0].url}
              alt={data.items[0].title}
              className='w-[38px] object-contain ltr:-rotate-12 rtl:rotate-12'
            />
            <p className='text-base font-semibold text-gray-300'>{t('view_menu')}</p>
          </div>
          <img
            src='/icons/common/arrow.svg'
            width={20}
            height={20}
            className={`transition-all ${collapse ? '-rotate-90' : 'rotate-90'}`}
            alt='arrow'
          />
        </div>
        <Modal
          fullscreen
          closeButton
          render={({ open }) => {
            return (
              <AnimatePresence>
                {collapse && (
                  <motion.div
                    initial='collapsed'
                    animate='open'
                    exit='collapsed'
                    variants={{
                      open: { opacity: 1, height: 'auto' },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    className='overflow-hidden'
                    transition={{ duration: 0.3 }}
                  >
                    <div className='flex flex-wrap items-start gap-1 py-4'>
                      {data.items.map((item, index) => (
                        <Button
                          key={item.url + index}
                          theme='white'
                          shape='text'
                          className='p-1'
                          onClick={() => {
                            open();
                            setMenu(index);
                          }}
                        >
                          <img
                            src={item.url}
                            alt={item.title}
                            className='max-h-32 w-24 rounded-md object-contain transition-all hover:scale-105'
                          />
                        </Button>
                      ))}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            );
          }}
          className='overflow-y-auto bg-black/10 p-4 backdrop-blur-sm'
          body={() => (
            <Gallery
              posters={data.items as ContentfulImage[]}
              autoPlay={false}
              selectedIndex={menu}
            />
          )}
        />
      </div>
    </div>
  );
};

export default MenuCollection;
