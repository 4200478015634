import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useGetEventsList,
  useGetExperiences,
  useGetRestaurants,
  useGetShows,
  useGetZonePages,
} from '@wbk/contentful/api';
import { getItem, isArabic, setItem } from '@wbk/utils';
import Context, { SearchStateProps } from './context';

type Props = {
  children: React.ReactNode;
};

const SEARCH_LIMIT = 4;
let searchTimeout: NodeJS.Timeout;

const SearchProvider = ({ children }: Props) => {
  const prevHistory = getItem('search_history');
  const history = (prevHistory ? JSON.parse(prevHistory) : []) as string[];
  const [state, setState] = useState<SearchStateProps>({
    showPopover: false,
    searchHistory: history,
    search: undefined,
  });
  const { pathname } = useLocation();
  const canFetch = typeof state.search === 'string';
  const isArabicSearch = isArabic(state?.search);
  const searchLang = isArabicSearch ? 'ar-SA' : 'en-US';
  const query = {
    lang: searchLang,
    limit: SEARCH_LIMIT,
    where: {
      OR: [
        { title_contains: state.search },
        { description_contains: state.search },
        { category: { title_contains: state.search } },
        { location: { title_contains: state.search } },
        { zone: { title_contains: state.search } },
        {
          seo: {
            OR: [
              { title_contains: state.search },
              { keywords_contains: state.search },
              { description_contains: state.search },
            ],
          },
        },
      ],
    },
  };
  const {
    data: events,
    isLoading: eventsLoading,
    isFetched: eventsFetched,
  } = useGetEventsList(query, { enabled: canFetch });
  const {
    data: experiences,
    isLoading: experiencesLoading,
    isFetched: experiencesFetched,
  } = useGetExperiences(query, {
    enabled: canFetch,
  });
  const {
    data: shows,
    isLoading: showsLoading,
    isFetched: showsFetched,
  } = useGetShows(query, {
    enabled: canFetch,
  });
  const {
    data: restaurants,
    isLoading: restaurantsLoading,
    isFetched: restaurantsFetched,
  } = useGetRestaurants(query, {
    enabled: canFetch,
  });
  const {
    data: zones,
    isLoading: zoneLoading,
    isFetched: zonesFetched,
  } = useGetZonePages(
    { lang: searchLang, where: { title_contains: state.search } },
    { enabled: state.showPopover }
  );

  useEffect(() => {
    if (!pathname.includes('/search')) {
      setState((prevState) => ({ ...prevState, searchState: undefined, search: undefined }));
    }
  }, [pathname]);

  const togglePopover = (state: boolean) => {
    setState((prevState) => ({ ...prevState, showPopover: state }));
  };

  const handleSearch = (val?: string) => {
    const value = val || '';
    setState((prevState) => ({ ...prevState, searchState: value }));

    clearTimeout(searchTimeout);

    if (!val) {
      return setState((prevState) => ({ ...prevState, search: undefined }));
    }

    searchTimeout = setTimeout(() => {
      saveSearch(value);
    }, 500);
  };

  const saveSearch = (value?: string) => {
    setState((prevState) => ({ ...prevState, search: value }));

    // Dont save 2 letters or less
    if (value && value.length > 2) {
      const prevHistory = getItem('search_history');
      const history = (prevHistory ? JSON.parse(prevHistory) : []) as string[];
      if (!history?.includes(value?.trim())) {
        const newHistory = history.concat(value).slice(-5); // keep only latest 5
        setState((prevState) => ({ ...prevState, searchHistory: newHistory }));
        setItem('search_history', JSON.stringify(newHistory));
      }
    }
  };

  const clearHistory = () => {
    localStorage.removeItem('search_history');
    setState((prevState) => ({ ...prevState, searchHistory: [] }));
  };

  const allEventsMerged = (events?.items || [])?.concat(
    experiences?.items || [],
    shows?.items || [],
    restaurants?.items || []
  );
  const isLoading =
    eventsLoading && experiencesLoading && showsLoading && restaurantsLoading && zoneLoading;
  const hasResult = !!state.search && (!!zones?.length || !!allEventsMerged?.length);
  const hasZoneResult = Number(state.search?.length) > 1 && hasResult;
  const fetchedDone =
    eventsFetched && experiencesFetched && showsFetched && restaurantsFetched && zonesFetched;

  const sortedZones = useMemo(() => {
    const filtered = zones?.filter((zonePage) => {
      const zone = zonePage.sectionsCollection?.items?.[0]?.zone;
      return !zone?.schedule?.closeDateTime || new Date(zone.schedule.closeDateTime) > new Date();
    });

    return filtered?.sort((a, b) => {
      const zoneA = a.sectionsCollection?.items?.[0]?.zone;
      const zoneB = b.sectionsCollection?.items?.[0]?.zone;
      return zoneA.order - zoneB.order;
    });
  }, [zones]);

  return (
    <Context.Provider
      value={{
        ...state,
        onSearch: handleSearch,
        onHistoryClear: clearHistory,
        togglePopover,
        isLoading,
        events: events?.items,
        experiences: experiences?.items,
        shows: shows?.items,
        restaurants: restaurants?.items,
        allEventsMerged,
        hasResult,
        hasZoneResult,
        fetchedDone,
        zoneLoading,
        zones: sortedZones,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default SearchProvider;
