const PriceIcon = ({ className = '' }) => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <rect
        x='9.58301'
        y='2.20813'
        width='1.66667'
        height='3.33333'
        rx='0.833333'
        fill='currentColor'
      />
      <rect
        x='9.58301'
        y='13.8749'
        width='1.66667'
        height='4.16667'
        rx='0.833333'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.9113 7.24484C12.6791 6.94929 12.3756 6.71443 12.0009 6.54026C11.6261 6.36609 11.2646 6.27901 10.9163 6.27901C10.7368 6.27901 10.5548 6.29484 10.37 6.32651C10.1853 6.35818 10.0191 6.41887 9.87129 6.50859C9.72351 6.59831 9.59948 6.71442 9.49921 6.85693C9.39893 6.99943 9.34879 7.18151 9.34879 7.40318C9.34879 7.59318 9.38837 7.75151 9.46754 7.87818C9.54671 8.00484 9.66282 8.11567 9.81587 8.21068C9.96893 8.30568 10.151 8.39276 10.3621 8.47193C10.5732 8.55109 10.8107 8.6329 11.0746 8.71734C11.4546 8.84401 11.8505 8.98387 12.2621 9.13693C12.6738 9.28998 13.0485 9.49317 13.3863 9.74651C13.7241 9.99984 14.0038 10.3139 14.2255 10.6886C14.4471 11.0633 14.558 11.5304 14.558 12.0898C14.558 12.7337 14.4392 13.2905 14.2017 13.7603C13.9642 14.23 13.6449 14.6179 13.2438 14.924C12.8427 15.2301 12.3835 15.4571 11.8663 15.6048C11.3491 15.7526 10.816 15.8265 10.2671 15.8265C9.4649 15.8265 8.68907 15.6866 7.93962 15.4069C7.19017 15.1272 6.5674 14.7287 6.07129 14.2115L7.84462 12.4065C8.11907 12.7443 8.48059 13.0266 8.92921 13.2536C9.37782 13.4805 9.82379 13.594 10.2671 13.594C10.4677 13.594 10.663 13.5729 10.853 13.5307C11.043 13.4885 11.2092 13.4198 11.3517 13.3248C11.4942 13.2298 11.6077 13.1032 11.6921 12.9448C11.7766 12.7865 11.8188 12.5965 11.8188 12.3748C11.8188 12.1637 11.766 11.9843 11.6605 11.8365C11.5549 11.6887 11.4045 11.5541 11.2092 11.4328C11.0139 11.3114 10.7712 11.2005 10.4809 11.1003C10.1906 11 9.86074 10.8918 9.49129 10.7757C9.1324 10.6596 8.78143 10.5223 8.43837 10.364C8.09532 10.2057 7.78921 10.0025 7.52004 9.75443C7.25087 9.50637 7.03448 9.20554 6.87087 8.85193C6.70726 8.49831 6.62546 8.06818 6.62546 7.56151C6.62546 6.93873 6.75212 6.40568 7.00546 5.96234C7.25879 5.51901 7.59129 5.15484 8.00296 4.86984C8.41462 4.58484 8.87906 4.37637 9.39629 4.24443C9.91351 4.11248 10.436 4.04651 10.9638 4.04651C11.5971 4.04651 12.2436 4.16262 12.9034 4.39484C13.5631 4.62707 14.141 4.97012 14.6371 5.42401L12.9113 7.24484Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default PriceIcon;
