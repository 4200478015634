import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Organization } from '@wbk/api';
import { useGetEventsList } from '@wbk/contentful/api';
import { DEFAULT_PAGINATION } from '@/constants/pagination';
import EventItemSkeleton from '../ui/spinners/EventItemSkeleton';
import Reveal from '../animation/Reveal';
import EventItem from '../events/EventItem';
import TicketIcon from '../svg/Ticket';
import Pagination from '../common/Pagination';
import OrganiationListItem from './OrganiationListItem';

type Props = {
  organization: Organization;
};

const EventListByOrganization = ({ organization }: Props) => {
  const [searchParams] = useSearchParams();
  const { lang } = useParams<{ lang: Language }>();
  const { t } = useTranslation('event');
  const page = searchParams.get('page');
  const perPage = DEFAULT_PAGINATION;
  const query = {
    lang,
    limit: perPage,
    skip: (Number(page) - 1) * perPage,
    where: {
      organizationSlug: organization.slug,
    },
  };

  const { data, isLoading } = useGetEventsList(query);

  return (
    <>
      {/* Desktop */}
      <div className='xs:grid-cols-2 hidden grid-cols-1 gap-4 pb-6 md:grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
        {isLoading ? (
          <EventItemSkeleton number={6} />
        ) : (
          data?.items?.map((event, i) => {
            const delay = (i % 3) * 0.1;
            return (
              <Reveal
                key={event.id + i}
                direction='bottom'
                delay={i % 2 > 0 ? delay : -delay}
                className='mx-auto w-full max-w-[380px]'
              >
                <EventItem event={event} />
              </Reveal>
            );
          })
        )}
      </div>

      {/* Mobile */}
      <ul className='space-y-4 md:hidden'>
        {data?.items?.map((event, i) => {
          const delay = (i % 1) * 0.1;
          return (
            <Reveal
              as='li'
              key={event.id + i}
              direction='bottom'
              delay={delay}
              className='flex items-center gap-2'
            >
              <OrganiationListItem organization={organization} event={event} />
            </Reveal>
          );
        })}
      </ul>

      {!isLoading && !data?.items?.length && (
        <div className='mx-auto flex h-[200px] items-center justify-center p-4'>
          <div className='mx-auto space-y-4 font-semibold text-zinc-400'>
            <TicketIcon className='mx-auto h-28 w-28' />
            <p className='text-center'>{t('event:no_events_available')}</p>
          </div>
        </div>
      )}

      <Pagination total={data?.total || 0} />
    </>
  );
};

export default EventListByOrganization;
